<template>
    <div id="cpanel_root" v-if="showContent">
        <LeftMenu :activeLeftMenu="1" />
        <div id="cpanel_root_right">
            <CpanelGroupsLevel />
        </div>
    </div>
    
</template>

<script>
    import LeftMenu from './LeftMenu.vue'
    import CpanelGroupsLevel from './CpanelGroupsLevel.vue'
    import router from '@/router'
    
    export default {
        name: 'Cpanel',
        title: 'Панель управления',
        components: {
            LeftMenu, CpanelGroupsLevel
        },
        data() {
            return {
                showContent: false,
            }
        },
        created() {
            if( ! localStorage.mToken ) {
                router.push('/login');
                return;
            }
            
            this.showContent = true;
        }
    }
</script>

<style scoped>
    #cpanel_root {
        display: flex;
        min-height: 100vh;
    }
    
    #cpanel_root_right {
        flex-grow: 1;
        background: #FAFAFA;
    }
    
    
</style>