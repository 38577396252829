<template>
    <CpanelHeader :navigation="navigation" @gotoNav="gotoNav" />
    <div id="cgl1_root">
        <div id="cgl1_header" v-if="! isPage(PAGE_OPEN_MODEL_INFO)">
            <span id="cgl1_header_title">{{ navigation[currentNavIndex].name }}</span>
            <input id="cgl1_header_search" placeholder="Поиск по модели" @click.stop="$router.push('/search')" />
        </div>
        <div id="cgl1_body">
            
            <template v-if="isPage(PAGE_GROUPS)">
                
                <div 
                    v-if="typeAccount == 2 || typeAccount == 4"
                    class="cgl1_group_box cgl1_group_box_add_new"
                    @click.stop="openPopupAddEditGroup">
                    <img src="/images/add_group.svg" />
                    <span>{{currentNavIndex == 0 ? 'Добавить новую группу': 'Добавить новую подгруппу товаров'}}</span>
                </div>
                
                <div
                    v-for="(item, index) in list"
                    :key="index" 
                    class="cgl1_group_box cgl1_group_box_default"
                    :class="{'cgl1_group_box_default_end_level': currentNavIndex == 1}"
                    @click.stop="openGroup(item)"
                    >
                    <div class="cgl1_group_box_default_name">{{ item.name }}</div>
                    <div class="cgl1_group_box_default_descr">{{ item.description }}</div>
                    <div 
                        v-if="typeAccount == 2 || typeAccount == 4"
                        class="cgl1_group_box_default_footer">
                        <div class="cgl1_group_box_default_footer_btn"
                             @click.stop="editGroup(item)"
                             >
                            <img src="/images/group_edit.svg"/>
                            <span>Редактировать</span>
                        </div>
                        <div class="cgl1_group_box_default_footer_btn"
                             v-if="item.parent_id > 0"
                             @click.stop="openGroup(item);openPopupAddEditModel()"
                             >
                            <img src="/images/group_add_model.svg"/>
                            <span>Добавить модель</span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="isPage(PAGE_LIST_MODELS)">
                <CpanelListModels
                                ref="cpanelListModels"
                                :currentGroup_id="this.navigation[this.currentNavIndex].parent_id"
                                @clickAddModel="openPopupAddEditModel"
                                @editModel="editModel"
                                @openModel="openModel"
                                />
            </template>
            <template v-if="isPage(PAGE_OPEN_MODEL_INFO)">
                <ModelInfo ref="modelInfo"
                           :openModelId="openModelId"
                           @editModel="editModel"
                           
                           />
            </template>
        </div>
    
    </div>
    <PopupAddEditGroup ref="formAddEditGroup" @savePopupAddEditGroup="loadGroups" />
    <PopupAddEditModel ref="formAddEditModel"
                       v-if="this.navigation.length > 0"
                       :currentGroup_id="this.navigation[this.currentNavIndex].parent_id"
                       @savePopupAddEditModel="updateCpanelListModels"
                       @deleteModel="deleteModel"
                       @errorNoHaveManufacturers="showAlertNotHaveManufacturers"
                       />
    <Alert ref="popupAlert"/>
</template>

<script>
    import CpanelHeader from './CpanelHeader.vue'
    import PopupAddEditModel from './PopupAddEditModel.vue'
    import PopupAddEditGroup from './PopupAddEditGroup.vue'
    import CpanelListModels from './CpanelListModels.vue'
    import ModelInfo from './ModelInfo.vue'
    import Alert from './Alert.vue'
    
    export default {
        name: 'CpanelGroupsLevel',
        components: {
            PopupAddEditGroup, CpanelHeader, CpanelListModels, PopupAddEditModel, Alert, ModelInfo
        },
        data() {
            return {
                show_page: 0,
                //page: 1,
                list: [],
                currentNavIndex: 0,
                navigation: [
                    { name: 'Все группы товаров', parent_id: 0, index: 0 }
                ],
                openModelId: 0,
                typeAccount: null,
            };
        },
        created() {
            this.PAGE_GROUPS            = 1;
            this.PAGE_LIST_MODELS       = 2;
            this.PAGE_OPEN_MODEL_INFO   = 3;
            
            this.typeAccount = localStorage.typeAccount;
            
            if(this.$route.params.id) {
                const found_1 = this.$route.params.id.match(/^([0-9]+)$/i);

                if( found_1 ) {

                    this.navigation = [];

                    this.openModelId  = found_1[1];
                    this.show_page    = this.PAGE_OPEN_MODEL_INFO;
                    return;
                }
            }
            
            this.show_page = this.PAGE_GROUPS;
            this.loadGroups();
        },
        beforeUnmount () {
            
        },
        methods: {
            showPage(id) {
                this.show_page = id;
            },
            isPage(id) {
                return this.show_page == id;
            },
            updateCpanelListModels(data) {
                if(this.isPage(this.PAGE_LIST_MODELS)) {
                    this.$refs.cpanelListModels.loadList();
                }
                else if(this.isPage(this.PAGE_OPEN_MODEL_INFO)) {
                    this.$refs.modelInfo.refresh();
                    this.navigation[ this.navigation.length -1 ].name = data.name;
                }
            },
            openPopupAddEditGroup() {
                this.$refs.formAddEditGroup.show(
                         this.currentNavIndex == 0 
                         ? 'Создание группы' : 'Создание подгруппы'
                        , false
                        , this.navigation[this.currentNavIndex].parent_id);
            },
            openPopupAddEditModel() {
                console.log('openPopupAddEditModel', this.$refs.formAddEditModel);
                this.$refs.formAddEditModel.show(false);
            },
            loadGroups() {
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadGroupsLevel + this.navigation[this.currentNavIndex].parent_id, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.list = data.data;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                    
                });
            },
            editGroup(item) {
                this.$refs.formAddEditGroup.show(
                         this.currentNavIndex == 0 
                         ? 'Редактирование группы' : 'Редактирование подгруппы'
                        , true
                        , item.parent_id
                        , item.id
                        , item.name
                        , item.description
                        , item.name_url
                );
            },
            openGroup(item) {
                console.log('openGroup', item);
                
                this.navigation.push({
                      name: item.name
                    , parent_id: item.id
                    , index: this.currentNavIndex + 1
                });
                this.currentNavIndex++;
                
                //console.log(item, this.currentNavIndex);
                if(this.currentNavIndex == 2) {
                    this.showPage(this.PAGE_LIST_MODELS);
                }
                else {
                    this.loadGroups();
                }
            },
            gotoNav(index) {
                if(index === this.currentNavIndex) {
                    return;
                }
                
                this.showPage(this.PAGE_GROUPS);
                
                let new_mass = [];
                for(let i in this.navigation) {
                    if(i <= index) {
                        new_mass.push(this.navigation[i]);
                    }
                }
                
                //console.log(new_mass, index, this.navigation);
                
                this.currentNavIndex = index;
                this.navigation = new_mass;
                //this.loadGroups();
                if(this.currentNavIndex == 2) {
                    this.showPage(this.PAGE_LIST_MODELS);
                }
                else {
                    this.loadGroups();
                }
                
                console.log(this.navigation);
            },
            editModel(id) {
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadInfoModel + id, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.$refs.formAddEditModel.show(true, data.data, data.account_id);
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
                
            },
            openModel(id, name) {
                this.openModelId = id;
                this.showPage(this.PAGE_OPEN_MODEL_INFO);
                
                this.navigation.push({
                      name: name
                    , parent_id: this.navigation[this.currentNavIndex].parent_id
                    , index: this.currentNavIndex + 1
                });
                this.currentNavIndex++;
                
                console.log(this.navigation);
            },
            showAlertNotHaveManufacturers() {
                console.log('showAlertNotHaveManufacturers');
                this.$refs.popupAlert.show('Для создания модели, необходимо заполнить поле "Произодитель", на странице <a href="/profile#profile_org_info">профиля</a>');
            },
            deleteModel() {
                if(this.isPage(this.PAGE_LIST_MODELS)) {
                    this.$refs.cpanelListModels.loadList();
                }
                else if(this.isPage(this.PAGE_OPEN_MODEL_INFO)) {
                    this.gotoNav(--this.currentNavIndex);
                    this.navigation.splice(this.navigation.length - 1, 1);
                    this.showPage(this.PAGE_LIST_MODELS);
                }
            }
        }
    }
</script>

<style scoped>
    #cgl1_root {
    }
    #cgl1_header {
        display: flex;
        align-items: center;
        margin: 17px 22px 23px 42px;
    }
    #cgl1_header_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
        color: #454A4D;
        flex-grow: 1;
        
    }
    #cgl1_header_search {
        font-size: 11px;
        padding: 8px 8px 8px 36px;
        border: 1px solid #E2E1E1;
        border-radius: 5px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-image: url(/images/search.svg);
        background-position: 15px center;
    }
    .cgl1_group_box {
        width: 265px;
        height: 170px;
        cursor: pointer;
        margin: 5px;
    }
    .cgl1_group_box_add_new {
        border: 1px dashed rgba(205, 205, 205, 0.5);
        border-radius: 3px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .cgl1_group_box_default {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: #FFF;
        padding: 20px 12px 18px 18px;
        border: 1px dashed #FFF;
    }
    .cgl1_group_box_default:hover {
        border-color: #f88;
    }
    /*.cgl1_group_box_default_end_level {
        cursor: default;
    }
    .cgl1_group_box_default_end_level:hover {
        border-color: #FFF;
    }*/
    .cgl1_group_box_add_new > img {
        display: inline-block;
        margin-bottom: 19px;
    }
    .cgl1_group_box_add_new > span {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: rgba(69, 74, 77, 0.4);
    }
    
    .cgl1_group_box_add_new:hover {
        border-color: rgba(205, 205, 205, 1);
        background-color: #fff;
    }
    .cgl1_group_box_add_new:hover .cgl1_group_box_add_new > span {
        color: rgba(69, 74, 77, 1);
    }
    
    #cgl1_body {
        margin: 0 11px 20px 37px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        font-size: 0;
    }
    .cgl1_group_box_default_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .cgl1_group_box_default_footer_btn {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .cgl1_group_box_default_footer_btn > img {
        display: inline-block;
        margin-right: 3px;
        width: 10px;
        height: 10px;
    }
    .cgl1_group_box_default_footer_btn > span {
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 13px;
        color: #2094FF;
    }
    .cgl1_group_box_default_footer_btn:hover span {
        text-decoration: underline;
    }
    
    .cgl1_group_box_default_name {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #454A4D;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        max-height: 40px;
        margin-bottom: 10px;
    }
    .cgl1_group_box_default_descr {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: rgba(69, 74, 77, 0.4);
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        /*max-height: 40px;*/
        margin-bottom: 24px;
    }
    
</style>