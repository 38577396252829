<template>
    <div class="popup_addSerialNumbers" v-show="showContent">
        <div class="popup_addSerialNumbers_form">
            <div class="popup_addSerialNumbers_formCloseBtn" @click.stop="closeForm()"></div>
            <div id="popup_addSerialNumbers_form_title">Добавить серийные номера</div>
            <div id="popup_addSerialNumbers_body">
                
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: 'PopupAddNewSerialNumbers',
        emits: ["closeForm"],
        data() {
            return {
                showContent: false
            };
        },
        methods: {
            show() {
                this.showContent = true;
            },
            hide() {
                this.showContent = false;
            },
            closeForm() {
                this.$emit("closeForm");
                this.hide();
            },
        }
    }
</script>

<style scoped>
    .popup_addSerialNumbers {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(150, 150, 150, 0.1);
    }
    .popup_addSerialNumbers_form {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 500px;
        min-height: 394px;
        top: 48%;
        left: 50%;
        border: 1px solid #eee;
        transform: translate(-50%, -50%);
        background: #fefefe;
        padding: 51px 51px 51px 50px;
    }
    .popup_addSerialNumbers_formCloseBtn {
        position: absolute;
        display: inline-block;
        top: 22px;
        right: 29px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/images/close-popup.svg);
        background-size: 20px 20px;
        opacity: 0.7;
        cursor: pointer;
    }
    .popup_addSerialNumbers_formCloseBtn:hover {
        opacity: 1;
    }
    #popup_addSerialNumbers_form_title {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 28px;
    }
</style>