import router from '@/router'
import { toast } from '@/toast.js'

if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  };
}

let host_main  = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8001/' : 'https://api.qrdata.tech/';
let host_api   = host_main + 'api/lk/';

export const g_config = {
    urlMain: host_main,
    urlRegistration: host_api + 'registration',
    urlSetPasswordToken: host_api + 'set-password-token-exist',
    urlFirstSetPassword: host_api + 'first-set-password',
    urlLogin: host_api + 'login',
    urlLogout: host_api + 'logout',
    urlGetProfileInfo: host_api + 'get-profile-info',
    urlGetCountMyOrgs: host_api + 'get-count-my-organizations',
    urlProfileSetNewPassword: host_api + 'profile-set-new-password',
    urlSaveNewGroup: host_api + 'save-new-group',
    urlEditGroup: host_api + 'edit-group',
    urlDeleteGroup: host_api + 'delete-group/',
    urlLoadGroupsLevel: host_api + 'load-groups-level/',
    urlSaveModelImage: host_api + 'save-model-image',
    urlSaveModelDocs: host_api + 'save-model-docs',
    urlSaveModelManualDoc: host_api + 'save-model-manual-docs',
    urlSaveNewModel: host_api + 'save-new-model',
    urlSaveEditModel: host_api + 'save-edit-model',
    urlLoadListModels: host_api + 'load-list-models/',
    urlLoadInfoModel: host_api + 'load-info-model/',
    urlDeleteModel: host_api + 'delete-model/',
    urlGetManufacturers: host_api + 'get-manufacturers',
    urlSaveFieldOrgForm: host_api + 'save-field-org-form',
    urlSaveOneOrgManufacturers: host_api + 'save-one-org-manufacturers',
    urlDeleteOrgManufacturers: host_api + 'delete-org-manufacturers/',
    urlSaveNewOrgUser: host_api + 'save-new-org-user',
    urlSaveEditOrgUser: host_api + 'save-edit-org-user',
    urlLoadListOrgUser: host_api + 'load-list-org-users',
    urlLoadDataOrgUser: host_api + 'load-data-org-user/',
    urlDeleteOrgUser: host_api + 'delete-org-users/',
    urlSaveSerialNumbers: host_api + 'save-serial-numbers',
    urlLoadSerialNumbers: host_api + 'load-serial-numbers/',
    urlSaveOneSerialNumbers: host_api + 'save-one-serial-numbers',
    urlDeleteOneSerialNumbers: host_api + 'delete-one-serial-numbers/',
    urlSerachSerialNumber: host_api + 'search-serial-numbers',
    urlSerachSerialNumber2: host_api + 'search-serial-numbers-2',
    urlForgotPassword: host_api + 'forgot-password',
    urlSearch: host_api + 'search',
    urlGetLatestSerials: host_api + 'get-latest-serials',
    urlDownloadMultiSelect: host_api + 'download-multi-select',
}

/**
 * Обработка ошибки апи, которую вернул сам сервер, в json ответа
 */
export const apiErrorHandling = (data) => {
    console.log(data);

    if(data.key == "unauthorized")
    { 
        localStorage.mToken = '';
        toast.toast( { type: 'text', value: "Пользователь не авторизован" }, { settings: { duration: 5300 } });
        router.push('/login');
        return;
    }
};

/**
 * Обработка ошибки запроса к апи
 */
const errorResponseHandling = (err, method, url, text, request_data = {}) => {
    
    console.log(err, '-', err.name, '-', err.message, method, url, text, request_data);
    
    let printMessage = "Ошибка обработки запроса";
    
    if (err instanceof TypeError) {
        if(err.message.indexOf("NetworkError") != -1) {
            printMessage = "Ошибка сети";
            toast.toast( { type: 'text', value: printMessage }, { settings: { duration: 5300 } });
        }
    }
    /*else if (err instanceof SyntaxError) {
        
        fetch(g_config.urlSendError, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization': 'Bearer ' + localStorage.token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                type: method,
                url: url,
                data:  navigator.userAgent 
                        + ' \n' + window.location 
                        + ' \n' + JSON.stringify(request_data) 
                        + ' \n' + text
            })
        });
    }*/

    //toast.toast( { type: 'text', value: printMessage }, { settings: { duration: 5300 } });
};

/**
 * Запрос POST
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_postRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
        
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'POST', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос POST
 * 
 * @param {type} url
 * @param {type} request_data (new FormData())
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_postRequest2 = async (url = '', request_data, token) => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: request_data
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'POST', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос PUT
 * 
 * @param {type} url
 * @param {type} request_data
 * @param {type} x_csrf_token
 * @returns {unresolved}
 */
export const g_putRequest = async (url = '', request_data = {}, token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(request_data)
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'PUT', url, text, request_data);
        return Promise.reject({type_error: 'other', data: err});
    }
};

/**
 * Запрос GET
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_getRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'GET', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};


/**
 * Запрос DELETE
 * 
 * @param {type} url
 * @param {type} token
 * @returns {unresolved}
 */
export const g_deleteRequest = async (url = '', token = '') => {
    
    let text = '';
    
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Accept-Language': localStorage.lang,
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=utf-8',
              'X-Token-Secure': token,
              'pragma': 'no-cache',
              'cache-control': 'no-cache',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
    
        text = await response.text(); // Parse it as text
        const data = JSON.parse(text); // Try to parse it as json
        
        if(data.success === false) {
            apiErrorHandling(data);
            return Promise.reject({type_error: 'api', data: data});
        }
        
        return data;
    }
    catch(err) {
        errorResponseHandling(err, 'DELETE', url, text);
        return Promise.reject({type_error: 'other', data: err});
    }
};

let _id_counter = 0;
export const getRandId = () => {
    return '' + (_id_counter++).toString(36) + '' + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36).slice(-5);
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    return /\+7\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}/.test(phone);
};

//Транслитерация кириллицы в URL
export const transliterationForUrl = (str) => {
    str = str.toLowerCase(); // все в нижний регистр
    var cyr2latChars = new Array(
       ['а', 'a'], ['б', 'b'], ['в', 'v'],  ['г', 'g'],
       ['д', 'd'], ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'],
       ['и', 'i'], ['й', 'y'], ['к', 'k'], ['л', 'l'],
       ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'],  ['р', 'r'],
       ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'],
       ['х', 'h'], ['ц', 'c'], ['ч', 'ch'],['ш', 'sh'], ['щ', 'shch'],
       ['ъ', ''],  ['ы', 'y'], ['ь', ''],  ['э', 'e'], ['ю', 'yu'], ['я', 'ya'],
       ['А', 'A'], ['Б', 'B'],  ['В', 'V'], ['Г', 'G'],
       ['Д', 'D'], ['Е', 'E'],  ['Ё', 'YO'], ['Ж', 'ZH'], ['З', 'Z'],
       ['И', 'I'], ['Й', 'Y'],  ['К', 'K'], ['Л', 'L'],
       ['М', 'M'], ['Н', 'N'],  ['О', 'O'], ['П', 'P'], ['Р', 'R'],
       ['С', 'S'], ['Т', 'T'],  ['У', 'U'], ['Ф', 'F'],
       ['Х', 'H'], ['Ц', 'C'], ['Ч', 'CH'], ['Ш', 'SH'], ['Щ', 'SHCH'],
       ['Ъ', ''],  ['Ы', 'Y'],
       ['Ь', ''],
       ['Э', 'E'],
       ['Ю', 'YU'],
       ['Я', 'YA'],
       ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'],
       ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'],
       ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'],
       ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'],
       ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'],
       ['z', 'z'],
       ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'], ['E', 'E'],
       ['F', 'F'], ['G', 'G'], ['H', 'H'], ['I', 'I'], ['J', 'J'], ['K', 'K'],
       ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'], ['P', 'P'],
       ['Q', 'Q'], ['R', 'R'], ['S', 'S'], ['T', 'T'], ['U', 'U'], ['V', 'V'],
       ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],
       [' ', '-'], ['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'],
       ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'],
       ['-', '-']
    );

    var newStr = new String();
    for (var i = 0; i < str.length; i++)
    {
        let ch = str.charAt(i);
        var newCh = '';
        for (var j = 0; j < cyr2latChars.length; j++)
        {
            if (ch == cyr2latChars[j][0])
            {
                newCh = cyr2latChars[j][1];
            }
        }
        // Если найдено совпадение, то добавляется соответствие, если нет - пустая строка
        newStr += newCh;
    }
    // Удаляем повторяющие знаки - Именно на них заменяются пробелы.
    // Так же удаляем символы перевода строки, но это наверное уже лишнее
    return newStr.replace(/[_]{2,}/gim, '_').replace(/\n/gim, '');
}

export const limitTextareaLength = (obj, value) =>  {
    if(obj.value.length > value) {
        return obj.value.substr(0, value);
    }
    else {
        return obj.value;
    }
}
export const replaceNewLineChars = (obj) =>  {
    obj.value = obj.value.replace(/\s/gm, " ");
}
/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * Convert timestatm to date format hour:min dd.mm.YYYY
 * 
 * @param {type} UNIX_timestamp
 * @returns {String}
 */
export const timeConverter = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp * 1000);
    let year = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();
    let hour = '' + a.getHours();
    let min = '' + a.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;
    if (hour.length < 2)  hour = '0' + hour;
    if (min.length < 2)   min = '0' + min;

    return hour + ':' + min + ' ' + date + '.' + month + '.' + year;
}

export const timeConverterOnlyData = (UNIX_timestamp) => {
    let a = new Date(UNIX_timestamp * 1000);
    let year = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;

    return date + '.' + month + '.' + year;
}

export const isValidHttpUrl = (url) => {
    var regURLrf = /^(?:(?:https?):[/]{2}(?:[а-я0-9_-]{1,32}(?::[а-я0-9_-]{1,32})?@)?)?(?:(?:[а-я0-9-]{1,128}\.)+(?:рф)|(?! 0)(?:(?! 0[^.]|255)[ 0-9]{1,3}\.){3}(?! 0|255)[ 0-9]{1,3})(?:[/][a-zа-я0-9.,_@%&?+=~/-]*)?(?:#[^ '"&<>]*)?$/i;
    var regURL = /^(?:(?:https?):[/]{2}(?:[a-z0-9_-]{1,32}(?::[a-z0-9_-]{1,32})?@)?)?(?:(?:[a-z0-9-]{1,128}\.)+(?:com|net|org|mil|edu|arpa|ru|gov|biz|info|aero|inc|name|[a-z]{2})|(?! 0)(?:(?! 0[^.]|255)[ 0-9]{1,3}\.){3}(?! 0|255)[ 0-9]{1,3})(?:[/][a-zа-я0-9.,_@%&?+=~/-]*)?(?:#[^ '"&<>]*)?$/i;
    return regURLrf.test(url)||regURL.test(url);
}

/**
 * 
 * @param {date} a
 * @returns {dd.mm.yyyy}
 */
export const dateToString = (a) => {
    let year = a.getFullYear();
    let month = '' + (a.getMonth() + 1);
    let date = '' + a.getDate();

    if (month.length < 2) month = '0' + month;
    if (date.length < 2)  date = '0' + date;

    return date + '.' + month + '.' + year;
}

/**
 * 
 * @param {type} string date dd.mm.yyyy
 * @returns {Date}
 */
export const strToDate = (strdate) => {
    
    const re = /^\d{2}\.\d{2}\.\d{4}/
    if( ! re.test(strdate) ) {
        return null;
    }
    
    let dateParts = strdate.split(".");
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
}