<!-- Восстановление пароля -->

<template>
    <div id="forgot_password_root">
        <div id="forgot_password_header">
            <router-link to="/"><img src="/images/logo.svg" /></router-link>
            <div id="forgot_password_header_call">
                <img src="/images/call-icon.svg" />
                <div id="forgot_password_header_call_phone">
                    <span id="forgot_password_header_call_phone_number">8 800 000 00 00</span>
                    <span id="forgot_password_header_call_phone_hint">служба поддержки</span>
                </div>
            </div>
        </div>
        
        <div id="form_login">
            <div id="form_forgot_password_title">
                Восстановление пароля
            </div>
            <div>
                <div class="form_forgot_password_label">Введите ваш логин</div>
                <div class="form_forgot_password_field form_forgot_password_field_email"
                     :class="{'form_forgot_password_field_error': form.errorLogin }"
                     >
                    <img class="form_forgot_password_field_icon" src="/images/form-login-icon-1.svg" />
                    <input placeholder="mail@mail.ru" v-model="form.email" maxlength="80" />
                </div>
                <div id="form_forgot_password_error">{{ form.error }}</div>
                <div class="form_forgot_password_btn" @click.stop="forgotPassword">
                    Отправить письмо
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import * as global from '../global.js'
    import router from '@/router'
    import { toast } from '@/toast.js'
    
    export default {
        name: 'ForgotPassword',
        title: 'Восстановление пароля',
        data() {
            return {
                passwordShow: false,
                form: {
                    email: '',
                    error: '',
                    errorLogin: false,
                }
            };
        },
        methods: {
            forgotPassword() {
                this.form.errorLogin     = false;
                this.form.error = '';
                
                if( ! global.validateEmail(this.form.email) ) {
                    this.form.errorLogin = true;
                    return;
                }
                
                this.$bus.emit('showLoading');

                this.$postRequest(this.$config.urlForgotPassword, {
                    email: this.form.email.toLowerCase()
                })
                .then(() => {
                    this.$bus.emit('hideLoading');
                    toast.toast( 
                              { type: 'text', value: 'На указанный email, отправлено письмо со ссылкой на восстановление пароля' }
                            , { settings: { duration: 6300 } });
                    router.push('/login');
                })
                .catch((data) => {
                    this.$bus.emit('hideLoading');
                    if(data.type_error === 'api') {
                        if(data.data.key === 'email-not-found') {
                            this.form.error = 'Email не найден';
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>
    #forgot_password_root {
        position: relative;
        min-height: 100vh;
        min-width: 100%;
        background: #F8F9FD;
    }
    #forgot_password_header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 35px 96px 0 37px;
    }
    #forgot_password_header_call {
        display: flex;
        align-items: center;
    }
    #forgot_password_header_call > img {
        display: inline-block;
        margin-right: 19px;
    }
    #forgot_password_header_call_phone {
        display: flex;
        flex-direction: column;
    }
    #forgot_password_header_call_phone_number {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 20px;
        color: #646A6D;
        margin-bottom: 5px;
    }
    #forgot_password_header_call_phone_hint {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #646A6D;
    }
    #form_login {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: #FFFFFF;
        box-shadow: 0px 20px 30px rgba(1, 101, 165, 0.07);
        border-radius: 7px;
        padding: 46px 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #form_forgot_password_title {
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 28px;
        text-align: center;
        color: #474B50;
        margin-bottom: 36px;
        font-weight: 600;
    }
    .form_forgot_password_label {
        margin-bottom: 7px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #B2BEC4;
        text-transform: uppercase;
    }
    .form_forgot_password_field {
        border: 1px solid #ECEFF0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        height: 50px;
        width: 320px;
        padding: 0 15px;
    }
    .form_forgot_password_field_icon {
        display: inline-block;
        margin-right: 15px;
        width: 21px;
        height: 21px;
    }
    .form_forgot_password_field > input {
        border: none;
        padding: 5px;
        flex-grow: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #474B50;
    }
    .form_forgot_password_field_email {
        margin-bottom: 15px;
    }
    .form_forgot_password_field_error {
        border: 1px solid red;
    }
    .form_forgot_password_field_error input {
        color: red;
    }
    #form_forgot_password_link_forgot_password {
        text-decoration: none;
    }
    #form_forgot_password_link_forgot_password:hover {
        text-decoration: underline;
    }
    .form_forgot_password_btn {
        width: 320px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        cursor: pointer;
        
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        border-radius: 5px;
    }
    .form_forgot_password_btn:hover {
        background: #0ea1ff;
    }
    #form_forgot_password_error {
        color: red;
        margin-bottom: 20px;
        font-size: 14px;
        text-align: center;
    }
</style>
