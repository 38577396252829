<template>
    <CpanelHeader :navigation="navigation" @gotoNav="gotoNav" />
    <div id="cls_root">
        <div id="cls_header">
            <span id="cls_header_title">{{ navigation[currentNavIndex].name }}</span>
            <input id="cls_header_search" placeholder="Поиск по модели" @click.stop="$router.push('/search')"/>
        </div>
        <div id="cls_body">
            <div id="searchBySerialNumber_c">
                <div id="searchBySerialNumber_c_title">Фильтр отбора</div>
                <div class="searchBySerialNumber_c_line">
                    <div class="searchBySerialNumber_c_item">
                        <span class="searchBySerialNumber_c_item_title">Серийный номер: </span>
                        <div class="inputWithClearBtn" id="searchBySerialNumber">
                            <input placeholder="Поиск по номеру..."
                                   v-model="serach_value"
                                   @keyup="onSearch"/>
                            <img src="/images/clear-search-input.svg" @click.stop="clearSearch" />
                        </div>
                    </div>
                    <div class="searchBySerialNumber_c_item">
                        <span class="searchBySerialNumber_c_item_title">Текст в модели: </span>
                        <div class="inputWithClearBtn" id="searchByTextModel">
                            <input placeholder="Поиск по тексту в названии и описании модели модели"
                                   v-model="search_textModel"
                                   @keyup="onSearch"/>
                            <img src="/images/clear-search-input.svg" @click.stop="clearTextModel" />
                        </div>
                    </div>
                    
                </div>
                <div class="searchBySerialNumber_c_line">
                    <div class="searchBySerialNumber_c_item">
                        <span class="searchBySerialNumber_c_item_title">Группа: </span>
                        <select @change="selectGroup($event)"
                                ref="selectGroupLevel_0"
                                class="searchBySerialNumber_c_groupSelect">
                            <option value="0">Не указана</option>
                            <option
                                v-for="(item, index) in groups.level_0"
                                :key="index"
                                class="cls_list_item"
                                :value="item.id"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="searchBySerialNumber_c_item">
                        <span class="searchBySerialNumber_c_item_title">Подгруппа: </span>
                        <select class="searchBySerialNumber_c_groupSelect"
                                ref="selectGroupLevel_1"
                                @change="selectGroupLevel_1()">
                            <option value="0">Не указана</option>
                            <option
                                v-for="(item, index) in groups.level_1"
                                :key="index"
                                class="cls_list_item"
                                :value="item.id"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                    
                </div>
                <div class="searchBySerialNumber_c_line">
                    <div class="searchBySerialNumber_c_item">
                        <span class="searchBySerialNumber_c_item_title">Дата с: </span>
                        <div :class="{'searchBySerialNumber_c_periodDates_haveError': periodDates.error }">
                            <span class="searchBySerialNumber_c_periodDates" @click.stop="openCalendar(1)">
                                {{ periodDates.start_date == '' ? '__.__.____' : periodDates.start_date }}
                                <img src="/images/clear-search-input.svg" 
                                     class="searchBySerialNumber_c_periodDates_clearBtn"
                                     @click.stop="clearStartDate" />
                                <div class="searchBySerialNumber_c_periodDates_popupCalendar"
                                    :class="{'searchBySerialNumber_c_periodDates_popupCalendar-show': periodDates.showCalendar_1 }">
                                   <Calendar v-if="periodDates.showCalendar_1" 
                                             :selectDate="periodDates.selectDate" 
                                             :indexField="periodDates.indexCalendar" 
                                             @selectDay="setDateInCalendar" />
                               </div>
                            </span>
                            <span class="searchBySerialNumber_c_item_title"> до: </span>
                            <span class="searchBySerialNumber_c_periodDates" @click.stop="openCalendar(2)">
                                {{ periodDates.end_date == '' ? '__.__.____' : periodDates.end_date }}
                                <img src="/images/clear-search-input.svg" 
                                     class="searchBySerialNumber_c_periodDates_clearBtn"
                                     @click.stop="clearEndDate" />
                                <div class="searchBySerialNumber_c_periodDates_popupCalendar"
                                    :class="{'searchBySerialNumber_c_periodDates_popupCalendar-show': periodDates.showCalendar_2 }">
                                   <Calendar v-if="periodDates.showCalendar_2" 
                                             :selectDate="periodDates.selectDate" 
                                             :indexField="periodDates.indexCalendar" 
                                             @selectDay="setDateInCalendar" />
                               </div>
                            </span>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            
            <template v-if="list.length > 0">
                <div class="cls_list_item_header">
                    <span></span>
                    <span>Дата</span>
                    <span>Группа</span>
                    <span>Подгруппа</span>
                    <span>Серийны номер</span>
                    <span>Модель</span>
                    <span>
                        <span
                            id="btnDownloadMultiSelect"
                            :class="{ 'show_btnDownloadMultiSelect': multiSelect.length > 0 }"
                            @click.stop="downloadMultiSelectDocument"
                            >Скачать QR-коды и<br /> Тех. паспорт</span>
                    </span>
                </div>
                <div
                    v-for="(item, index) in list"
                    :key="index"
                    class="cls_list_item"
                    >
                    <input type="checkbox" v-model="list[index].checkBox" @change="checkList_MultiSelect" />
                    <span class="cls_list_itemTimeCreate">{{ timeConvert(item.created_at) }}</span>
                    <span class="cls_list_item_group_name">{{ item.group1_name }}</span>
                    <span class="cls_list_item_group_name">{{ item.group2_name }}</span>
                    <span class="cls_list_item_input" v-html="list[index].serial_number"></span>
                        
                    <span class="cls_list_item_model_name" v-html="list[index].model_name"></span>
                    <a class="btnDownloadQrCode"
                       target="_blank"
                       :href="$config.urlMain + 'images/qr/' + account_id + '/' + item.fname"
                       >
                        Скачать QR-код
                    </a>
                </div>
            </template>
            <template v-else>
                <div id="cls_list_empty">Список пуст</div>
            </template>
            
        </div>
    </div>
</template>

<script>
    import * as global from '../global.js'
    import CpanelHeader from './CpanelHeader.vue'
    import Calendar from './Calendar.vue'
    
    import { strToDate } from '../global.js'
    
    export default {
        name: 'CpanelListSerials',
        data() {
            return {
                serach_value: '',
                search_textModel: '',
                list: [],
                currentNavIndex: 0,
                navigation: [
                    { name: 'Серийные номера', parent_id: 0, index: 0 }
                ],
                account_id: null,
                groups: {
                    level_0: [],
                    level_1: [],
                },
                
                periodDates: {
                    showCalendar_1: false,
                    showCalendar_2: false,
                    selectDate: '',
                    error: false,
                    start_date: '',
                    end_date: '',
                    indexCalendar: 0,
                },
                multiSelect: [],
            };
        },
        components: {
            CpanelHeader, Calendar
        },
        created() {
            this.getLatestSerials();
            
            this.loadGroups(0, (data) => {
               this.groups.level_0 = data; 
            });
        },
        methods: {
            escapeRegExChars: function (value) {
                return value.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
            },
            gotoNav() {
                
            },
            timeConvert(time) {
                return global.timeConverterOnlyData(time);
            },
            getLatestSerials() {
                this.$getRequest(this.$config.urlGetLatestSerials, localStorage.mToken)
                .then((data) => {
                    this.multiSelect = [];
                    this.account_id = data.account_id;
                    let mass = data.data;
                    
                    for(let item of mass) {
                        item.serial_number_original = item.serial_number;
                    }
            
                    this.list = mass;
                })
                .catch(() => {
                });
            },
            onSearch() {
                
                if( 
                           this.periodDates.start_date != ''
                        && this.periodDates.end_date != ''
                        && strToDate(this.periodDates.start_date).getTime() > strToDate(this.periodDates.end_date).getTime()
                ) {
                    this.periodDates.error = true;
                    return;
                }
                
                const startTms = strToDate(this.periodDates.start_date);
                const endTms   = strToDate(this.periodDates.end_date);
                
                let searchValue     = this.serach_value.toUpperCase();
                let searchTextModel = this.search_textModel.trim();
                
                if(
                        searchValue.length > 2
                     || searchTextModel.length > 2
                     || (
                            this.periodDates.start_date != '' && this.periodDates.end_date != '' && ! this.periodDates.error
                        )
                     || (
                               parseInt( this.$refs.selectGroupLevel_0.value ) != 0
                            || parseInt( this.$refs.selectGroupLevel_1.value ) != 0
                        )
                     || (
                               startTms != null
                            || endTms != null
                        )
                ) {
                    this.$postRequest(this.$config.urlSerachSerialNumber2,
                    {
                        value: searchValue,
                        searchTextModel: searchTextModel,
                        startTms: startTms ? startTms.getTime() / 1000 : null,
                        endTms: endTms ? endTms.getTime() / 1000 : null,
                        group_0: parseInt( this.$refs.selectGroupLevel_0.value ),
                        group_1: parseInt( this.$refs.selectGroupLevel_1.value ),
                    }
                    , localStorage.mToken)
                    .then((data) => {
                        this.multiSelect = [];
                        this.account_id = data.account_id;
                        let mass = data.data;
                        let pattern = '(' + this.escapeRegExChars(searchValue) + ')';
                        let pattern2 = '(' + this.escapeRegExChars(searchTextModel) + ')';
                
                        for(let item of mass) {
                            
                            item.serial_number_original = item.serial_number;
                            
                            if( item.serial_number.indexOf(searchValue) > -1 ) {
                                item.serial_number = item.serial_number.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
                            }
                            
                            item.model_name = item.model_name.replace(new RegExp(pattern2, 'gi'), '<strong>$1</strong>');
                            item.checkBox = false;
                        }
                        
                        this.list = mass;
                    })
                    .catch(() => {
                    });
                }
                else if(searchValue.length == 0) {
                    this.getLatestSerials();
                }
            },
            clearSearch() {
                if(this.serach_value.length > 0) {
                    this.serach_value = '';
                    this.list = [];
                    this.getLatestSerials();
                }
            },
            clearTextModel() {
                if(this.search_textModel.length > 0) {
                    this.search_textModel = '';
                    this.list = [];
                    this.getLatestSerials();
                }
            },
            loadGroups(parent_id, callback) {
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadGroupsLevel + parent_id, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    if(callback) {
                        callback(data.data);
                    }
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                    
                });
            },
            selectGroup(event) {
                this.loadGroups(event.target.value, (data) => {
                    this.groups.level_1 = data;
                    this.onSearch();
                 });
            },
            openCalendar(index) {
                if(index == 1) {
                    this.periodDates.selectDate = this.periodDates.start_date;
                    this.periodDates.showCalendar_1  = ! this.periodDates.showCalendar_1;
                    this.periodDates.showCalendar_2  = false;
                }
                else {
                    this.periodDates.selectDate = this.periodDates.end_date;
                    this.periodDates.showCalendar_2  = ! this.periodDates.showCalendar_2;
                    this.periodDates.showCalendar_1  = false;
                }
                
                //console.log(this.periodDates.selectDate);
                
                this.periodDates.indexCalendar = index;
            },
            setDateInCalendar(day_name, index) {
                if(index == 1) {
                    this.periodDates.start_date = day_name;
                }
                else {
                    this.periodDates.end_date = day_name;
                }
                
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.onSearch();
            },
            clearStartDate() {
                this.periodDates.start_date = '';
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.onSearch();
            },
            clearEndDate() {
                this.periodDates.end_date = '';
                this.periodDates.showCalendar_1 = false;
                this.periodDates.showCalendar_2 = false;
                this.periodDates.error = false;
                
                this.onSearch();
            },
            selectGroupLevel_1() {
                this.onSearch();
            },
            checkList_MultiSelect() {
                let data = [];
        
                for(let item of this.list) {
                    if(item.checkBox) {
                        data.push({
                            serial_num_id: item.serial_num_id,
                            serial_number: item.serial_number_original,
                            serial_number_created_at: item.created_at,
                            model_id: item.model_id,
                            fname: item.fname,
                            technical_cert: item.model_technical_cert,
                        });
                    }
                }
                
                this.multiSelect = data;
            },
            downloadMultiSelectDocument() {
                
                this.$bus.emit('showLoading', 'Получение данных...');
                
                this.$postRequest(this.$config.urlDownloadMultiSelect,
                {
                    account_id: this.account_id,
                    data: this.multiSelect,
                    urlMain: this.$config.urlMain,
                }
                , localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    
                    var url = this.$config.urlMain + 'tmp-download-zip/' + data.file_name;
                    var a = document.createElement('a');
                    a.href = url;
                    document.body.appendChild(a);
                    a.click();    
                    //a.remove();
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            }
        }
    }
</script>

<style>
    .cls_list_item_input strong {
        color: #166adf;
    }
    .cls_list_item_model_name strong {
        color: #166adf;
    }
</style>
<style scoped>
    #cls_root {
    }
    #cls_header {
        display: flex;
        align-items: center;
        margin: 17px 22px 23px 42px;
    }
    #cls_header_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
        color: #454A4D;
        flex-grow: 1;
        
    }
    #cls_header_search {
        font-size: 11px;
        padding: 8px 8px 8px 36px;
        border: 1px solid #E2E1E1;
        border-radius: 5px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-image: url(/images/search.svg);
        background-position: 15px center;
    }
    #cls_body {
        margin: 0 25px 20px 42px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 0;
    }
    #searchBySerialNumber_c {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    #searchBySerialNumber {
        width: 350px;
    }
    #searchBySerialNumber > input {
        text-transform: uppercase;
    }
    #searchByTextModel {
        width: 450px;
    }
    
    .inputWithClearBtn {
        position: relative;
    }
    .inputWithClearBtn > input {
        width: 100%;
        border: 1px solid #CCC;
        padding: 6px;
        font-size: 14px;
    }
    .inputWithClearBtn > input::-webkit-input-placeholder {text-transform: lowercase;}
    .inputWithClearBtn > input::-moz-placeholder {text-transform: lowercase;}
    .inputWithClearBtn > img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 5px;
        top: 7px;
        cursor: pointer;
        opacity: 0.1;
    }
    .inputWithClearBtn > img:hover {
        opacity: 0.3;
    }
    .cls_list_item_header {
        padding: 11px 18px;
        display: grid;
        grid-template-columns: 40px 110px 1fr 1fr 1fr 2fr 185px;
        align-items: center;
        width: 100%;
        margin-bottom: 5px;
    }
    .cls_list_item_header > span {
        
        color: rgba(69, 74, 77, 0.4);
        font-size: 12px;
    }
    .cls_list_item {
        padding: 11px 18px;
        display: grid;
        grid-template-columns: 40px 110px 1fr 1fr 1fr 2fr 185px;
        align-items: center;
        width: 100%;
        background: #FFF;
        border-radius: 5px;
        margin-bottom: 8px;
    }
    .cls_list_item > *:first-child {
        justify-self: start;
        margin-left: 3px;
    }
    .cls_list_item_input {
        width: 100%;
        flex-grow: 1;
        padding: 5px;
        text-transform: uppercase;
        font-size: 14px;
    }
    
    .cls_list_item_model_name {
        margin-right: 10px;
        
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #454A4D;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 20px;        /* fallback */
        max-height: 40px;       /* fallback */
    }
    .cls_list_item_group_name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.3);
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        white-space: nowrap;
    }
    .cls_list_itemTimeCreate {
        font-size: 12px;
        white-space: nowrap;
        display: inline-block;
        margin-right: 15px;
    }
    .btnDownloadQrCode {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        background: #2094FF;
        box-shadow: 0px 5px 15px rgba(0, 143, 234, 0.1);
        border-radius: 5px;
        width: 180px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
    }
    .btnDownloadQrCode:hover {
        background: #2a8ae1;
    }
    
    #searchBySerialNumber_c_title {
        font-size: 14px;
        margin-bottom: 14px;
    }
    .searchBySerialNumber_c_item {
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
        position: relative;
    }
    .searchBySerialNumber_c_item_title {
        font-size: 14px;
        display: inline-block;
        margin-right: 10px;
    }
    .searchBySerialNumber_c_item select {
        border: 1px solid #CCC;
        background: #FFF;
        padding: 6px;
    }
    .searchBySerialNumber_c_line {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .searchBySerialNumber_c_groupSelect {
        width: 250px;
        font-size: 14px;
    }
    .searchBySerialNumber_c_periodDates {
        border: 1px solid #ccc;
        padding: 6px 10px;
        line-height: 17px;
        display: inline-block;
        background: #FFF;
        font-size: 14px;
        margin-right: 10px;
        position: relative;
        min-width: 116px;
    }
    
    .searchBySerialNumber_c_periodDates_popupCalendar {
        position: absolute;
        top: 35px;
        left: -2px;
        border-radius: 10px;
        background: #FFF;
        border: 1px solid #C7D5E1;
        padding: 6px 10px;
        display: none;
        transition: opacity 1s ease-out;
        opacity: 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }
    .searchBySerialNumber_c_periodDates_popupCalendar-show {
        opacity: 1;
        display: block;
    }
    .searchBySerialNumber_c_periodDates_clearBtn {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 5px;
        top: 7px;
        cursor: pointer;
        opacity: 0.1;
    }
    .searchBySerialNumber_c_periodDates_clearBtn:hover {
        opacity: 0.3;
    }
    .searchBySerialNumber_c_periodDates_haveError > .searchBySerialNumber_c_periodDates {
        border-color: red;
    }
    
    #cls_list_empty {
        font-size: 14px;
        margin: 20px 5px;
    }
    #btnDownloadMultiSelect {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #FFFFFF;
        background: #15850e;
        box-shadow: 0px 5px 15px rgba(0, 143, 234, 0.1);
        border-radius: 5px;
        width: 180px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        visibility: hidden;
    }
    #btnDownloadMultiSelect:hover {
        background: #196814;
    }
    .show_btnDownloadMultiSelect {
        visibility: visible !important;
    }
</style>