<template>
    <div id="cpanel_root_left">
        <div id="cpanel_root_leftHeader">
            <router-link to="/"><img src="/images/logo.svg" /></router-link>
        </div>
        <div id="cpanel_root_leftMenu">
            <router-link
                class="cpanel_root_leftMenu_item"
                :class="{'cpanel_root_leftMenu_active': activeMenu == 1 }"
                to="/"
                >Все товары</router-link>
            <router-link
                class="cpanel_root_leftMenu_item"
                :class="{'cpanel_root_leftMenu_active': activeMenu == 2 }"
                to="/serial-numbers"
                >Серийные номера</router-link>
        </div>
        <div class="cpanel_root_leftFooterItem">
            <img src="/images/left-menu-icon.svg" />
            <div class="cpanel_root_leftFooterItem_PhoneBlock">
                <div class="cpanel_root_leftFooterItem_PhoneBlock_first">8 800 000 00 00</div>
                <div class="cpanel_root_leftFooterItem_PhoneBlock_second">телефон службы поддержки</div>
            </div>
        </div>
        <div class="cpanel_root_leftFooterItem">
            <img src="/images/left-menu-icon.svg" />
            <div class="cpanel_root_leftFooterItem_PhoneBlock">
                <div class="cpanel_root_leftFooterItem_PhoneBlock_first">support@mail.ru</div>
                <div class="cpanel_root_leftFooterItem_PhoneBlock_second">почта службы поддержки</div>
            </div>
        </div>

        <div class="cpanel_root_left_footer">
            @Ltd. LockerBox 2021
        </div>

    </div>
</template>

<script>
    export default {
        name: 'LeftMenu',
        emits: ["changeMenu"],
        props: {
            activeLeftMenu:  { type: Number, default: 0 },
        },
        data() {
            return {
                activeMenu: this.activeLeftMenu,
            };
        },
        methods: {
            setActiveMenu(num) {
                this.activeMenu = num;
                this.$emit("changeMenu", num);
            },
        }
    }
</script>

<style scoped>
    #cpanel_root_left {
        background: #FFF;
        width: 250px;
        min-width: 250px;
        display: flex;
        flex-direction: column;
    }
    #cpanel_root_leftHeader {
        height: 75px;
        border-bottom: 1.5px solid rgba(231, 231, 232, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #cpanel_root_leftHeader > img {
        display: inline-block;
        margin-top: 27px;
        margin-left: 29px;
    }
    #cpanel_root_leftMenu {
        flex-grow: 1;
    }
    .cpanel_root_leftMenu_item {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #454A4D;
        margin-left: 55px;
        margin-top: 28px;
        margin-bottom: 28px;
        cursor: pointer;
        display: block;
        text-decoration: none;
    }
    .cpanel_root_leftMenu_active {
        color: #2094FF;
    }
    .cpanel_root_leftFooterItem {
        border-top: 1px solid #E7E7E8;
        display: flex;
        padding-top: 26px;
        padding-left: 29.63px;
        padding-bottom: 21px;
    }
    .cpanel_root_leftFooterItem_PhoneBlock {
        display: flex;
        flex-direction: column;
    }
    .cpanel_root_leftFooterItem > img {
        width: 19px;
        height: 19px;
        display: inline-block;
        margin-right: 19.63px;
    }
    .cpanel_root_leftFooterItem_PhoneBlock_first {
        margin-bottom: 2px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #454A4D;
    }
    .cpanel_root_leftFooterItem_PhoneBlock_second {
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 20px;
        color: #646A6D;
    }
    .cpanel_root_left_footer {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: rgba(100, 106, 109, 0.5);
        padding: 21px 29px;
    }
</style>