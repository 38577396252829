<template>
    <div id="cpanel_root">
        <LeftMenu @changeMenu="changeMenu"/>
        <div id="cpanel_root_right">
            <CpanelHeader :navigation="[{name: 'Главная', index: 0}, {name: 'Поиск', index: 1}]" @gotoNav="gotoNav" />
            <div id="search">
                <div id="search_header">
                    <span id="search_header_title">Поиск</span>
                </div>
                <div id="searchField">
                    <input placeholder="Поиск"
                           v-model="serach_value"
                           ref="searchInput"
                           maxlength="100"
                           @keyup="onSearch"/>
                    <img src="/images/clear-search-input.svg" @click.stop="clearSearch" />
                </div>
                
                <div id="list_result">
                    <div id="list_result_header" v-if="list.length > 0">
                        <span>Изобр.</span>
                        <span>Название</span>
                        <span>Описание</span>
                        <span>Гарантия</span>
                        <span v-if="typeAccount == 2 || typeAccount == 4"></span>
                    </div>
                    <div
                        v-for="(item, index) in list"
                        :key="index"
                        class="list_result_item"
                        >
                        <img class="list_result_item_img" :src="$config.urlMain + 'images/models/' + account_id + '/' + item.main_img" />
                        <router-link :to="'/open-model/' + item.id" class="list_result_itemModelNameAndManufacturerName">
                            <span class="list_result_item_name" v-html="item.name"></span>
                            <span>{{ item.group1_name }}/<br/>{{ item.group2_name }}</span>
                            <span v-html="item.manufacturer_name"></span>
                        </router-link>
                        
                        <span class="list_result_item_longText" v-html="item.description"></span>
                        <span class="list_result_item_longText" v-html="item.guarantee"></span>
                        <div class="clm_btn"
                             v-if="typeAccount == 2 || typeAccount == 4"
                             @click.stop="fun_editModel(item.id, item.group_id)">
                            <img src="/images/edit-model.svg" />
                            <span>Редактировать</span>
                        </div>
                    </div>
                    <div id="list_result_empty" v-if="list.length == 0">Список пуст</div>
                </div>
                
            </div>
        </div>
    </div>
    
    <PopupAddEditModel ref="formAddEditModel" 
                       :currentGroup="editModel.group_id"
                       @savePopupAddEditModel="onSearch"
                       @deleteModel="onSearch"
                       />
</template>

<script>
    import LeftMenu from './LeftMenu.vue'
    import CpanelHeader from './CpanelHeader.vue'
    import PopupAddEditModel from './PopupAddEditModel.vue'
    
    import router from '@/router'
    
    export default {
        name: 'Поиск',
        title: 'Поиск - Панель управления',
        components: {
            LeftMenu, CpanelHeader, PopupAddEditModel
        },
        data() {
            return {
                typeAccount: null,
                serach_value: '',
                list: [],
                timeoutSearch: null,
                account_id: null,
                editModel: {
                    id: null,
                    group_id: null,
                }
            };
        },
        created() {
            if( ! localStorage.mToken ) {
                router.push('/login');
            }
            
            this.typeAccount = localStorage.typeAccount;
            
            setTimeout(() => {
                this.$refs.searchInput.focus();
            }, 200);
        },
        methods: {
            escapeRegExChars: function (value) {
                return value.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
            },
            gotoNav(index) {
                if(index == 0) {
                    router.push('/');
                }
            },
            changeMenu(index) {
                this.activeMenu = parseInt(index);
                localStorage.activeLeftMenu = this.activeMenu;
                router.push('/')
            },
            runSearch() {
                this.$postRequest(this.$config.urlSearch,
                {
                    value: this.serach_value
                }, localStorage.mToken)
                .then((data) => {
                    this.account_id = data.account_id;
                    let mass = data.data;
                    var pattern = '(' + this.escapeRegExChars(this.serach_value) + ')';

                    for(let item of mass) {
                        if( item.description.indexOf(this.serach_value) > -1 ) {
                            item.description = item.description.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
                        }
                        if( item.guarantee.indexOf(this.serach_value) > -1 ) {
                            item.guarantee = item.guarantee.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
                        }
                        if( item.vendor_code.indexOf(this.serach_value) > -1 ) {
                            item.vendor_code = item.vendor_code.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
                        }
                        if( item.name.indexOf(this.serach_value) > -1 ) {
                            item.name = item.name.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
                        }
                    }

                    this.list = mass;
                })
                .catch(() => {
                });
            },
            onSearch() {
                if(this.serach_value.length > 2) {
                    if(this.timeoutSearch != null) {
                        clearTimeout(this.timeoutSearch);
                        this.timeoutSearch = null;
                    }
                    
                    this.timeoutSearch = setTimeout(() => { this.runSearch() }, 550);
                }
                else {
                    if(this.timeoutSearch != null) {
                        clearTimeout(this.timeoutSearch);
                        this.timeoutSearch = null;
                    }
                }
            },
            clearSearch() {
                this.serach_value = '';
            },
            fun_editModel(id, group_id) {
                this.editModel.id        = id;
                this.editModel.group_id  = group_id;
                
                console.log( this.editModel );
                
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadInfoModel + id, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.$refs.formAddEditModel.show(true, data.data, data.account_id);
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            }
        }
    }
</script>

<style>
    .list_result_item > span strong {
        color: #166adf; 
    }
    .list_result_item_name > strong {
        color: #166adf; 
    }
</style>
<style scoped>
    #cpanel_root {
        display: flex;
        min-height: 100vh;
    }
    
    #cpanel_root_right {
        flex-grow: 1;
        background: #FAFAFA;
    }
    
    #search {
        display: flex;
        flex-direction: column;
        background: #FFF;
        margin: 20px;
        padding: 36px;
    }
    #search_header_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
        color: #454A4D;
        flex-grow: 1;
        
    }
    
    #searchField {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 18px;
    }
    #searchField > input {
        width: 100%;
        border: 1px solid #CCC;
        padding: 6px;
        font-size: 16px;
    }
    #searchField > img {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        opacity: 0.1;
    }
    #searchField > img:hover {
        opacity: 0.3;
    }
    #list_result_header {
        display: grid;
        grid-template-columns: 75px 1fr 2fr 2fr 180px;
        grid-gap: 10px;
        margin-bottom: 5px;
        padding: 14px;
        font-size: 12px;
    }
    .list_result_item {
        display: grid;
        grid-template-columns: 75px 1fr 2fr 2fr 180px;
        grid-gap: 10px;
        align-items: center;
        background: #fafafa;
        border-radius: 5px;
        overflow: hidden;
        padding: 14px;
        font-size: 12px;
        line-height: 14px;
        color: rgba(69, 74, 77, 0.9);
        margin-bottom: 5px;
    }
    .list_result_item > span {
        display: inline-block;
    }
    
    .list_result_item_img {
        width: 70px;
        height: 70px;
        display: inline-block;
    }
    
    .clm_btn {
        width: 178px;
        height: 45px;
        padding: 0 25px;
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .clm_btn:hover {
        background: rgba(32, 148, 255, 0.2);
    }
    .clm_btn > img {
        width: 13px;
        height: 13px;
        display: inline-block;
        margin-right: 15px;
        
    }
    .clm_btn > span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #2094FF;
    }
    .list_result_item_longText {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(69, 74, 77, 0.9);
        
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 18px;        /* fallback */
        max-height: 38px;       /* fallback */
    }
    .list_result_itemModelNameAndManufacturerName {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: rgba(69, 74, 77, 0.9);
    }
    .list_result_itemModelNameAndManufacturerName:hover {
        text-decoration: underline;
    }
    .list_result_itemModelNameAndManufacturerName > span {
        font-size: 12px;
        display: block;
        margin-bottom: 3px;
    }
    .list_result_item_name {
        font-size: 15px !important;
        margin-bottom: 5px !important;
        
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 18px;        /* fallback */
        max-height: 38px;       /* fallback */
    }
    #list_result_empty {
        padding: 5px;
        font-size: 14px;
    }
</style>