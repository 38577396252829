<template>
    <div class="popup_addEditGroup" v-show="showContent">
        <div class="popup_addEditGroup_form">
            <div class="popup_addEditGroup_formCloseBtn" @click.stop="closeForm()"></div>
            <div id="popup_addEditGroup_form_title">{{ form.title }}</div>
            <div id="popup_addEditGroup_body">
                <div class="popup_addEditGroup_body_label">Наименование группы*</div>
                <div id="popup_addEditGroup_body_groupName">
                    <input
                        maxlength="200"
                        @input="checkForm" 
                        v-model="form.name"/>
                </div>
                <div id="popup_addEditGroup_body_hint">
                    *в этом поле укажите общее название группы представленной продукции, например “Банковское оборудование” в которую в свою очередь могут входить подгруппы “Банкоматы”, “Счетные машинки”, “Терминалы оплаты” и т.д.
                </div>
                <div class="popup_addEditGroup_body_label">Описание группы</div>
                <textarea 
                    id="popup_addEditGroup_body_descr"
                    @input="checkForm"
                    v-model="form.descr"
                    v-on:keyup="form.descr = limitLength($event.target, 1024)"
                    ></textarea>
            </div>
            <div id="popup_addEditGroup_formError">{{ form.error }}</div>
            <div id="popup_addEditGroup_formFooter">
                <div class="btnMain"
                     :class="{'btnMain_active': form.statusOK}"
                     @click.stop="execSave()"
                     >
                    {{ ! form.modeEdit ? 'Создать группу' : 'Сохранить изменения' }}
                </div>
                <span id="popup_addEditGroup_linkDelete"
                      v-if="form.modeEdit"
                      @click.stop="deleteGroup()"
                      >Удалить</span>
            </div>
        </div>
    </div>
    
</template>

<script>
    //import { toast } from '@/toast.js'
    import * as global from '../global.js'
    
    export default {
        name: 'PopupAddEditGroup',
        emits: ["closeForm", 'savePopupAddEditGroup'],
        data() {
            return {
                showContent: false,
                form: {
                    title: 'Создание группы',
                    modeEdit: false,
                    editId: 0,
                    parent_id: 0,
                    name: '',
                    descr: '',
                    url: '',
                    statusOK: false,
                    error: '',
                }
            };
        },
        methods: {
            limitLength(obj, value) {
                return global.limitTextareaLength(obj, value);
            },
            show(title, modeEdit, parent_id = 0, id = 0, name = '', description = '', name_url = '') {
                this.resetForm();
                
                this.form.title         = title;
                this.form.modeEdit      = modeEdit;
                this.form.editId        = id;
                this.form.parent_id     = parent_id;
                this.form.name          = name;
                this.form.descr         = description;
                this.form.name_url      = name_url;
                
                console.log(this.form);
                
                this.checkForm();
                this.showContent = true;
            },
            hide() {
                this.showContent = false;
            },
            closeForm() {
                this.$emit("closeForm");
                this.hide();
            },
            checkForm() {
                let name  = this.form.name.trim();
                let descr = this.form.descr.trim();
                
                if(name.length == 0 || descr.length == 0) {
                    this.form.statusOK = false;
                    return;
                }
                
                this.form.statusOK = true;
                this.form.url = global.transliterationForUrl(name);
                
                //console.log(url);
            },
            resetForm() {
                this.form.modeEdit       = false;
                this.form.editId         = 0;
                this.form.parent_id   = 0;
                this.form.name           = '';
                this.form.descr          = '';
                this.form.name_url       = '';
                this.form.error          = '';
                this.form.statusOK       = false;
            },
            execSave() {
                if(! this.form.statusOK) {
                    return;
                }
                
                this.$bus.emit('showLoading');
                
                if(this.form.modeEdit) {
                    this.$postRequest(this.$config.urlEditGroup, {
                        id: this.form.editId,
                        name: this.form.name.trim(),
                        descr: this.form.descr.trim(),
                        name_url: this.form.url,
                    }, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        this.resetForm();
                        this.hide();
                        this.$emit("savePopupAddEditGroup");
                    })
                    .catch((data) => {
                        this.$bus.emit('hideLoading');
                        if(data.type_error === 'api') {
                            if(data.data.key === 'exist group by name') {
                                //toast.toast( { type: 'text', value: 'Груп' }, { settings: { duration: 4300 } });
                                this.form.error = 'Группа с таким именем уже существует';
                            }
                        }
                    });
                }
                else {
                    this.$postRequest(this.$config.urlSaveNewGroup, {
                        parent_id: this.form.parent_id,
                        name: this.form.name.trim(),
                        descr: this.form.descr.trim(),
                        name_url: this.form.url,
                    }, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        this.resetForm();
                        this.hide();
                        this.$emit("savePopupAddEditGroup");
                    })
                    .catch((data) => {
                        this.$bus.emit('hideLoading');
                        if(data.type_error === 'api') {
                            if(data.data.key === 'exist group by name') {
                                //toast.toast( { type: 'text', value: 'Груп' }, { settings: { duration: 4300 } });
                                this.form.error = 'Группа с таким именем уже существует';
                            }
                        }
                    });
                }  
            },
            deleteGroup() {
                if(! confirm("Удалить группу?")) {
                    return;
                }
                
                this.$bus.emit('showLoading');
                
                this.$deleteRequest(this.$config.urlDeleteGroup + this.form.editId, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        this.resetForm();
                        this.hide();
                        this.$emit("savePopupAddEditGroup");
                    });
            }
        }
    }
</script>

<style scoped>
    .popup_addEditGroup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(150, 150, 150, 0.1);
    }
    .popup_addEditGroup_form {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 500px;
        min-height: 394px;
        top: 48%;
        left: 50%;
        border: 1px solid #eee;
        transform: translate(-50%, -50%);
        background: #fefefe;
        padding: 51px 51px 51px 50px;
    }
    .popup_addEditGroup_form_center {
        align-items: center;
        justify-content: center;
    }
    .popup_addEditGroup_formCloseBtn {
        position: absolute;
        display: inline-block;
        top: 22px;
        right: 29px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/images/close-popup.svg);
        background-size: 20px 20px;
        opacity: 0.7;
        cursor: pointer;
    }
    .popup_addEditGroup_formCloseBtn:hover {
        opacity: 1;
    }
    #popup_addEditGroup_form_title {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 28px;
    }
    #popup_addEditGroup_formError {
        color: red;
        font-size: 12px;
        min-height: 20px;
    }
    #popup_addEditGroup_formFooter {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .btnMain {
        min-width: 270px;
        min-height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: rgba(71, 75, 80, 0.4);
        cursor: default;
        background: rgba(223, 223, 223, 0.4);
        border-radius: 5px;
    }
    .btnMain_active {
        cursor: pointer;
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        color: #FFF;
    }
    .btnMain_active:hover {
        background: #0c86d4;
    }
    
    .popup_addEditGroup_body_label {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.4);
        margin-bottom: 11px;
    }
    #popup_addEditGroup_body_groupName {
        border-bottom: 1px solid #D9D9DA;
        padding-bottom: 11px;
    }
    #popup_addEditGroup_body_groupName > input {
        width: 100%;
        border: none;
    }
    #popup_addEditGroup_body_hint {
        margin-top: 11px;
        margin-bottom: 25px;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: rgba(69, 74, 77, 0.7);
    }
    #popup_addEditGroup_body_descr {
        width: 100%;
        min-height: 125px;
        border: 1px solid #D9D9DA;
        margin-bottom: 12px;
        resize: none;
        padding: 12px;
    }
    #popup_addEditGroup_linkDelete {
        cursor: pointer;
        font-size: 14px;
        color: #666;
    }
    #popup_addEditGroup_linkDelete:hover {
        text-decoration: underline;
    }
</style>