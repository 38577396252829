<template>
    <div class="popup_alert" v-show="showContent">
        <div class="popup_alert_form">
            <div class="popup_alert_formCloseBtn" @click.stop="closeForm()"></div>
            <div id="popup_alert_form_title" v-html="title"></div>
            <div id="popup_alert_formFooter">
                <div class="btnMain btnMain_active"
                     @click.stop="hide"
                     >
                    OK
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    //import * as global from '../global.js'
    
    export default {
        name: 'Alert',
        emits: ["closeForm"],
        data() {
            return {
                title: '',
                showContent: false
            };
        },
        methods: {
            show(title) {
                this.title = title;
                this.showContent = true;
            },
            hide() {
                this.showContent = false;
            },
            closeForm() {
                this.$emit("closeForm");
                this.hide();
            },
        }
    }
</script>

<style>
    #popup_alert_form_title > a {
        color: #2381ff !important;
    }
</style>
<style scoped>
    .popup_alert {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(150, 150, 150, 0.1);
    }
    .popup_alert_form {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: auto;
        top: 48%;
        left: 50%;
        border: 1px solid #eee;
        transform: translate(-50%, -50%);
        background: #fefefe;
        padding: 51px 51px 40px 50px;
    }
    .popup_alert_form_center {
        align-items: center;
        justify-content: center;
    }
    .popup_alert_formCloseBtn {
        position: absolute;
        display: inline-block;
        top: 22px;
        right: 29px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/images/close-popup.svg);
        background-size: 20px 20px;
        opacity: 0.7;
        cursor: pointer;
    }
    .popup_alert_formCloseBtn:hover {
        opacity: 1;
    }
    #popup_alert_form_title {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #454A4D;
        margin-bottom: 20px;
    }
    
    #popup_alert_formFooter {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .btnMain {
        min-width: 150px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: rgba(71, 75, 80, 0.4);
        cursor: default;
        background: rgba(223, 223, 223, 0.4);
        border-radius: 5px;
    }
    .btnMain_active {
        cursor: pointer;
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        color: #FFF;
    }
    .btnMain_active:hover {
        background: #0c86d4;
    }
</style>