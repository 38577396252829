export default class UploadFiles {
    uploadIndex = 0;
    xhr = null;
    cancel = false;
        
    constructor(
              url
            , data
            , files
            , token
            , callbackStart
            , callbackProgressMain
            , callbackProgressSecond
            , callbackEnd
            , callbackOkUpload
            , callbackError
            , callbackErrorRequest
    ){
        this.url = url;
        this.data = data;
        this.token = token;
        this.files = files;
        this.countFiles = files.length;
        
        this.callbackStart           = (callbackStart) ? callbackStart : null;
        this.callbackProgressMain    = (callbackProgressMain) ? callbackProgressMain : null;
        this.callbackProgressSecond  = (callbackProgressSecond) ? callbackProgressSecond : null;
        this.callbackEnd             = (callbackEnd) ? callbackEnd : null;
        this.callbackOkUpload        = (callbackOkUpload) ? callbackOkUpload : null;
        this.callbackError           = (callbackError) ? callbackError : null;
        this.callbackErrorRequest           = (callbackErrorRequest) ? callbackErrorRequest : null;
        
        if(this.callbackStart) {
            this.callbackStart(this.countFiles);
        }
        //console.log(files);
        this.uploadNext();
    }  
    cancel() {
        if(this.xhr) {
            this.xhr.abort();
        }
        this.cancel = true;
    } 
    uploadNext() {
        if(this.cancel) {
            return;
        }
        
        var file = this.files[this.uploadIndex];
        
        if(this.callbackProgressMain) {
            this.callbackProgressMain( (this.uploadIndex + 1) * 100 / this.countFiles, this.uploadIndex + 1, this.countFiles, file );
        }

        var fd = new FormData();
        fd.append("file", file);
        fd.append("pathSave", this.pathSave);

        this.xhr = new XMLHttpRequest();
        this.xhr.open("POST", this.url, true);
        this.xhr.setRequestHeader("X-Token-Secure", this.token);
        this.xhr.upload.onprogress = (e) => {
            
            if(this.callbackProgressSecond) {
                this.callbackProgressSecond( Math.ceil((e.loaded / e.total) * 100), file );
            }
        };

        this.xhr.onload = () => {
            if(this.xhr.status == 200) {
                
                var jsonResponse = JSON.parse(this.xhr.responseText);
                if(jsonResponse.success) {
                    if(this.callbackOkUpload) {
                        this.callbackOkUpload(file, jsonResponse);
                    }
                }
                else {
                    if(this.callbackError) {
                        this.callbackError(file, jsonResponse);
                    }
                }
                
                if((this.uploadIndex + 1 ) == this.countFiles) {
                    this.uploadIndex = 0;
                    if(this.callbackEnd) {
                        this.callbackEnd();
                    }
                } 
                else {
                    this.uploadIndex++;
                    this.uploadNext();
                }
            }
            else {
                if(this.callbackErrorRequest) {
                    this.callbackErrorRequest(this.xhr.status);
                }
            }
        }

        this.xhr.send(fd);
    }
}


