<template>
    <div id="msn_root">
        <div v-show="isPage(PAGE_LIST)">
            <div class="msn_root_header">
                <div class="msn_root_title">Серийные номера</div>
                <button class="btn" @click.stop="openFormPopupAddNewSerialNumbers">
                    <img src="/images/plus.svg" />
                    <span>Добавить</span>
                </button>
            </div>
            <div id="msn_list">
                <div id="searchBySerialNumber_c">
                    <div id="searchBySerialNumber">
                        <input placeholder="Поиск по номеру..."
                               v-model="serach_value"
                               @keyup="onSearch"/>
                        <img src="/images/clear-search-input.svg" @click.stop="clearSearch" />
                    </div>
                </div>
                
                <template v-if="list.length > 0">
                    <div
                        v-for="(item, index) in list"
                        :key="index"
                        class="msn_list_item"
                        >
                        <span class="msn_list_itemTimeCreate">{{ timeConvert(item.created_at) }}</span>
                        <input
                            maxlength="255"
                            class="msn_list_item_input"
                            @keyup="saveSerialNumber(item)"
                            :class="{'msn_list_item_inputActive': item.modeEdit }"
                            v-model="list[index].serial_number" :readonly="! item.modeEdit"/>
                        <img 
                            v-show="item.modeEdit"
                            @click.stop="item.modeEdit = false"
                            class="msn_list_item_icon"
                            src="/images/cancel-edit-serial-number.svg"/>
                        <img 
                            v-show="! item.modeEdit"
                            @click.stop="item.modeEdit = true"
                            class="msn_list_item_icon"
                            src="/images/edit-serial-number.svg"/>
                        <img 
                            v-show="! item.modeEdit"
                            class="msn_list_item_icon"
                            @click.stop="deleteSerialNumber(index, item.id)"
                            src="/images/delete.svg"/>
                    </div>
                </template>
                <template v-else>
                    <div id="msn_list_empty">Список пуст</div>
                </template>
                <div v-if="countPages > 1" id="pages">
                    <span
                        v-for="index in countPages"
                        :key="index"
                        :class="{'page_active': page + 1 == index}"
                        @click.stop="page + 1 != index && loadList(index - 1)">
                        {{ index }}
                    </span>
                </div>
            </div>
        </div>
        <div v-show="isPage(PAGE_ADD)">
            <div class="msn_root_header">
                <div class="msn_root_title">Добавить серийные номера</div>
                <button class="btn" @click.stop="closeFormPopupAddNewSerialNumbers">
                    <span>Закрыть</span>
                </button>
            </div>
            <div id="msn_add_form">
                <div
                    v-for="(item, index) in serialNumbers"
                    :key="index"
                    class="msn_add_form_item"
                    >
                    <input
                        maxlength="255"
                        placeholder="Введите серийный номер..."
                        v-model="serialNumbers[index]" />
                    <img
                        :class="{'unvisible': index == 0}"
                        @click.stop="deleteRow(index)"
                        src="/images/delete.svg"/>
                </div>
                
                <div id="msn_add_form_footer">
                    <button class="btn2" @click.stop="addSerial">+ Добавить ещё</button>
                    <button class="btn3" @click.stop="saveAll">
                        <img src="/images/save.svg"/>
                        <span>Сформировать QR-код ({{ serialNumbers.length }})</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <PopupAddNewSerialNumbers ref="popupAddNewSerialNumbers"/>
</template>

<script>
    import * as global from '../global.js'
    import PopupAddNewSerialNumbers from './PopupAddNewSerialNumbers.vue'
    
    export default {
        name: 'ModelSerialNumbers',
        components: {
            PopupAddNewSerialNumbers
        },
        props: {
            model_id: { type: Number, default: 0 },
        },
        data() {
            return {
                show_page: 0,
                list: [],
                serialNumbers: [''],
                serach_value: '',
                page: 0,
                countPages: null,
            };
        },
        created() {
            this.PAGE_LIST   = 1;
            this.PAGE_ADD    = 2;
            
            this.show_page = this.PAGE_LIST;
            this.loadList(0);
        },
        methods: {
            showPage(id) {
                this.show_page = id;
            },
            isPage(id) {
                return this.show_page == id;
            },
            openFormPopupAddNewSerialNumbers() {
                this.showPage(this.PAGE_ADD);
            },
            closeFormPopupAddNewSerialNumbers() {
                this.showPage(this.PAGE_LIST);
            },
            addSerial() {
                this.serialNumbers.push('');
            },
            deleteRow(index) {
                this.serialNumbers.splice(index, 1);
            },
            timeConvert(time) {
                return global.timeConverterOnlyData(time);
            },
            saveAll() {
                
                let mass = this.serialNumbers.filter((v) => v.length > 0);
                if(mass.length == 0) {
                    return;
                }
                
                mass = mass.map((v) => v.toUpperCase());
                
                this.$bus.emit('showLoading');

                this.$postRequest(this.$config.urlSaveSerialNumbers, {
                    model_id: this.model_id,
                    serialNumbers: mass
                }, localStorage.mToken)
                .then(() => {
                    this.showPage(this.PAGE_LIST);
                    this.$bus.emit('hideLoading');
                    this.serialNumbers = [''];
                    this.loadList();
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                    /*if(data.type_error === 'api') {
                        if(data.data.key === 'old password is not right') {
                            toast.toast( { type: 'text', value: 'Старый пароль указан неверно' }, { settings: { duration: 4300 } });
                        }
                    }*/
                });
            },
            loadList(page = 0) {
                this.$bus.emit('showLoading', 'Получение данных...');

                this.page = page;

                this.$getRequest(this.$config.urlLoadSerialNumbers + page + '/' + this.model_id, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.countPages = data.count_page;
                    let mass = data.data
                    for(let item of mass) {
                        item['timeOutObj'] = null;
                        item['prosessSave'] = false;
                        item['modeEdit'] = false;
                    }
            
                    this.list = mass;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            saveSerialNumber(item) {
                if(item.timeOutObj != null) {
                    clearTimeout(item.timeOutObj);
                    item.timeOutObj = null;
                }
                
                if( ! item.modeEdit) {
                    return;
                }

                item.timeOutObj = setTimeout(() => { 
                    
                    item.prosessSave = true;
                    this.$postRequest(this.$config.urlSaveOneSerialNumbers, {
                        id: item.id,
                        model_id: this.model_id,
                        value: item.serial_number.toUpperCase()
                    }, localStorage.mToken)
                    .then((data) => {
                        item.prosessSave = false;
                        if(data.id) {
                            item.id = data.id;
                        }
                    })
                    .catch(() => {
                        item.prosessSave = false
                    });
                    
                }, 1500);
            },
            deleteSerialNumber(index, id) {
                this.$bus.emit('showLoading', 'Удаление...');

                this.$deleteRequest(this.$config.urlDeleteOneSerialNumbers + id, localStorage.mToken)
                .then(() => {
                    this.$bus.emit('hideLoading');
                    this.list.splice(index, 1);
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            onSearch() {
                if(this.serach_value.length > 2) {
                    this.$postRequest(this.$config.urlSerachSerialNumber,
                    {
                        model_id: this.model_id,
                        value: this.serach_value.toUpperCase()
                    }, localStorage.mToken)
                    .then((data) => {
                        let mass = data.data
                        for(let item of mass) {
                            item['timeOutObj'] = null;
                            item['prosessSave'] = false;
                            item['modeEdit'] = false;
                        }

                        this.list = mass;
                    })
                    .catch(() => {
                    });
                }
                else if(this.serach_value.length == 0) {
                    this.loadList();
                }
            },
            clearSearch() {
                if(this.serach_value.length > 0) {
                    this.serach_value = '';
                    this.loadList();
                }
            }
        }
    }
</script>

<style scoped>
    #msn_root {
        background: #FFF;
        padding: 40px 50px;
    }
    .msn_root_header {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
    .msn_root_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        color: #454A4D;
        flex-grow: 1;
    }
    
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        padding: 0 20px;
        cursor: pointer;
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        border: none;
    }
    .btn:hover {
        background: rgba(32, 148, 255, 0.3);
    }
    .btn > img {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 8px;
    }
    .btn > span {
        font-size: 14px;
    }
    
    .btn2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        padding: 0 20px;
        cursor: pointer;
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        border: none;
    }
    .btn2:hover {
        background: rgba(32, 148, 255, 0.3);
    }
    .btn3 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        padding: 0 10px;
        cursor: pointer;
        background: #2094FF;
        border-radius: 5px;
        border: none;
        color: #FFF;
    }
    .btn3:hover {
        background: #2784da;
    }
    .btn3 > img {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 8px;
    }
    .btn3 > span {
        font-size: 14px;
    }
    #msn_list_empty {
        font-size: 14px;
    }
    #msn_add_form {
        display: flex;
        flex-direction: column;
        width: 500px;
    }
    
    #msn_add_form_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        margin-right: 30px;
    }
    .msn_add_form_item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
    }
    .msn_add_form_item > img {
        width: 16px;
        height: 16px;
        display: inline-block;
        cursor: pointer;
        margin-left: 15px;
    }
    .msn_add_form_item > input {
        padding: 5px;
        border: 1px solid #ccc;
        width: 100%;
        display: block;
        text-transform: uppercase;
    }
    .msn_add_form_item > input::-webkit-input-placeholder {text-transform: capitalize;}
    .msn_add_form_item > input::-moz-placeholder {text-transform: capitalize;}
    .unvisible {
        visibility: hidden;
    }
    .msn_list_item {
        padding: 6px 5px;
        display: flex;
        align-items: center;
    }
    .msn_list_item:hover {
        background: #f8f8f8;
    }
    .msn_list_item_input {
        width: 100%;
        flex-grow: 1;
        border: 1px solid transparent;
        background: transparent;
        padding: 5px;
        text-transform: uppercase;
    }
    .msn_list_item_inputActive {
        background: #FFF;
        border-color: #CCC;
    }
    .msn_list_itemTimeCreate {
        font-size: 12px;
        white-space: nowrap;
        display: inline-block;
        margin-right: 15px;
    }
    .msn_list_item_icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        display: inline-block;
        margin-left: 10px;
    }
    #searchBySerialNumber_c {
        margin-bottom: 20px;
    }
    #searchBySerialNumber {
        position: relative;
        width: 250px;
    }
    #searchBySerialNumber > input {
        width: 100%;
        border: 1px solid #CCC;
        padding: 5px;
        font-size: 14px;
        text-transform: uppercase;
    }
    #searchBySerialNumber > input::-webkit-input-placeholder {text-transform: capitalize;}
    #searchBySerialNumber > input::-moz-placeholder {text-transform: capitalize;}
    #searchBySerialNumber > img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 5px;
        top: 7px;
        cursor: pointer;
        opacity: 0.1;
    }
    #searchBySerialNumber > img:hover {
        opacity: 0.3;
    }
    #pages {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }
    #pages > span {
        font-size: 14px;
        display: inline-block;
        padding: 2px 5px;
        cursor: pointer;
        margin: 0 2px;
    }
    #pages > .page_active {
        cursor: default;
        font-weight: bold;
    }
</style>