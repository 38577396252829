<template>
    <div id="modelInfo" v-if="showContent">
        <div id="modelInfo_name">{{ name }}</div>
        <div id="modelInfo_main">
            <img id="modelInfo_mainImg" :src="$config.urlMain + 'images/models/' + account_id + '/' + main_img" />
            <div id="modelInfo_mainRight">
                <div id="modelInfo_mainRightTitle">{{ name }}</div>
                <div id="modelInfo_mainRightVendorCode">Артикул: &nbsp;{{ vendor_code }}</div>
                
                <div id="modelInfo_mainRightTabs">
                        <div id="modelInfo_mainRightTabsHeader">
                            <span
                                v-for="(item, index) in tabs.tabsNames"
                                :key="index" 
                                :class="{'modelInfo_mainRightTabsHeader_active': tabs.activeIndex == index}"
                                @click.stop="tabs.activeIndex = index"
                                >{{ item }}</span>
                        </div>
                        <div id="modelInfo_mainRightTabsBody">
                            <!-- ------------------------------------------- -->
                            <div v-show="tabs.activeIndex == 0">
                                <div class="modelInfo_mainRightTabs_tab1">
                                    <div class="modelInfo_mainRightTabs_tab1_info">
                                        <div class="modelInfo_mainRightTabs_tab1_label">Описание</div>
                                        <div 
                                            v-show="pages[0].open_description"
                                            class="modelInfo_mainRightTabs_tab1_text">{{ description }}</div>
                                    </div>
                                    <div class="modelInfo_mainRightTabs_tab1_iconOpenClose"
                                         :class="{
                                            'modelInfo_mainRightTabs_tab1_iconOpenClose_open': pages[0].open_description,
                                            'modelInfo_mainRightTabs_tab1_iconOpenClose_close': ! pages[0].open_description
                                         }"
                                         @click.stop="pages[0].open_description = !pages[0].open_description"
                                         ></div>
                                </div>
                                <div class="modelInfo_mainRightTabs_tab1">
                                    <div class="modelInfo_mainRightTabs_tab1_info">
                                        <div class="modelInfo_mainRightTabs_tab1_label">Производитель</div>
                                        <div 
                                            v-show="pages[0].open_manufacturer_name"
                                            class="modelInfo_mainRightTabs_tab1_text">{{ manufacturer_name }}</div>
                                    </div>
                                    <div class="modelInfo_mainRightTabs_tab1_iconOpenClose"
                                         :class="{
                                            'modelInfo_mainRightTabs_tab1_iconOpenClose_open': pages[0].open_manufacturer_name,
                                            'modelInfo_mainRightTabs_tab1_iconOpenClose_close': ! pages[0].open_manufacturer_name
                                         }"
                                         @click.stop="pages[0].open_manufacturer_name = !pages[0].open_manufacturer_name"
                                         ></div>
                                </div>
                                <div class="modelInfo_mainRightTabs_tab1">
                                    <div class="modelInfo_mainRightTabs_tab1_info">
                                        <div class="modelInfo_mainRightTabs_tab1_label">Гарантия</div>
                                        <div
                                            v-show="pages[0].open_guarantee"
                                            class="modelInfo_mainRightTabs_tab1_text">{{ guarantee }}</div>
                                    </div>
                                    <div class="modelInfo_mainRightTabs_tab1_iconOpenClose"
                                         :class="{
                                            'modelInfo_mainRightTabs_tab1_iconOpenClose_open': pages[0].open_guarantee,
                                            'modelInfo_mainRightTabs_tab1_iconOpenClose_close': ! pages[0].open_guarantee
                                         }"
                                         @click.stop="pages[0].open_guarantee = !pages[0].open_guarantee"
                                         ></div>
                                </div>
                            </div>
                            <!-- ------------------------------------------- -->
                            <div v-show="tabs.activeIndex == 1">
                                <div class="modelInfo_mainRightTabs_tab2_docItem">
                                    <span>Руководство по эксплуатации</span>
                                    <span>{{ humanFileSize(manual_doc.fileSize) }}</span>
                                    <a target="_blank" :href="$config.urlMain +'docs/' + account_id + '/'  + manual_doc.fname">скачать</a>
                                </div>
                                <div
                                        v-for="(item, index) in docs"
                                        :key="index"
                                        class="modelInfo_mainRightTabs_tab2_docItem"
                                        >
                                    <span>{{ item.name }}</span>
                                    <span>{{ humanFileSize(item.fileSize) }}</span>
                                    <a target="_blank" :href="$config.urlMain +'docs/' + account_id + '/' + item.fname">скачать</a>
                                </div>
                            </div>
                            <!-- ------------------------------------------- -->
                            <div v-show="tabs.activeIndex == 2">
                                <div class="modelInfo_mainRightTabs_tab2_docItem">
                                    <span>Технический паспорт</span>
                                    <span>{{ humanFileSize(technical_cert.fileSize) }}</span>
                                    <a target="_blank" :href="$config.urlMain +'docs/' + account_id + '/'  + technical_cert.fname">скачать</a>
                                </div>
                            </div>
                            <!-- ------------------------------------------- -->
                            <div v-show="tabs.activeIndex == 3">
                                <div class="modelInfo_mainRightTabs_tab2_urlItem"
                                     v-for="(url, index) in urls"
                                     :key="index"
                                     >
                                    <a target="_blank" :href="url">{{ url }}</a>
                                </div>
                            </div>
                        </div>
                </div>
                
                <div
                    class="btnEditModel"
                    v-if="typeAccount == 2 || typeAccount == 4"
                    @click.stop="editModel">
                    <img src="/images/edit-model.svg" />
                    <span>Редактировать</span>
                </div>
                <div class="marginBottom" v-if="typeAccount == 3"></div>
                
            </div>
        </div>
        
        <ModelSerialNumbers v-if="have_searials" :model_id="id" />
        
    </div>
</template>

<script>
    import * as global from '../global.js'
    import ModelSerialNumbers from './ModelSerialNumbers.vue'
    
    export default {
        name: 'ModelInfo',
        emits: ["editModel"],
        components: {
            ModelSerialNumbers
        },
        props: {
            openModelId: { type: Number, default: 0 },
        },
        data() {
            return {
                showContent: false,
                account_id: null,
                id: 0,
                name: '',
                vendor_code: '',
                have_searials: true,
                main_img: '',
                description: '',
                manufacturer_name: '',
                guarantee: '',
                manual_doc: null,
                technical_cert: null,
                docs: [],
                urls: [],
                tabs: {
                    tabsNames: ['Характеристики', 'Документы', 'Технический паспорт', 'Ссылки'],
                    activeIndex: 0,
                },
                pages: {
                    0: {
                        open_description: false,
                        open_manufacturer_name: false,
                        open_guarantee: false,
                    }
                },
                typeAccount: null,
            };
        },
        created() {
            this.typeAccount = localStorage.typeAccount;
            this.openModel();
        },
        methods: {
            humanFileSize(size) {
                return global.humanFileSize(size);
            },
            update(data) {
                this.id = data.id;
                this.name = data.name;
                this.vendor_code = data.vendor_code;
                this.have_searials = data.have_searials;
                this.main_img = data.main_img;
                this.description = data.description;
                this.manufacturer_name = data.manufacturer_name;
                this.guarantee = data.guarantee;
                this.manual_doc = data.manual_doc;
                this.technical_cert = data.technical_cert;
                this.urls = data.urls;
                this.docs = data.docs;
            },
            openModel() {
                if(this.openModelId == 0) {
                    return;
                }
                
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadInfoModel + this.openModelId, localStorage.mToken)
                .then((data) => {
                    this.account_id = data.account_id;
                    this.update(data.data);
                    this.showContent = true;
                    
                    this.$bus.emit('hideLoading');
                    
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            refresh() {
                this.openModel();
            },
            editModel() {
                this.$emit("editModel", this.id);
            }
        }
    }
</script>

<style scoped>
    #modelInfo {
        margin: 25px 42px 42px 46px;
        width: 100%;
    }
    #modelInfo_name {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
        color: #454A4D;
        margin-bottom: 50px;
    }
    #modelInfo_main {
        background: #FFF;
        padding: 43px;
        margin-bottom: 5px;
        display: flex;
        align-items: flex-start;
        width: 100%;
    }
    #modelInfo_mainImg {
        max-width: 250px;
        max-height: 360px;
        display: inline-block;
        margin-right: 40px;
    }
    #modelInfo_mainRight {
        flex-grow: 1;
    }
    #modelInfo_mainRightTitle {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        color: #454A4D;
    }
    #modelInfo_mainRightVendorCode {
        margin-bottom: 26px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #999;
    }
    
    #modelInfo_mainRightTabs {
        width: 100%;
    }
    #modelInfo_mainRightTabsHeader {
        display: flex;
        align-items: flex-start;
    }
    #modelInfo_mainRightTabsHeader > span {
        cursor: pointer;
        padding: 10px;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #454A4D;
        padding-bottom: 15px;
        white-space: nowrap;
    }
    .modelInfo_mainRightTabsHeader_active {
        border-bottom: 1px solid #2094FF !important;
        cursor: default !important;
    }
    #modelInfo_mainRightTabsBody {
        margin-top: 8px;
    }
    .modelInfo_mainRightTabs_tab1_label {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 7px;
        margin-top: 12px;
    }
    .modelInfo_mainRightTabs_tab1_text {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.7);
        transition: all 2s linear;
        display: block;
    }
    .modelInfo_mainRightTabs_tab1 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding-bottom: 10px;
        display: flex;
        align-items: flex-start;
    }
    .modelInfo_mainRightTabs_tab1_iconOpenClose {
        display: inline-block;
        width: 17px;
        height: 17px;
        background-position: center;
        background-size: 17px 17px;
        background-repeat: no-repeat;
        margin-top: 10px;
        cursor: pointer;
        flex-basis: 50px;
    }
    .modelInfo_mainRightTabs_tab1_iconOpenClose_open {
        background-image: url(/images/up-arrow.svg);
    }
    .modelInfo_mainRightTabs_tab1_iconOpenClose_close {
        background-image: url(/images/down-arrow.svg);
    }
    .modelInfo_mainRightTabs_tab1_info {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .modelInfo_mainRightTabs_tab2_docItem {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f8f8f8;
        font-size: 12px;
        color: rgba(69, 74, 77, 0.7);
    }
    .modelInfo_mainRightTabs_tab2_docItem > *:first-child {
        flex-grow: 1;
        color: #555;
    }
    .modelInfo_mainRightTabs_tab2_docItem > * {
        display: inline-block;
        margin-right: 10px;
    }
    .modelInfo_mainRightTabs_tab2_docItem > a {
        color: #2094FF;
    }
    .modelInfo_mainRightTabs_tab2_urlItem {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f8f8f8;
        
    }
    .modelInfo_mainRightTabs_tab2_urlItem > a {
        font-size: 12px;
        color: #2094FF;
    }
    .btnEditModel {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #2094FF;
        filter: drop-shadow(0px 5px 15px rgba(0, 143, 234, 0.1));
        border-radius: 5px;
        width: 178px;
        height: 40px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .btnEditModel:hover {
        background-color: #f8f8f8;
    }
    .btnEditModel > img {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 15px;
    }
    .btnEditModel > span {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #2094FF;
    }
    .marginBottom {
        margin-top: 35px;
    }
</style>