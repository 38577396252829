<template>
    <div class="popup_addEditOrgUser" v-show="showContent">
        <div class="popup_addEditOrgUser_form">
            <div class="popup_addEditOrgUser_formCloseBtn" @click.stop="closeForm()"></div>
            <div id="popup_addEditOrgUser_form_title">{{ ! form.modeEdit ? 'Добавление пользователя' : 'Редактирование пользователя' }}</div>
            <div id="popup_addEditOrgUser_formTwoColumns">
                <div>
                    <div class="input_box"
                         :class="{'input_box_error': form.errorCode == ERROR_TYPE__NOT_SET_LAST_NAME}"
                        >
                       <div class="input_box_title">Фамилия</div>
                       <div class="input_box_footer">
                           <input
                              placeholder="Введите фамилию"
                              v-model="form.last_name"
                              maxlength="80" />
                       </div>
                    </div>
                    <div class="input_box"
                         :class="{'input_box_error': form.errorCode == ERROR_TYPE__NOT_SET_FIRST_NAME}"
                         >
                       <div class="input_box_title">Имя</div>
                       <div class="input_box_footer">
                           <input
                              placeholder="Введите имя"
                              v-model="form.first_name"
                              maxlength="80" />
                       </div>
                    </div>
                    <div class="input_box"
                         :class="{'input_box_error': form.errorCode == ERROR_TYPE__NOT_SET_POSITION}"
                         >
                       <div class="input_box_title">Должность</div>
                       <div class="input_box_footer">
                           <input
                              placeholder="Введите должность"
                              v-model="form.position"
                              maxlength="150" />
                       </div>
                    </div>
                    <div class="input_box"
                         :class="{'input_box_error': form.errorCode == ERROR_TYPE__PHONE}"
                         >
                       <div class="input_box_title">Телефон</div>
                       <div class="input_box_footer">
                           <input
                              placeholder="Введите телефон"
                              v-model="form.phone"
                              v-mask="'+7(###) ### ## ##'"
                              maxlength="50" />
                       </div>
                    </div>
                </div>
                
                <div>
                    <div class="input_box"
                         :class="{'input_box_error': form.errorCode == ERROR_TYPE__EMAIL}"
                         >
                       <div class="input_box_title">Введите E-mail </div>
                       <div class="input_box_footer">
                           <input
                              placeholder="Введите E-mail "
                              v-model="form.email"
                              maxlength="80" />
                       </div>
                    </div>
                    <div class="input_box"
                            :class="{'input_box_error': form.errorPassword }"
                            >
                           <div class="input_box_title">Введите новый пароль</div>
                           <div class="input_box_footer">
                               <input 
                                  :type="form.showPassword ? 'text' : 'password'"
                                  :placeholder="form.showPassword ? '' : '* * * * * * * * * *'"
                                  v-model="form.new_password_1"
                                  @input="checkPasswords"
                                  maxlength="50" />
                               <div
                                   class="input_box_iconShowHidePassword"
                                   :class="{
                                       'input_box_showPassword': ! form.showPassword,
                                       'input_box_hidePassword': form.showPassword,
                                   }"
                                   @click.stop="form.showPassword = ! form.showPassword"
                                   ></div>
                           </div>
                    </div>
                    
                    <div class="input_box"
                            :class="{'input_box_error': form.errorPassword }"
                            >
                           <div class="input_box_title">Повторите новый пароль</div>
                           <div class="input_box_footer">
                               <input 
                                  :type="form.showPassword ? 'text' : 'password'"
                                  :placeholder="form.showPassword ? '' : '* * * * * * * * * *'"
                                  v-model="form.new_password_2"
                                  @input="checkPasswords"
                                  maxlength="50" />
                               <div
                                   class="input_box_iconShowHidePassword"
                                   :class="{
                                       'input_box_showPassword': ! form.showPassword,
                                       'input_box_hidePassword': form.showPassword,
                                   }"
                                   @click.stop="form.showPassword = ! form.showPassword"
                                   ></div>
                           </div>
                    </div>
                    
                    <div class="input_box_2"
                         :class="{'input_box_error': form.errorCode == ERROR_TYPE__EMAIL}"
                         >
                       <div class="input_box_title">Выберите права</div>
                       <div class="input_box_footer">
                            <div class="form_reg_checkbox" @click.stop="setTypeAccount(ADMIN)">
                                 <div class="form_reg_checkbox_icon"
                                      :class="{
                                         'form_reg_checkbox_icon_checked': form.typeAccount == ADMIN,
                                         'form_reg_checkbox_icon_unchecked': form.typeAccount != ADMIN,
                                      }"
                                      ></div>
                                 <div class="form_reg_checkbox_name">Администратор</div>
                             </div>
                             <div class="form_reg_checkbox" @click.stop="setTypeAccount(USER)">
                                 <div class="form_reg_checkbox_icon"
                                      :class="{
                                         'form_reg_checkbox_icon_checked': form.typeAccount == USER,
                                         'form_reg_checkbox_icon_unchecked': form.typeAccount != USER,
                                      }"
                                      ></div>
                                 <div class="form_reg_checkbox_name">Пользователь</div>
                             </div>
                       </div>
                    </div>
                    
                </div>
            </div>
            <div id="popup_addEditOrgUser_formError">{{ form.error }}</div>
            <div id="popup_addEditOrgUser_formFooter">
                <div class="btnMain btnMain_active"
                     @click.stop="saveForm()">
                    Сохранить
                </div>
                <span id="popup_addEditOrgUser_linkDelete"
                      v-if="form.modeEdit"
                      @click.stop="deleteUser"
                      >Удалить</span>
            </div>
        </div>
    </div>
    
</template>

<script>
    import * as global from '../global.js'
    
    export default {
        name: 'PopupAddEditOrgUser',
        emits: ["closeForm", "saveForm"],
        data() {
            return {
                showContent: false,
                form: {
                    modeEdit: false,
                    errorPassword: false,
                    editId: 0,
                    last_name: '',
                    first_name: '',
                    position: '',
                    phone: '',
                    email: '',
                    new_password_1: '',
                    new_password_2: '',
                    error: '',
                    errorCode: 0,
                    typeAccount: 1,
                }
            };
        },
        created() {
            this.ERROR_TYPE__NOT_SET_LAST_NAME  = 1;
            this.ERROR_TYPE__NOT_SET_FIRST_NAME = 2;
            this.ERROR_TYPE__NOT_SET_POSITION   = 3;
            this.ERROR_TYPE__EMAIL              = 4;
            this.ERROR_TYPE__PHONE              = 5;
            this.ERROR_TYPE__SET_PASSWORD       = 6;
            //------------------------------------------------------------------
            this.USER       = 1;
            this.ADMIN      = 2;
            //------------------------------------------------------------------
        },
        methods: {
            setTypeAccount(v) {
                this.form.typeAccount = v;
            },
            show(modeEdit, data) {
                this.resetForm();
                
                this.form.modeEdit = modeEdit;
                if(this.form.modeEdit && data) {
                    this.form.editId = data.id;
                    this.form.first_name = data.first_name;
                    this.form.last_name  = data.last_name;
                    this.form.position   = data.position;
                    this.form.email      = data.email;
                    this.form.phone      = data.phone;
                    this.form.typeAccount      = data.type_account;
                }
                
                this.showContent = true;
            },
            hide() {
                this.showContent = false;
            },
            resetForm() {
                this.form = {
                    modeEdit: false,
                    errorPassword: false,
                    editId: 0,
                    last_name: '',
                    first_name: '',
                    position: '',
                    phone: '',
                    email: '',
                    new_password_1: '',
                    new_password_2: '',
                    error: '',
                    errorCode: 0,
                    typeAccount: 1,
                }
            },
            checkPasswords() {
                
                this.form.errorPassword = this.form.new_password_1.length < 6 || this.form.new_password_1 != this.form.new_password_2;
                
                if(this.form.errorPassword) {
                    this.form.errorCode = this.ERROR_TYPE__SET_PASSWORD;
                }
            },
            closeForm() {
                this.$bus.emit("closeFormPopupChangePassword");
                this.hide();
            },
            saveForm() {
                this.form.error = '';
                this.form.errorCode = 0;
                let last_name = this.form.last_name.trim();
                let first_name = this.form.first_name.trim();
                let position = this.form.position.trim();
                let phone = this.form.phone.trim();
                let email = this.form.email.trim();
                
                if(last_name.length == 0) {
                    this.form.error = 'Не указана фамилия';
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_LAST_NAME;
                    return;
                }
                if(first_name.length == 0) {
                    this.form.error = 'Не указано имя';
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_FIRST_NAME;
                    return;
                }
                if(position.length == 0) {
                    this.form.error = 'Не указана должность';
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_POSITION;
                    return;
                }
                if( ! global.validatePhone(phone) ) {
                    this.form.error = 'Не указан телефон';
                    this.form.errorCode = this.ERROR_TYPE__PHONE;
                    return;
                }
                if( ! global.validateEmail(email) ) {
                    this.form.error = 'Не указан email';
                    this.form.errorCode = this.ERROR_TYPE__EMAIL;
                    return;
                }
                
                if(this.form.editId == 0) {
                    if( this.form.new_password_1.length == 0 || this.form.new_password_2.length == 0 ) {
                        this.form.error = 'Не указан пароль';
                        return;
                    }
                    if( this.form.errorPassword ) {
                        this.form.error = 'Пароли не совпадают';
                        return;
                    }
                }
                else if( this.form.new_password_1.length > 0 || this.form.new_password_2.length > 0 ) {
                    if( this.form.errorPassword ) {
                        this.form.error = 'Пароли не совпадают';
                        return;
                    }
                }
                
                if(this.form.editId == 0) {
                    this.$bus.emit('showLoading');

                    this.$postRequest(this.$config.urlSaveNewOrgUser, {
                        lastName: last_name,
                        firstName: first_name,
                        position: position,
                        phone: phone,
                        email: email,
                        typeAccount: this.form.typeAccount,
                        password: this.form.new_password_1,
                    }, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        this.$emit("saveForm");
                        this.hide();
                    })
                    .catch((data) => {
                        this.$bus.emit('hideLoading');
                        if(data.type_error === 'api') {
                            if(data.data.key === 'email exist') {
                                this.form.error = 'Пользователь с таким email уже существует';
                            }
                        }
                    });
                }
                else {
                    this.$bus.emit('showLoading');

                    this.$postRequest(this.$config.urlSaveEditOrgUser, {
                        editId: this.form.editId,
                        lastName: last_name,
                        firstName: first_name,
                        position: position,
                        phone: phone,
                        email: email,
                        typeAccount: this.form.typeAccount,
                        password: this.form.new_password_1,
                    }, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        this.$emit("saveForm");
                        this.hide();
                    })
                    .catch((data) => {
                        this.$bus.emit('hideLoading');
                        if(data.type_error === 'api') {
                            if(data.data.key === 'email exist') {
                                this.form.error = 'Пользователь с таким email уже существует';
                            }
                        }
                    });
                }
            },
            deleteUser() {
                if(! confirm("Удалить пользователя?")) {
                    return;
                }
                
                this.$bus.emit('showLoading');
                
                this.$deleteRequest(this.$config.urlDeleteOrgUser + this.form.editId, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        this.resetForm();
                        this.hide();
                        this.$emit("saveForm");
                    });
            }
        }
    }
</script>

<style scoped>
    .popup_addEditOrgUser {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(150, 150, 150, 0.1);
    }
    .popup_addEditOrgUser_form {
        position: absolute;
        display: flex;
        flex-direction: column;
        min-width: 704px;
        min-height: 394px;
        top: 48%;
        left: 50%;
        border: 1px solid #eee;
        transform: translate(-50%, -50%);
        background: #fefefe;
        padding: 51px 69px 51px 50px;
    }
    .popup_addEditOrgUser_form_center {
        align-items: center;
        justify-content: center;
    }
    .popup_addEditOrgUser_formCloseBtn {
        position: absolute;
        display: inline-block;
        top: 22px;
        right: 29px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/images/close-popup.svg);
        background-size: 20px 20px;
        opacity: 0.7;
        cursor: pointer;
    }
    .popup_addEditOrgUser_formCloseBtn:hover {
        opacity: 1;
    }
    #popup_addEditOrgUser_form_title {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 28px;
    }
    #popup_addEditOrgUser_formTwoColumns {
        display: flex;
        align-items: flex-start;
    }
    #popup_addEditOrgUser_formTwoColumns > div:first-child {
        margin-right: 45px;
    }
    #popup_addEditOrgUser_formError {
        margin-top: 0;
        color: red;
        font-size: 12px;
        min-height: 20px;
    }
    #popup_addEditOrgUser_formFooter {
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #popup_addEditOrgUser_text_ok {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 35px;
        color: #454A4D;
        text-align: center;
        margin-top: 35px;
    }
    
    
    .btnMain {
        min-width: 270px;
        min-height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: rgba(71, 75, 80, 0.4);
        cursor: default;
        background: rgba(223, 223, 223, 0.4);
        border-radius: 5px;
    }
    .btnMain_active {
        cursor: pointer;
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        color: #FFF;
    }
    .btnMain_active:hover {
        background: #0c86d4;
    }
    .input_box_iconShowHidePassword {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
    }
    .input_box_showPassword {
        background-image: url(/images/show-password.svg);
    }
    .input_box_hidePassword {
        background-image: url(/images/hide-password.svg);
    }
    .input_box {
        width: 270px;
        border-bottom: 1px solid #D9D9DA;
        margin-bottom: 25px;
    }
    .input_box_2 {
        width: 270px;
        margin-bottom: 25px;
    }
    .input_box_footer {
        padding: 10px;
        display: flex;
        align-items: center;
    }
    .input_box_footer > input {
        flex-grow: 1;
        border: none;
        font-size: 14px;
    }
    .input_box_footer > .input_box_iconShowHidePassword {
        display: inline-block;
        margin-left: 10px;
        opacity: 0.5;
    }
    .input_box_footer > .input_box_iconShowHidePassword:hover {
        opacity: 0.7;
    }
    .input_box_title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: rgba(71, 75, 80, 0.5);
    }
    .input_box_error {
        border-bottom: 1px solid red;
    }
    .input_box_error input {
        color: red;
    }
    .input_box_footer > .form_reg_checkbox:first-child {
        margin-right: 12px;
    }
    .form_reg_checkbox {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }
    .form_reg_checkbox_icon {
        display: inline-block;
        margin-right: 12px;
        width: 17px;
        height: 17px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .form_reg_checkbox_icon_unchecked {
        background-image: url(/images/checkbox-unchecked.svg);
    }
    .form_reg_checkbox_icon_checked {
        background-image: url(/images/checkbox-checked.svg);
    }
    .form_reg_checkbox_name {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #474B50;
    }
    #popup_addEditOrgUser_linkDelete {
        cursor: pointer;
        font-size: 14px;
        color: #666;
    }
    #popup_addEditOrgUser_linkDelete:hover {
        text-decoration: underline;
    }
</style>