<!-- Вход в личный кабинет. Формы авторизации -->

<template>
    <div id="login_root">
        <div id="login_header">
            <img src="/images/logo.svg" />
            <div id="login_header_call">
                <img src="/images/call-icon.svg" />
                <div id="login_header_call_phone">
                    <span id="login_header_call_phone_number">8 800 000 00 00</span>
                    <span id="login_header_call_phone_hint">служба поддержки</span>
                </div>
            </div>
        </div>
        
        <div id="form_login">
            <div id="form_login_title">
                <span>Добро пожаловать</span><br />на портал сервиса<br />QR-паспортов!
            </div>
            <div class="form_login_label">Введите ваш логин</div>
            <div class="form_login_field form_login_field_email"
                 :class="{'form_login_field_error': form.errorLogin }"
                 >
                <img class="form_login_field_icon" src="/images/form-login-icon-1.svg" />
                <input placeholder="mail@mail.ru" 
                       v-model="form.login" maxlength="80"
                       ref="loginFormEmail" />
            </div>
            <div class="form_login_label">Введите пароль</div>
            <div class="form_login_field form_login_field_password"
                 :class="{'form_login_field_error': form.errorPassword }"
                 >
                <img class="form_login_field_icon" src="/images/form-login-icon-2.svg" />
                <input ref="login_form_password_field"
                       :type="passwordShow ? 'text' : 'password'"
                       :placeholder="passwordShow ? '' : '********'"
                       v-model="form.password"
                       maxlength="80" />
            </div>
            <div id="form_login_tools_password">
                <div id="form_login_tools_password_showhidePassword" @click.stop="passwordShow = !passwordShow">
                    <div class="form_login_icon_show_hide_password"
                         :class="{
                            'form_login_icon_show_hide_password_show': ! passwordShow,
                            'form_login_icon_show_hide_password_hide': passwordShow
                         }"
                         ></div>
                    <span>{{ passwordShow ? 'Скрыть пароль' : 'Показать пароль' }}</span>
                </div>
                <router-link to="/forgot-password" id="form_login_link_forgot_password">Забыли пароль?</router-link>
            </div>
            <div class="form_login_btn" @click.stop="login">
                Войти в личный кабинет
            </div>
            <div id="form_login_link_reg">
                <router-link to="/registration">Зарегистрироваться</router-link>
            </div>
        </div>
        
    </div>
</template>

<script>
    import * as global from '../global.js'
    import router from '@/router'
    import { toast } from '@/toast.js'
    
    export default {
        name: 'Login',
        title: 'Авторизация',
        data() {
            return {
                passwordShow: false,
                form: {
                    login: '',
                    password: '',
                    errorLogin: false,
                    errorPassword: false,
                }
            };
        },
        created() {
            setTimeout(() => {
                this.$refs.loginFormEmail.focus();
            }, 300);
        },
        mounted() {
            
        },
        methods: {
            login() {
                this.form.errorLogin     = false;
                this.form.errorPassword  = false;
                
                if( ! global.validateEmail(this.form.login) ) {
                    this.form.errorLogin = true;
                    return;
                }
                
                if( this.form.password.length < 6 ) {
                    this.form.errorPassword = true;
                    return;
                }
                
                this.$bus.emit('showLoading');
                
                this.$postRequest(this.$config.urlLogin, {
                    login: this.form.login.toLowerCase(),
                    password: this.form.password
                })
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    localStorage.mToken = data.token;
                    localStorage.UserEmail = data.email;
                    localStorage.UserFirstName = data.firstName;
                    localStorage.UserPhoto = data.photo;
                    localStorage.typeAccount = data.typeAccount;
                    router.push('/');
                })
                .catch((data) => {
                    this.$bus.emit('hideLoading');
                    //console.log(data);
                    if(data.type_error === 'api') {
                        if(data.data.key === 'error-login-or-pass') {
                            toast.toast( { type: 'text', value: 'Логин или пароль указаны неверно' }, { settings: { duration: 5300 } });
                        }
                        else if(data.data.key === 'pass-not-set') {
                            toast.toast( { type: 'text', value: 'Пароль не установлен' }, { settings: { duration: 5300 } });
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>
    #login_root {
        position: relative;
        min-height: 100vh;
        min-width: 100%;
        background: #F8F9FD;
    }
    #login_header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 35px 96px 0 37px;
    }
    #login_header_call {
        display: flex;
        align-items: center;
    }
    #login_header_call > img {
        display: inline-block;
        margin-right: 19px;
    }
    #login_header_call_phone {
        display: flex;
        flex-direction: column;
    }
    #login_header_call_phone_number {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 20px;
        color: #646A6D;
        margin-bottom: 5px;
    }
    #login_header_call_phone_hint {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #646A6D;
    }
    #form_login {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: #FFFFFF;
        box-shadow: 0px 20px 30px rgba(1, 101, 165, 0.07);
        border-radius: 7px;
        padding: 46px 60px;
    }
    #form_login_title {
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 28px;
        text-align: center;
        color: #474B50;
        margin-bottom: 36px;
    }
    #form_login_title > span {
        font-weight: 600;
    }
    .form_login_label {
        margin-bottom: 7px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #B2BEC4;
        text-transform: uppercase;
    }
    .form_login_field {
        border: 1px solid #ECEFF0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        height: 50px;
        width: 320px;
        padding: 0 15px;
    }
    .form_login_field_icon {
        display: inline-block;
        margin-right: 15px;
        width: 21px;
        height: 21px;
    }
    .form_login_field > input {
        border: none;
        padding: 5px;
        flex-grow: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #474B50;
    }
    .form_login_field_email {
        margin-bottom: 25px;
    }
    .form_login_field_password {
        margin-bottom: 6px;
    }
    .form_login_field_error {
        border: 1px solid red;
    }
    .form_login_field_error input {
        color: red;
    }
    #form_login_tools_password {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        margin-bottom: 40px;
    }
    #form_login_tools_password_showhidePassword {
        display: flex;
        align-items: center;
        cursor: pointer;
        
    }
    #form_login_tools_password_showhidePassword > span,
    #form_login_link_forgot_password {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        color: #646A6D;
    }
    #form_login_link_forgot_password {
        text-decoration: none;
    }
    #form_login_link_forgot_password:hover {
        text-decoration: underline;
    }
    .form_login_icon_show_hide_password {
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        margin-right: 11px;
    }
    .form_login_icon_show_hide_password_show {
        background-image: url(/images/show-password.svg);
    }
    .form_login_icon_show_hide_password_hide {
        background-image: url(/images/hide-password.svg);
    }
    .form_login_btn {
        width: 320px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        cursor: pointer;
        
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        border-radius: 5px;
    }
    .form_login_btn:hover {
        background: #0ea1ff;
    }
    #form_login_link_reg {
        text-align: center;
        margin-top: 29px;
    }
    #form_login_link_reg a {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #008FEA;
        text-decoration: none;
        border-bottom: 2px dashed;
        padding-bottom: 5px;
    }
    #form_login_link_reg a:hover {
        color: #0ea1ff;
    }
</style>
