<!-- Вход в личный кабинет. Формы авторизации -->

<template>
    <div id="login_root">
        <div id="login_header">
            <router-link to="/"><img src="/images/logo.svg" /></router-link>
            <div id="login_header_call">
                <img src="/images/call-icon.svg" />
                <div id="login_header_call_phone">
                    <span id="login_header_call_phone_number">8 800 000 00 00</span>
                    <span id="login_header_call_phone_hint">служба поддержки</span>
                </div>
            </div>
        </div>
        
        <div id="form_reg" v-show="showForm == 1">
            <div id="form_reg_title">
                Регистрация нового пользователя
            </div>
            <div class="form_reg_step_title">
                <span>Шаг 1.</span><span>Личные данные</span>
            </div>
            <div class="form_reg_row">
                <div class="form_reg_cell" :class="{
                        'form_reg_cell_bottomLine': regForm.typeError != REG_FORM_ERROR__NOT_SET_LAST_NAME,
                        'form_reg_cell_red_bottomLine': regForm.typeError == REG_FORM_ERROR__NOT_SET_LAST_NAME,
                    }">
                    <span class="form_reg_label">Ваша фамилия</span>
                    <div class="form_reg_footer">
                        <input placeholder="Введите фамилию" v-model="regForm.lastName" maxlength="80" />
                    </div>
                </div>
                <div class="form_reg_cell" :class="{
                        'form_reg_cell_bottomLine': regForm.typeError != REG_FORM_ERROR__EMAIL_ERROR,
                        'form_reg_cell_red_bottomLine': regForm.typeError == REG_FORM_ERROR__EMAIL_ERROR,
                    }">
                    <span class="form_reg_label">Ваш e-mail</span>
                    <div class="form_reg_icon_and_input form_reg_footer">
                        <img src="/images/icon-message.svg" />
                        <input  placeholder="Введите email" v-model="regForm.email" maxlength="80" />
                    </div>
                </div>
            </div>
            <div class="form_reg_row mb59">
                <div class="form_reg_cell" :class="{
                        'form_reg_cell_bottomLine': regForm.typeError != REG_FORM_ERROR__NOT_SET_FIRST_NAME,
                        'form_reg_cell_red_bottomLine': regForm.typeError == REG_FORM_ERROR__NOT_SET_FIRST_NAME,
                    }">
                    <span class="form_reg_label">Ваше имя</span>
                    <div class="form_reg_footer">
                        <input placeholder="Введите имя" v-model="regForm.firstName" maxlength="80" />
                    </div>
                </div>
                <div class="form_reg_cell" :class="{
                        'form_reg_cell_bottomLine': regForm.typeError != REG_FORM_ERROR__PHONE_ERROR,
                        'form_reg_cell_red_bottomLine': regForm.typeError == REG_FORM_ERROR__PHONE_ERROR,
                    }">
                    <span class="form_reg_label">Ваш телефон</span>
                    <div class="form_reg_icon_and_input form_reg_footer">
                        <img src="/images/icon-message.svg" />
                        <input placeholder="Введите ваш телефон" v-mask="'+7(###) ### ## ##'" v-model="regForm.phone" />
                    </div>
                </div>
            </div>
            <div class="form_reg_step_title">
                <span>Шаг 2.</span><span>Данные организации</span>
            </div>
            <div class="form_reg_row mb20">
                <div class="form_reg_cell" :class="{
                        'form_reg_cell_bottomLine': regForm.typeError != REG_FORM_ERROR__INN_ERROR,
                        'form_reg_cell_red_bottomLine': regForm.typeError == REG_FORM_ERROR__INN_ERROR,
                    }">
                    <span class="form_reg_label">ИНН организации</span>
                    <div class="form_reg_footer">
                        <input placeholder="Введите инн организации" v-mask="'#### #### ##'" v-model="regForm.inn" />
                    </div>
                </div>
                <div class="form_reg_cell">
                    <span class="form_reg_label">Сфера деятельности</span>
                    <div id="form_reg_choose_type_account">
                        <div class="form_reg_checkbox" @click.stop="setTypeAccount(MANUFACTURER)">
                            <div class="form_reg_checkbox_icon"
                                 :class="{
                                    'form_reg_checkbox_icon_checked': regForm.typeAccount == MANUFACTURER,
                                    'form_reg_checkbox_icon_unchecked': regForm.typeAccount != MANUFACTURER,
                                 }"
                                 ></div>
                            <div class="form_reg_checkbox_name">Производитель</div>
                        </div>
                        <div class="form_reg_checkbox" @click.stop="setTypeAccount(DISTRIBUTOR)">
                            <div class="form_reg_checkbox_icon"
                                 :class="{
                                    'form_reg_checkbox_icon_checked': regForm.typeAccount == DISTRIBUTOR,
                                    'form_reg_checkbox_icon_unchecked': regForm.typeAccount != DISTRIBUTOR,
                                 }"
                                 ></div>
                            <div class="form_reg_checkbox_name">Дистрибьютор</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="form_reg_errors" class="mb20">
                {{ regForm.errors }}
            </div>
            
            <div id="form_reg_footer">
                <div class="form_reg_btn" @click.stop="registration()">
                    Зарегистрироваться
                </div>
                <div>
                    <router-link to="/" id="link_goto_main_page">Назад на главную</router-link>
                </div>
            </div>
        </div>
        
        <div id="form_2"  v-show="showForm == 2">
            <div id="form_2_title">
                Завершение<br />регистрации
            </div>
            <div class="form_2_title_2">
                В ближайшее время с Вами<br/>свяжется менеджер, после<br/>чего на адрес
            </div>
            <div id="form_2_email">
                {{ regForm.email }}
            </div>
            <div class="form_2_title_2">
                будет направлено письмо<br/>с логином и временным<br/>паролем для входа на<br/>портал
            </div>
        </div>
        
    </div>
</template>

<script>
    import * as global from '../global.js'
    
    export default {
        name: 'Registration',
        title: 'Регистрация',
        data() {
            return {
                showForm: 1,
                passwordShow: false,
                YEAR: (new Date()).getFullYear(),
                
                regForm: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    inn: '',
                    typeAccount: null,
                    errors: '',
                    typeError: 0,
                }
            };
        },
        created() {
            //------------------------------------------------------------------
            this.REG_FORM_ERROR__NOT_SET_FIRST_NAME       = 1;
            this.REG_FORM_ERROR__NOT_SET_LAST_NAME        = 2;
            this.REG_FORM_ERROR__EMAIL_ERROR              = 3;
            this.REG_FORM_ERROR__PHONE_ERROR              = 4;
            this.REG_FORM_ERROR__INN_ERROR                = 5;
            this.REG_FORM_ERROR__NOT_SET_TYPE_ACCOUNT     = 6;
            //------------------------------------------------------------------
            this.DISTRIBUTOR       = 1;
            this.MANUFACTURER      = 2;
            //------------------------------------------------------------------
        },
        mounted() {
            
        },
        methods: {
            setTypeAccount(v) {
                this.regForm.typeAccount = v;
            },
            async registration() {
                this.regForm.errors = '';
                this.regForm.typeError = 0;
                
                let firstName = this.regForm.firstName.trim();
                let lastName = this.regForm.lastName.trim();
                
                if(lastName.length < 2) {
                    this.regForm.errors = 'Не указана фамилия';
                    this.regForm.typeError = this.REG_FORM_ERROR__NOT_SET_LAST_NAME;
                    return;
                }
                
                if( ! global.validateEmail(this.regForm.email) ) {
                    this.regForm.errors = 'Email указан неверно';
                    this.regForm.typeError = this.REG_FORM_ERROR__EMAIL_ERROR;
                    return;
                }
                
                if(firstName.length < 2) {
                    this.regForm.errors = 'Не указано имя';
                    this.regForm.typeError = this.REG_FORM_ERROR__NOT_SET_FIRST_NAME;
                    return;
                }
                
                if( ! global.validatePhone(this.regForm.phone) ) {
                    this.regForm.errors = 'Телефон указан неверно';
                    this.regForm.typeError = this.REG_FORM_ERROR__PHONE_ERROR;
                    return;
                }
                
                if( ! /\d{4}\s\d{4}\s\d{2}/.test(this.regForm.inn) ) {
                    this.regForm.errors = 'Не указан инн';
                    this.regForm.typeError = this.REG_FORM_ERROR__INN_ERROR;
                    return;
                }
                
                if( this.regForm.typeAccount === null ) {
                    this.regForm.errors = 'Не указана сфера деятельности';
                    this.regForm.typeError = this.REG_FORM_ERROR__NOT_SET_TYPE_ACCOUNT;
                    return;
                }

                let _inn = this.regForm.inn.replace(/\s/g, '');
                
                this.$bus.emit('showLoading');
                
                var token = "a9c0cb02e8f60ee69498b87457bead75143a26cc";
                
                const response = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party', {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json;charset=utf-8',
                      "Authorization": "Token " + token,
                      'pragma': 'no-cache',
                      'cache-control': 'no-cache',
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify({query: _inn})
                });
                
                let text = await response.text(); // Parse it as text
                const data = JSON.parse(text); // Try to parse it as json
                
                if(data.suggestions.length == 0) {
                    this.regForm.errors = 'ИНН указан неверно';
                    this.regForm.typeError = this.REG_FORM_ERROR__INN_ERROR;
                    this.$bus.emit('hideLoading');
                    return;
                }

                this.$postRequest(this.$config.urlRegistration, {
                    firstName: this.regForm.firstName,
                    lastName: this.regForm.lastName,
                    email: this.regForm.email.toLowerCase(),
                    phone: this.regForm.phone,
                    inn: _inn,
                    typeAccount: this.regForm.typeAccount,
                    org_name: data.suggestions[0].data.name.short_with_opf,
                    org_address: data.suggestions[0].data.address.value
                })
                .then(() => {
                    this.$bus.emit('hideLoading');
                    this.showForm = 2;
                })
                .catch((data) => {
                    this.$bus.emit('hideLoading');
                    if(data.type_error == 'api') {
                        if(data.data.key == 'email exist') {
                            this.regForm.errors = 'Email уже зарегистрирован в системе';
                            this.regForm.typeError = this.REG_FORM_ERROR__EMAIL_ERROR;
                        }
                        if(data.data.key == 'phone exist') {
                            this.regForm.errors = 'Телефон уже зарегистрирован в системе';
                            this.regForm.typeError = this.REG_FORM_ERROR__PHONE_ERROR;
                        }
                        if(data.data.key == 'inn exist') {
                            this.regForm.errors = 'ИНН уже зарегистрирован в системе';
                            this.regForm.typeError = this.REG_FORM_ERROR__INN_ERROR;
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>
    #login_root {
        position: relative;
        min-height: 100vh;
        min-width: 100%;
        background: #F8F9FD;
    }
    #login_header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 35px 96px 0 37px;
    }
    #login_header_call {
        display: flex;
        align-items: center;
    }
    #login_header_call > img {
        display: inline-block;
        margin-right: 19px;
    }
    #login_header_call_phone {
        display: flex;
        flex-direction: column;
    }
    #login_header_call_phone_number {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 20px;
        color: #646A6D;
        margin-bottom: 5px;
    }
    #login_header_call_phone_hint {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #646A6D;
    }
    #form_reg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: #FFFFFF;
        box-shadow: 0px 20px 30px rgba(1, 101, 165, 0.07);
        border-radius: 7px;
        padding: 39px 60px;
    }
    #form_reg_title {
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 28px;
        color: #474B50;
        margin-bottom: 29px;
    }
    .form_reg_step_title {
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 21px;
    }
    .form_reg_step_title > span:first-child {
        color: #008FEA;
        display: inline-block;
        margin-right: 5px;
    }
    .form_reg_step_title > span:last-child {
        color: #474B50;
    }
    .form_reg_row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }
    .form_reg_row > .form_reg_cell:first-child {
        margin-right: 57px;
    }
    .form_reg_label {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #474B50;
    }
    .form_reg_cell {
        display: flex;
        flex-direction: column;
        width: 320px;
    }
    .form_reg_cell input {
        border: none;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
    }
    .form_reg_icon_and_input {
        display: flex;
        align-items: center;
    }
    .form_reg_icon_and_input > img {
        display: inline-block;
        margin-right: 15px;
    }
    .form_reg_cell_bottomLine {
        border-bottom: 1px solid #ECEFF0;
    }
    .form_reg_cell_red_bottomLine {
        border-bottom: 1px solid #f00;
    }
    .form_reg_footer {
        padding: 15px;
    }
    .mb20 {
        margin-bottom: 20px;
    }
    #form_reg_choose_type_account {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
    }
    .form_reg_checkbox {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }
    .form_reg_checkbox_icon {
        display: inline-block;
        margin-right: 12px;
        width: 17px;
        height: 17px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .form_reg_checkbox_icon_unchecked {
        background-image: url(/images/checkbox-unchecked.svg);
    }
    .form_reg_checkbox_icon_checked {
        background-image: url(/images/checkbox-checked.svg);
    }
    .form_reg_checkbox_name {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #474B50;
    }
    .form_reg_btn {
        width: 320px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        cursor: pointer;
        
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        border-radius: 5px;
    }
    .form_reg_btn:hover {
        background: #0ea1ff;
    }
    #link_goto_main_page {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        padding-bottom: 3px;
        color: #B2BEC3;
        border-bottom: 2px dashed;
        text-decoration: none;
    }
    #link_goto_main_page:hover {
        color: #a4b0b5;
    }
    #form_reg_footer {
        display: flex;
        align-items: center;
    }
    #form_reg_footer .form_reg_btn {
        margin-right: 50px;
    }
    
    #form_2 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: #FFFFFF;
        box-shadow: 0px 20px 30px rgba(1, 101, 165, 0.07);
        border-radius: 7px;
        padding: 39px 60px;
    }
    #form_2_title {
        margin-bottom: 45px;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        color: #474B50;
    }
    .form_2_title_2 {
        font-size: 20px;
        line-height: 33px;
        text-align: center;
        color: #474B50;
        margin-bottom: 30px;
    }
    #form_2_email {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 35px;
        text-align: center;
        color: #008FEA;
        margin-bottom: 48px;
    }
    #form_reg_errors {
        color: #F00;
        font-size: 14px;
        min-height: 20px;
        margin-left: 10px;
    }
</style>
