// сибытия для обмена данными в системе
class Event{
    constructor(){
        this.events = {};
    }
    
    onOnce(eventName, fn) {
        this.offAll(eventName);
        this.on(eventName, fn);
    }

    on(eventName, fn) {
        if (this.events[eventName]) {
            for (var i = 0; i < this.events[eventName].length; i++) {
                if (this.events[eventName][i] === fn) {
                    return;
                }
            }
        }
        
        this.events[eventName] = this.events[eventName] || [];
        this.events[eventName].push(fn);
    }

    off(eventName, fn) {
        if (this.events[eventName]) {
            for (var i = 0; i < this.events[eventName].length; i++) {
                if (this.events[eventName][i] === fn) {
                    this.events[eventName].splice(i, 1);
                    break;
                }
            }
        }
    }
    
    offAll(eventName) {
        if (this.events[eventName]) {
            this.events[eventName] = [];
        }
    }

    emit(eventName, ...data) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(function(fn) {
                fn(...data);
            });
        }
    }
    
    debugPrint() {
        console.log(this.events);
    }
}

export default new Event();