<template>
    <div id="cpanel_root">
        <LeftMenu @changeMenu="changeMenu"/>
        <div id="cpanel_root_right">
            <CpanelHeader :navigation="[{name: 'Главная', index: 0}, {name: 'Профиль', index: 1}]" @gotoNav="gotoNav" />
            <div id="profile">
                <div id="profile_icon">
                    {{ userFirstName[0].toUpperCase() }}
                </div>
                <div id="profile_info">
                    <div id="profile_info_title">Личные данные пользователя</div>
                    <div class="profile_row">
                        <div class="profile_user_item">
                            <div class="profile_user_item_title">Фамилия</div>
                            <input class="profile_user_item_value" v-model="form.lastName" readonly />
                        </div>
                        <div class="profile_user_item">
                            <div class="profile_user_item_title">Личный E-mail</div>
                            <input class="profile_user_item_value" v-model="form.email" readonly />
                        </div>
                    </div>
                    
                    <div class="profile_row">
                        <div class="profile_user_item">
                            <div class="profile_user_item_title">Имя</div>
                            <input class="profile_user_item_value" v-model="form.firstName" readonly />
                        </div>
                        <div class="profile_user_item">
                            <div class="profile_user_item_title">Личный телефон</div>
                            <input class="profile_user_item_value" v-mask="'+7(###) ### ## ##'" v-model="form.phone" readonly />
                        </div>
                    </div>
                    
                    <div id="profile_password" v-if="typeAccount == 2">
                        <div id="profile_password_title">Пароль</div>
                        <div id="profile_password_footer">
                            <span>* * * * * * * * * *</span><button class="btn2" @click.stop="openFormSetNewPassword()">Изменить пароль</button>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <FormOrg ref="formOrg"/>
            <FormUsers />
            
            
        </div>
    </div>
    <PopupChangePassword ref="formSetNewPassword" />
</template>

<script>
    import LeftMenu from './LeftMenu.vue'
    import CpanelHeader from './CpanelHeader.vue'
    import PopupChangePassword from './PopupChangePassword.vue'
    
    import FormOrg from './FormOrg.vue'
    import FormUsers from './FormUsers.vue'
    import router from '@/router'
    
    export default {
        name: 'Profile',
        title: 'Профиль - Панель управления',
        components: {
            LeftMenu, CpanelHeader, PopupChangePassword, FormOrg, FormUsers
        },
        data() {
            return {
                typeAccount: null,
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                },
                orgForm: {
                    typeAccount: null,
                    intervalSave: 3000,
                    
                    runTimeSave: {
                        org_email: {
                            needSave: false,
                            prosessSave: false,
                            timeOutObj: null,
                            value: ''
                        }
                    }
                },
                manufacturerList: [''],
            };
        },
        computed: {
            userFirstName() {
                return localStorage.UserFirstName;
            },
        },
        created() {
            if( ! localStorage.mToken ) {
                router.push('/login');
            }
            
            this.typeAccount = localStorage.typeAccount;
            this.loadProfileInfo();
        },
        methods: {
            setTypeAccount(v) {
                this.orgForm.typeAccount = v;
            },
            /*setActiveMenu(num) {
                this.activeMenu = num;
            },*/
            loadProfileInfo() {
                this.$bus.emit('showLoading');

                this.$getRequest(this.$config.urlGetProfileInfo, localStorage.mToken)
                .then((data) => {
                    
                    this.form.firstName = data.data.first_name;
                    this.form.lastName  = data.data.last_name;
                    this.form.email  = data.data.email;
                    this.form.phone  = data.data.phone;
                    
                    this.$refs.formOrg.update(data);
                    this.$bus.emit('hideLoading');
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                    
                });
            },
            openFormSetNewPassword() {
                this.$refs.formSetNewPassword.show();
            },
            gotoNav(index) {
                if(index == 0) {
                    router.push('/');
                }
            },
            changeMenu(index) {
                this.activeMenu = parseInt(index);
                localStorage.activeLeftMenu = this.activeMenu;
                router.push('/')
            }
        }
    }
</script>

<style scoped>
    #cpanel_root {
        display: flex;
        min-height: 100vh;
    }
    
    #cpanel_root_right {
        flex-grow: 1;
        background: #FAFAFA;
    }
    
    #profile {
        display: flex;
        align-items: flex-start;
        background: #FFF;
        margin: 20px;
        padding: 36px;
    }
    #profile_icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 188px;
        height: 188px;
        background-image: url(/images/default-user-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 188px 188px;
        color: #FFF;
        font-weight: 600;
        font-size: 100px;
    }
    #profile_info {
        display: flex;
        flex-direction: column;
        margin-left: 84px;
    }
    #profile_info_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 28px;
    }
    .profile_user_item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #D9D9DA;
        width: 270px;
    }
    .profile_user_item_title {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: rgba(71, 75, 80, 0.5);
    }
    .profile_user_item_value {
        border: none;
        display: block;
        margin-top: 11px;
        margin-bottom: 10px;
        font-size: 15px;
    }
    .profile_row {
        display: flex;
        margin-left: 10px;
        margin-top: 10px;
    }
    .profile_row .profile_user_item:first-child {
        margin-right: 47px;
    }
    
    #profile_password {
        margin-top: 35px;
        margin-left: 10px;
    }
    #profile_password_title {
        margin-bottom: 11px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: rgba(71, 75, 80, 0.5);
    }
    #profile_password_footer {
        display: flex;
        align-items: center;
    }
    #profile_password_footer > span {
        display: flex;
        align-items: center;
        width: 304px;
        height: 40px;
        background: rgba(196, 196, 196, 0.1);
        border-radius: 5px;
        margin-right: 15px;
        padding-top: 6px;
        padding-left: 18px;
        color: rgba(71, 75, 80, 0.4);
    }
    .btn2 {
        cursor: pointer;
        border: none;
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #2094FF;
        height: 40px;
        padding: 0 25px;
    }
    .btn2:hover {
        background: rgba(22, 100, 172, 0.1);
    }
    
    
</style>