<template>
    <div id="cpanel_root_rightHeader" v-if="userFirstName && userEmail">
        <div id="cpanel_root_rightHeader_navigation">
            <template
                v-for="(item, index) in navigation"
                :key="index"
                >
                <div v-if="index > 0" class="cpanel_root_rightHeader_navigation_item_delimeter">/</div>
                <div class="cpanel_root_rightHeader_navigation_item"
                     @click.stop="gotoNav(item)"
                     >{{ item.name }}</div>
            </template>
        </div>
        <div id="cpanel_root_rightHeader_btns">
            <!--<div class="cpanel_header_btn">
                <img src="/images/add.svg" />
                <span>Добавить группу</span>
            </div>-->
        </div>
        <div id="cpanel_root_rightHeader_userInfo" @click.stop="userMenuOpened = ! userMenuOpened">
            <div v-if="! userPhoto || userPhoto.length == 0" id="cpanel_root_rightHeader_userInfo_photo">
                {{ userFirstName[0].toUpperCase() }}
            </div>
            <div>
                <div id="cpanel_root_rightHeader_userInfo_firstName">{{ userFirstName }}</div>
                <div id="cpanel_root_rightHeader_userInfo_email">{{ userEmail }}</div>
            </div>
            <div id="cpanel_root_rightHeader_userInfo_iconMenu"
                 :class="{
                    'cpanel_user_panel_opened': userMenuOpened,
                    'cpanel_user_panel_close': ! userMenuOpened
                 }"
                 ></div>

            <div id="cpanel_root_rightHeader_userInfo_menu" v-show="userMenuOpened">
                <router-link class="cpanel_root_rightHeader_userInfo_menu_item" to="/profile">
                    <img src="/images/profile.svg" />
                    <span>Профиль</span>
                </router-link>
                <div class="cpanel_root_rightHeader_userInfo_menu_item" @click.stop="logout">
                    <img src="/images/exit.svg" />
                    <span>Выйти</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '@/router'
    
    export default {
        name: 'CpanelHeader',
        emits: ["gotoNav"],
        props: {
            navigation: [Array]
        },
        data() {
            return {
                userMenuOpened: false,
                typeAccount: null
            };
        },
        computed: {
            userFirstName() {
                return localStorage.UserFirstName;
            },
            userEmail() {
                return localStorage.UserEmail;
            },
            userPhoto() {
                return localStorage.UserPhoto;
            }
        },
        created() {
            this.typeAccount = localStorage.typeAccount;
        },
        methods: {
            logout() {
                this.$bus.emit('showLoading');

                this.$getRequest(this.$config.urlLogout, localStorage.mToken)
                .then(() => {
                    this.$bus.emit('hideLoading');
                    localStorage.mToken = '';
                    router.push('/login');
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            gotoNav(item) {
                console.log(item);
                this.$emit("gotoNav", item.index);
            }
        },
    }
</script>

<style scoped>
    #cpanel_root_rightHeader {
        height: 75px;
        border-bottom: 1.5px solid rgba(231, 231, 232, 0.5);
        display: flex;
        align-items: center;
    }
    #cpanel_root_rightHeader_navigation {
        flex-grow: 1;
        padding-left: 42px;
        
        display: flex;
        align-items: center;
    }
    .cpanel_root_rightHeader_navigation_item {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.3);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        white-space: nowrap;
    }
    .cpanel_root_rightHeader_navigation_item:hover {
        text-decoration: underline;
    }
    #cpanel_root_rightHeader_navigation > .cpanel_root_rightHeader_navigation_item_delimeter {
        display: inline-block;
        margin-right: 11px;
        margin-left: 8px;
        color: rgba(69, 74, 77, 0.3);
        cursor: default;
    }
    #cpanel_root_rightHeader_navigation > .cpanel_root_rightHeader_navigation_item:last-child {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #2094FF;
        cursor: default;
    }
    #cpanel_root_rightHeader_navigation > .cpanel_root_rightHeader_navigation_item:last-child:hover {
        text-decoration: none;
    }
    
    #cpanel_root_rightHeader_userInfo {
        height: 100%;
        border-left: 1.5px solid rgba(231, 231, 232, 0.5);
        display: flex;
        align-items: center;
        padding-left: 31px;
        width: 264px;
        cursor: pointer;
        position: relative;
    }
    #cpanel_root_rightHeader_userInfo_photo {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        width: 35px;
        height: 35px;
        background-image: url(/images/default-user-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 35px 35px;
        color: #FFF;
        font-weight: 600;
        font-size: 20px;
    }
    #cpanel_root_rightHeader_userInfo_firstName {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #454A4D;
    }
    #cpanel_root_rightHeader_userInfo_email {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.5);
    }
    #cpanel_root_rightHeader_userInfo_iconMenu {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 10px;
    }
    .cpanel_user_panel_close {
        background-image: url(/images/user-menu-closed.svg);
    }
    .cpanel_user_panel_opened {
        background-image: url(/images/user-menu-open.svg);
    }
    #cpanel_root_rightHeader_userInfo_menu {
        position: absolute;
        left: -1px;
        top: 75px;
        background: #fff;
        width: 100%;
        border: 1px solid #eee;
    }
    #cpanel_root_rightHeader_userInfo_menu > * {
        border-bottom: 1px solid #f5f5f5;
    }
    #cpanel_root_rightHeader_userInfo_menu > *:last-child {
        border-bottom: none;
    }
    
    .cpanel_root_rightHeader_userInfo_menu_item {
        cursor: pointer;
        padding: 16px 20px;
        font-size: 14px;
        color: #CCC;
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    .cpanel_root_rightHeader_userInfo_menu_item > img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        opacity: 0.2;
    }
    .cpanel_root_rightHeader_userInfo_menu_item:hover {
        color: #000;
        background: #f7f7f7;
    }
    .cpanel_root_rightHeader_userInfo_menu_item:hover > img {
        opacity: 0.5;
    }
    .cpanel_header_btn {
        height: 45px;
        padding: 0 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        background: #2094FF;
        box-shadow: 0px 5px 15px rgba(0, 143, 234, 0.1);
        border-radius: 5px;
    }
    #cpanel_root_rightHeader_btns {
        padding-right: 14px;
        padding-left: 20px;
    }
    .cpanel_header_btn > img {
        display: inline-block;
        margin-right: 5px;
    }
    .cpanel_header_btn:hover {
        background: #0ea1ff;
    }
</style>