<template>
    <div class="popup_addEditModel" v-show="showContent">
        <div class="popup_addEditModel_form">
            <div class="popup_addEditModel_formCloseBtn" @click.stop="closeForm()"></div>
            <div id="popup_addEditModel_form_title">{{ ! form.modeEdit ? 'Создание модели' : 'Редактирование модели' }}</div>
            <div id="popup_addEditModel_body">
                <div id="popup_addEditModel_body_imgAdd">
                    <div id="popup_addEditModel_body_imgAdd_title">Изображение модели</div>
                    <label v-if="form.imgFile == null" 
                           id="popup_addEditModel_body_imgAdd_btn1">
                        <input type="file"
                               style="display: none"
                               accept="image/png, image/webp, image/jpeg"
                               @change="chooseImage"
                               />
                    </label>
                    <div v-if="form.imgFile != null"
                         id="popup_addEditModel_body_imgAdd_uploaded">
                         
                        <img :src="$config.urlMain + form.imgFile.path" />
                        <label class="link">
                            <span>Изменить</span>
                            <input type="file"
                               style="display: none"
                               accept="image/png, image/webp, image/jpeg"
                               @change="chooseImage"
                               />
                        </label>
                    </div>
                </div>
                <div id="popup_addEditModel_body_right">
                    <div class="popup_addEditModel_body_label">Название модели:</div>
                    <div class="popup_addEditModel_body_input"
                         :class="{'popup_addEditModel_body_input_error': this.form.errorCode == this.ERROR_TYPE__NOT_SET_NAME}"
                         >
                        <textarea style="overflow:hidden; resize:none;"
                                  wrap="soft"
                                  @click.stop="autoHeight($event, 1, 5)"
                                  @keyup="autoHeight($event, 1, 5); form.name = limitLength($event.target, 255)"
                                  v-model="form.name"
                                  ></textarea>
                    </div>
                    <div id="popup_addEditModel_body_rightTabs">
                        <div id="popup_addEditModel_body_rightTabsHeader">
                            <span
                                v-for="(item, index) in form.tabs.tabsNames"
                                :key="index" 
                                :class="{'popup_addEditModel_body_rightTabsHeader_active': form.tabs.activeIndex == index}"
                                @click.stop="form.tabs.activeIndex = index"
                                >{{ item }}</span>
                        </div>
                        <div id="popup_addEditModel_body_rightTabsBody">
                            <!-- ------------------------------------------- -->
                            <div v-show="form.tabs.activeIndex == 0">
                                <div class="popup_addEditModel_body_rightTabsBody_label">Описание:</div>
                                <textarea
                                    class="popup_addEditModel_body_rightTabsBody_text"
                                    :class="{'popup_addEditModel_body_rightTabsBody_text_error': this.form.errorCode == this.ERROR_TYPE__NOT_SET_DESCR}"
                                    style="overflow:hidden; resize:none;"
                                    wrap="soft"
                                    @click.stop="autoHeight($event, 2, 5)"
                                    @keyup="autoHeight($event, 2, 5);form.descr = limitLength($event.target, 1024)"
                                    v-model="form.descr"
                                    ></textarea>
                                <div class="popup_addEditModel_body_rightTabsBody_label">Производитель:</div>
                                <select
                                    v-model="form.manufacturer"
                                    class="popup_addEditModel_body_rightTabsBody_select"
                                    :class="{'popup_addEditModel_body_rightTabsBody_text_error': this.form.errorCode == this.ERROR_TYPE__NOT_SET_MANUFACTURER}"
                                    >
                                    <option disabled value="">Выберите производителя</option>
                                    <option
                                        v-for="(item, index) in loadedManufacturerList"
                                        :key="index"
                                        :value="item.id">{{ item.name }}</option>
                                </select>
                                <div class="popup_addEditModel_body_rightTabsBody_label">Гарантия:</div>
                                <textarea
                                    class="popup_addEditModel_body_rightTabsBody_text"
                                    :class="{'popup_addEditModel_body_rightTabsBody_text_error': this.form.errorCode == this.ERROR_TYPE__NOT_SET_GUARANTEE}"
                                    style="overflow:hidden; resize:none;"
                                    wrap="soft"
                                    @click.stop="autoHeight($event, 2, 5)"
                                    @keyup="autoHeight($event, 2, 5);form.guarantee = limitLength($event.target, 1024)"
                                    v-model="form.guarantee"
                                    ></textarea>
                                
                                <div id="popup_addEditModel_body_rightTabsBody_footer">
                                    <label id="popup_addEditModel_body_rightTabsBody_footerCh">
                                        <input type="checkbox" v-model="form.haveSearials" />
                                        <span>Не заполнять серийный номер</span>
                                    </label>

                                </div>
                            </div>
                            <!-- ------------------------------------------- -->
                            <div v-show="form.tabs.activeIndex == 1" id="popup_addEditModel_body_rightFormAddDocs">
                                
                                <div v-if="form.manualDoc != null">
                                    <table class="popup_addEditModel_body_rightFormAddDocs_table">
                                        <tr class="popup_addEditModel_body_rightFormAddDocs_tableHeader">
                                            <td>Название документа</td>
                                            <td>Размер файла</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Руководство по эксплуатации
                                            </td>
                                            <td>{{ fileSize(form.manualDoc.fileSize) }}</td>
                                            <td><span @click.stop="form.manualDoc = null">Удалить</span></td>
                                        </tr>
                                    </table>
                                </div>
                                <label class="btn popup_addEditModel_body_rightFormAddDocs_addBtn"
                                       >
                                    <input type="file" 
                                           style="display: none" 
                                           accept=".pdf,.doc,.docx"
                                           @change="chooseManualDoc"/>
                                    <span>Добавить руководство по эксплуатации (doc, docx, pdf)</span>
                                </label>
                                
                                <div v-if="form.uploadedDocs.length > 0">
                                    <table class="popup_addEditModel_body_rightFormAddDocs_table">
                                        <tr class="popup_addEditModel_body_rightFormAddDocs_tableHeader">
                                            <td>Название документа</td>
                                            <td>Название файла</td>
                                            <td>Размер файла</td>
                                            <td></td>
                                        </tr>
                                        <tr
                                            v-for="(item, index) in form.uploadedDocs"
                                            :key="index"
                                            >
                                            <td>
                                                <textarea 
                                                    placeholder="Введите название документа"
                                                    style="overflow:hidden; resize:none;"
                                                    wrap="soft"
                                                    @click.stop="autoHeight($event, 1, 5)"
                                                    @keyup="autoHeight($event, 1, 5);replaceNewLineChars($event.target);form.uploadedDocs[index].userNameFile = limitLength($event.target, 255)"
                                                    
                                                    v-model="form.uploadedDocs[index].userNameFile"
                                                ></textarea>
                                            </td>
                                            <td>{{ item.fileName }}</td>
                                            <td>{{ fileSize(item.fileSize) }}</td>
                                            <td><span @click.stop="deleteFileUpload(index)">Удалить</span></td>
                                        </tr>
                                    </table>
                                </div>
                                <label class="btn popup_addEditModel_body_rightFormAddDocs_addBtn"
                                       >
                                    <input type="file" 
                                           style="display: none" 
                                           accept=".pdf,.doc,.docx"
                                           @change="chooseDocs"
                                           multiple />
                                    <span>Добавить дополнительные документы (doc, docx, pdf)</span>
                                </label>
                            </div>
                            <!-- ------------------------------------------- -->
                            <div v-show="form.tabs.activeIndex == 2">
                                <div v-if="form.technicalCertificate != null">
                                    <table class="popup_addEditModel_body_rightFormAddDocs_table">
                                        <tr class="popup_addEditModel_body_rightFormAddDocs_tableHeader">
                                            <td>Название документа</td>
                                            <td>Размер файла</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Технический паспорт
                                            </td>
                                            <td>{{ fileSize(form.technicalCertificate.fileSize) }}</td>
                                            <td><span @click.stop="form.technicalCertificate = null">Удалить</span></td>
                                        </tr>
                                    </table>
                                </div>
                                <label class="btn popup_addEditModel_body_rightFormAddDocs_addBtn"
                                       >
                                    <input type="file" 
                                           style="display: none" 
                                           accept=".pdf,.doc,.docx"
                                           @change="chooseTechnicalCertificate"/>
                                    <span>Добавить технический паспорт (doc, docx, pdf)</span>
                                </label>
                            </div>
                            <!-- ------------------------------------------- -->
                            <div v-show="form.tabs.activeIndex == 3">
                                <div id="popup_addEditModel_body_rightTabsBody_listUrls"
                                     v-if="form.urls.length > 0"
                                     >
                                    <table id="popup_addEditModel_body_rightFormAddDocs_tableUrls">
                                        <tr
                                            v-for="(item, index) in form.urls"
                                            :key="index"
                                            >
                                            <td>
                                                <textarea 
                                                    placeholder="Введите адрес ссылки"
                                                    style="overflow:hidden; resize:none;"
                                                    wrap="soft"
                                                    @keyup="autoHeight($event, 1, 5);replaceNewLineChars($event.target);form.urls[index] = limitLength($event.target, 255)"
                                                    v-model="form.urls[index]"
                                                    ></textarea>
                                            </td>
                                            <td><span @click.stop="form.urls.splice(index, 1);">Удалить</span></td>
                                        </tr>
                                    </table>
                                    
                                </div>
                                <button class="btn" @click.stop="form.urls.push('')">Добавить ссылку</button>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <div id="popup_addEditModel_formError">{{ form.error }}</div>
            <div id="popup_addEditModel_formFooter">
                <div class="btnMain btnMain_active"
                     @click.stop="execSave()"
                     >
                    Сохранить
                </div>
                <span id="popup_addEditModel_linkDelete"
                      v-if="form.modeEdit"
                      @click.stop="deleteGroup()"
                      >Удалить</span>
            </div>
        </div>
    </div>
    
</template>

<script>
    //import { toast } from '@/toast.js'
    import * as global from '../global.js'
    import UploadOneFile from '../other/UploadOneFile.js'
    import UploadFiles from '../other/UploadFiles.js'
    
    export default {
        name: 'PopupAddEditModel',
        emits: ["closeForm", 'savePopupAddEditModel', 'errorNoHaveManufacturers', 'deleteModel'],
        props: {
            currentGroup_id: { type: Number, default: 0 },
        },
        data() {
            return {
                showContent: false,
                modeAddNewGroup: true,
                form: {
                    modeEdit: false,
                    account_id: null,
                    imgFile: null,
                    editId: 0,
                    name: '',
                    descr: '',
                    manufacturer: '',
                    guarantee: '',
                    error: '',
                    errorCode: 0,
                    
                    technicalCertificate: null,
                    manualDoc: null,
                    uploadedDocs: [],
                    removedDocs: [],
                    urls: [],
                    haveSearials: false,
                    
                    tabs: {
                        tabsNames: ['Характеристики', 'Документы', 'Технический паспорт', 'Ссылки'],
                        activeIndex: 0,
                    }
                },
                loadedManufacturerList: []
            };
        },
        created() {
            this.ERROR_TYPE__NOT_SET_NAME = 1;
            this.ERROR_TYPE__NOT_SET_DESCR = 2;
            this.ERROR_TYPE__NOT_SET_MANUFACTURER = 3;
            this.ERROR_TYPE__NOT_SET_GUARANTEE = 4;
            this.ERROR_TYPE__NOT_SET_MANUAL_DOC = 5;
            this.ERROR_TYPE__NOT_SET_TC_DOC = 6;
            this.ERROR_TYPE__NOT_SET_NAMES_ADDITIONAL_DOC = 7;
        },
        methods: {
            replaceNewLineChars(obj) {
                global.replaceNewLineChars(obj);
            },
            fileSize(size) {
                return global.humanFileSize(size);
            },
            limitLength(obj, value) {
                return global.limitTextareaLength(obj, value);
            },
            show(modeEdit, data, account_id) {
                this.resetForm();
                
                this.form.modeEdit      = modeEdit;
                this.account_id        = account_id;
                if(this.form.modeEdit && data) {
                    this.form.editId        = data.id;
                    this.form.name          = data.name;
                    this.form.descr         = data.description;
                    this.form.manufacturer  = data.manufacturer;
                    this.form.guarantee     = data.guarantee;
                    this.form.haveSearials  = ! data.have_searials;
                    this.form.imgFile = {
                                        path: 'images/models/' + this.account_id + '/' + data.main_img,
                                        fname: data.main_img
                                    };

                    this.form.urls = data.urls;
                    
                    this.form.manualDoc = {
                        fileSize: data.manual_doc.fileSize,
                        path: 'images/docs/' + this.account_id + '/' + data.manual_doc.fname,
                        fnameServer: data.manual_doc.fname
                    };
                    this.form.technicalCertificate = {
                        fileSize: data.technical_cert.fileSize,
                        path: 'images/docs/' + this.account_id + '/' + data.technical_cert.fname,
                        fnameServer: data.technical_cert.fname
                    };
                    
                    for(let item of data.docs) {
                        this.form.uploadedDocs.push({
                            userNameFile: item.name,
                            fileName: '-',
                            fileSize: item.fileSize,
                            path: 'images/docs/' + this.account_id + '/' + item.fname,
                            fnameServer: item.fname
                        });
                    }
                }
                
                console.log(this.form);
                
                this.checkForm();
                this.showContent = true;
                this.loadManufacturers();
            },
            autoHeight(event, minRows, maxRows) {
                let t = event.target;
                
                if (t.scrollTop == 0)   t.scrollTop=1;
                while (t.scrollTop == 0) {
                    if (t.rows > minRows)
                            t.rows--; else
                        break;
                    t.scrollTop = 1;
                    if (t.rows < maxRows)
                            t.style.overflowY = "hidden";
                    if (t.scrollTop > 0) {
                        t.rows++;
                        break;
                    }
                }
                while(t.scrollTop > 0) {
                    if (t.rows < maxRows) {
                        t.rows++;
                        if (t.scrollTop == 0) t.scrollTop=1;
                    } else {
                        t.style.overflowY = "auto";
                        break;
                    }
                }
            },
            hide() {
                this.showContent = false;
            },
            closeForm() {
                this.$emit("closeForm");
                this.hide();
            },
            checkForm() {
                let name  = this.form.name.trim();
                let descr = this.form.descr.trim();
                
                if(name.length == 0 || descr.length == 0) {
                    
                    return;
                }
                
            },
            resetForm() {
                this.form.modeEdit       = false;
                this.form.imgFile        = null;
                this.form.name           = '';
                this.form.descr          = '';
                this.form.manufacturer   = '';
                this.form.guarantee      = '';
                this.form.editId         = 0;
                this.form.parent_id      = 0;
                this.form.manualDoc      = null;
                this.form.technicalCertificate      = null;
                
                this.form.error          = '';
                this.form.errorCode      = '';
                this.form.uploadedDocs   = [];
                this.form.removedDocs    = [];
                this.form.urls           = [];
                this.form.haveSearials    = false;
                this.form.tabs.activeIndex = 0;
            },
            execSave() {
                this.form.error = '';
                this.form.errorCode = 0;
                let name = this.form.name.trim();
                let descr = this.form.descr.trim();
                let manufacturer = this.form.manufacturer;
                let guarantee = this.form.guarantee.trim();
                
                if(this.form.imgFile == null) {
                    this.form.error = 'Не установлено изображение модели';
                    return;
                }
                
                if(name.length == 0) {
                    this.form.error = 'Не указано название модели';
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_NAME;
                    return;
                }
                if(descr.length == 0) {
                    this.form.error = 'Не указано описание модели';
                    this.form.tabs.activeIndex = 0;
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_DESCR;
                    return;
                }
                if(manufacturer === '') {
                    this.form.error = 'Не указан производитель модели';
                    this.form.tabs.activeIndex = 0;
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_MANUFACTURER;
                    return;
                }
                if(guarantee.length == 0) {
                    this.form.error = 'Не указана информация о гарантии';
                    this.form.tabs.activeIndex = 0;
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_GUARANTEE;
                    return;
                }
                if(this.form.manualDoc == null) {
                    this.form.error = 'Не указано руководство по эксплуатации';
                    this.form.tabs.activeIndex = 1;
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_MANUAL_DOC;
                    return;
                }
                if(this.form.technicalCertificate == null) {
                    this.form.error = 'Не указан технический паспорт модели';
                    this.form.tabs.activeIndex = 2;
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_TC_DOC;
                    return;
                }
                
                if(this.form.urls.some((href) => { return href.length > 0 && ! global.isValidHttpUrl(href) })) {
                    this.form.error = 'Ссылка указана неверно';
                    this.form.tabs.activeIndex = 3;
                    return;
                }
                
                let saveData = {};
                saveData['edit_id'] = this.form.editId;
                saveData['group_id'] = this.currentGroup_id;
                saveData['name'] = name;
                saveData['descr'] = descr;
                saveData['manufacturer'] = manufacturer;
                saveData['guarantee'] = guarantee;
                saveData['main_img'] = this.form.imgFile.fname;
                saveData['manual_doc'] = this.form.manualDoc.fnameServer;
                saveData['technical_cert'] = this.form.technicalCertificate.fnameServer;
                saveData['have_searials'] = ! this.form.haveSearials;
                saveData['urls'] = this.form.urls.filter(href => href.length > 0);
                saveData['docs'] = this.form.uploadedDocs.map((value) => {
                    return { 'name': value.userNameFile, 'fname': value.fnameServer }
                });
                saveData['removedDocs'] = this.form.removedDocs;
                
                if(this.form.uploadedDocs.some((item) => { return item.userNameFile == '' })) {
                    this.form.error = 'Не указано название документа';
                    this.form.tabs.activeIndex = 1;
                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_NAMES_ADDITIONAL_DOC;
                    return;
                }
                
                //console.log(saveData);
                
                if( ! this.form.modeEdit) {
                    this.$bus.emit('showLoading');
                    this.$postRequest(this.$config.urlSaveNewModel, saveData, localStorage.mToken)
                        .then(() => {
                            this.$bus.emit('hideLoading');
                            this.resetForm();
                            this.hide();
                            this.$emit("savePopupAddEditModel", saveData);
                        })
                        .catch((data) => {
                            this.$bus.emit('hideLoading');
                            if(data.type_error === 'api') {
                                if(data.data.key === 'not-set-main-image') {
                                    this.form.error = 'Не установлено изображение модели';
                                }
                                else if(data.data.key === 'not-set-name') {
                                    this.form.error = 'Не указано название модели';
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_NAME;
                                }
                                else if(data.data.key === 'not-set-descr') {
                                    this.form.error = 'Не указано описание модели';
                                    this.form.tabs.activeIndex = 0;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_DESCR;
                                }
                                else if(data.data.key === 'not-set-manufacturer') {
                                    this.form.error = 'Не указан производитель модели';
                                    this.form.tabs.activeIndex = 0;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_MANUFACTURER;
                                }
                                else if(data.data.key === 'not-set-guarantee') {
                                    this.form.error = 'Не указана информация о гарантии';
                                    this.form.tabs.activeIndex = 0;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_GUARANTEE;
                                }
                                else if(data.data.key === 'not-set-manual-doc') {
                                    this.form.error = 'Не указано руководство по эксплуатации';
                                    this.form.tabs.activeIndex = 1;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_MANUAL_DOC;
                                }
                                else if(data.data.key === 'not-set-technical-cert') {
                                    this.form.error = 'Не указан технический паспорт модели';
                                    this.form.tabs.activeIndex = 2;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_TC_DOC;
                                }
                            }
                        });
                }
                else {
                    this.$bus.emit('showLoading');
                    this.$postRequest(this.$config.urlSaveEditModel, saveData, localStorage.mToken)
                        .then(() => {
                            this.$bus.emit('hideLoading');
                            this.resetForm();
                            this.hide();
                            this.$emit("savePopupAddEditModel", saveData);
                        })
                        .catch((data) => {
                            this.$bus.emit('hideLoading');
                            if(data.type_error === 'api') {
                                if(data.data.key === 'not-set-main-image') {
                                    this.form.error = 'Не установлено изображение модели';
                                }
                                else if(data.data.key === 'not-set-name') {
                                    this.form.error = 'Не указано название модели';
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_NAME;
                                }
                                else if(data.data.key === 'not-set-descr') {
                                    this.form.error = 'Не указано описание модели';
                                    this.form.tabs.activeIndex = 0;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_DESCR;
                                }
                                else if(data.data.key === 'not-set-manufacturer') {
                                    this.form.error = 'Не указан производитель модели';
                                    this.form.tabs.activeIndex = 0;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_MANUFACTURER;
                                }
                                else if(data.data.key === 'not-set-guarantee') {
                                    this.form.error = 'Не указана информация о гарантии';
                                    this.form.tabs.activeIndex = 0;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_GUARANTEE;
                                }
                                else if(data.data.key === 'not-set-manual-doc') {
                                    this.form.error = 'Не указано руководство по эксплуатации';
                                    this.form.tabs.activeIndex = 1;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_MANUAL_DOC;
                                }
                                else if(data.data.key === 'not-set-technical-cert') {
                                    this.form.error = 'Не указан технический паспорт модели';
                                    this.form.tabs.activeIndex = 2;
                                    this.form.errorCode = this.ERROR_TYPE__NOT_SET_TC_DOC;
                                }
                            }
                        });
                }
            },
            deleteGroup() {
                if(! confirm("Удалить модель?")) {
                    return;
                }
                
                this.$bus.emit('showLoading');
                
                this.$deleteRequest(this.$config.urlDeleteModel + this.form.editId, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        this.resetForm();
                        this.hide();
                        this.$emit("deleteModel");
                    });
            },
            chooseImage(e) {
                console.log(e.target.files);
                
                this.$bus.emit('showLoading');
                
                new UploadOneFile(
                                this.$config.urlSaveModelImage
                              , {}
                              , e.target.files[0]
                              , localStorage.mToken
                              , () => {
                                    //console.log('callback upload file -> start');
                              }
                              , () => {
                                    //console.log('callback upload file -> ProgressSecond: ', progress, file);
                              }
                              , () => {
                                    console.log('callback upload file -> end');
                                    this.$bus.emit('hideLoading');
                              }
                              , (file, response) => {
                                    console.log('callback upload file -> ok upload', file, response);
                                    this.form.imgFile = {
                                            path: response.path,
                                            fname: response.fname
                                        };
                              }
                              , () => {
                                    //console.log('callback upload file -> error upload', file, response);
                              }
                              , () => {
                                    //console.log('callback error request', status);
                                    this.$bus.emit('hideLoading');
                              }
                        );
            },
            chooseDocs(e) {
                console.log(e.target.files);
                
                this.$bus.emit('showLoading');
                
                new UploadFiles(
                                this.$config.urlSaveModelDocs
                              , {}
                              , e.target.files
                              , localStorage.mToken
                              , () => {
                                    console.log('callback upload file -> start');
                              }
                              , (progress, index, countFiles, file) => {
                                    console.log('callback upload file -> ProgressMain: ', progress, countFiles, file); 
                                }
                              , (progress, file) => {
                                    console.log('callback upload file -> ProgressSecond: ', progress, file);
                              }
                              , () => {
                                    console.log('callback upload file -> end');
                                    this.$bus.emit('hideLoading');
                              }
                              , (file, response) => {
                                    console.log('callback upload file -> ok upload', file, response);
                                    
                                    if( ! this.fileExistsInUpload(file.name, file.size)
                                            && this.form.uploadedDocs.length < 5
                                    ) {
                                        this.form.uploadedDocs.push({
                                            userNameFile: '',
                                            fileName: file.name,
                                            fileSize: file.size,
                                            path: response.path,
                                            fnameServer: response.fname
                                        });
                                    }
                              }
                              , (file, response) => {
                                    console.log('callback upload file -> error upload', file, response);
                              }
                              , (status) => {
                                    console.log('callback error request', status);
                                    this.$bus.emit('hideLoading');
                              }
                        );
            },
            fileExistsInUpload(name, size) {
                for(let item of this.form.uploadedDocs) {
                    if(item.fileName === name && item.fileSize === size) {
                        return true;
                    }
                }
                
                return false;
            },
            deleteFileUpload(index) {
                this.form.removedDocs.push(this.form.uploadedDocs[index].fnameServer);
                this.form.uploadedDocs.splice(index, 1);
            },
            chooseManualDoc(e) {
                this.$bus.emit('showLoading');
                
                new UploadOneFile(
                                this.$config.urlSaveModelManualDoc
                              , {}
                              , e.target.files[0]
                              , localStorage.mToken
                              , () => {
                                    console.log('callback upload file -> start');
                              }
                              , (progress, file) => {
                                    console.log('callback upload file -> ProgressSecond: ', progress, file);
                              }
                              , () => {
                                    console.log('callback upload file -> end');
                                    this.$bus.emit('hideLoading');
                              }
                              , (file, response) => {
                                    console.log('callback upload file -> ok upload', file, response);
                                    this.form.manualDoc = {
                                            fileSize: file.size,
                                            path: response.path,
                                            fnameServer: response.fname
                                        };
                              }
                              , (file, response) => {
                                    console.log('callback upload file -> error upload', file, response);
                              }
                              , (status) => {
                                    console.log('callback error request', status);
                                    this.$bus.emit('hideLoading');
                              }
                        );
            },
            chooseTechnicalCertificate(e) {
                this.$bus.emit('showLoading');
                
                new UploadOneFile(
                                this.$config.urlSaveModelManualDoc
                              , {}
                              , e.target.files[0]
                              , localStorage.mToken
                              , () => {
                                    console.log('callback upload file -> start');
                              }
                              , (progress, file) => {
                                    console.log('callback upload file -> ProgressSecond: ', progress, file);
                              }
                              , () => {
                                    console.log('callback upload file -> end');
                                    this.$bus.emit('hideLoading');
                              }
                              , (file, response) => {
                                    console.log('callback upload file -> ok upload', file, response);
                                    this.form.technicalCertificate = {
                                            fileName: file.name,
                                            fileSize: file.size,
                                            path: response.path,
                                            fnameServer: response.fname
                                        };
                              }
                              , (file, response) => {
                                    console.log('callback upload file -> error upload', file, response);
                              }
                              , (status) => {
                                    console.log('callback error request', status);
                                    this.$bus.emit('hideLoading');
                              }
                        );
            },
            loadManufacturers() {
                this.$bus.emit('showLoading');
                
                this.$getRequest(this.$config.urlGetManufacturers, localStorage.mToken)
                    .then((data) => {
                        
                        if(data.data.length == 0) {
                            this.hide();
                            this.$emit('errorNoHaveManufacturers');
                        }
                        else {
                            this.loadedManufacturerList = data.data;
                        }
                        this.$bus.emit('hideLoading');
                    });
            }
        }
    }
</script>

<style scoped>
    .popup_addEditModel {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(150, 150, 150, 0.1);
    }
    .popup_addEditModel_form {
        position: absolute;
        display: flex;
        flex-direction: column;
        min-width: 800px;
        min-height: 524px;
        max-height: 100%;
        top: 48%;
        left: 50%;
        border: 1px solid #eee;
        transform: translate(-50%, -50%);
        background: #fefefe;
        padding: 51px 51px 51px 50px;
    }
    .popup_addEditModel_form_center {
        align-items: center;
        justify-content: center;
    }
    .popup_addEditModel_formCloseBtn {
        position: absolute;
        display: inline-block;
        top: 22px;
        right: 29px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/images/close-popup.svg);
        background-size: 20px 20px;
        opacity: 0.7;
        cursor: pointer;
    }
    .popup_addEditModel_formCloseBtn:hover {
        opacity: 1;
    }
    #popup_addEditModel_form_title {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 28px;
    }
    #popup_addEditModel_formError {
        color: red;
        font-size: 12px;
        min-height: 20px;
    }
    #popup_addEditModel_formFooter {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .btnMain {
        min-width: 170px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: rgba(71, 75, 80, 0.4);
        cursor: default;
        background: rgba(223, 223, 223, 0.4);
        border-radius: 5px;
    }
    .btnMain_active {
        cursor: pointer;
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        color: #FFF;
    }
    .btnMain_active:hover {
        background: #0c86d4;
    }
    
    
    #popup_addEditModel_linkDelete {
        cursor: pointer;
        font-size: 14px;
        color: #666;
    }
    #popup_addEditModel_linkDelete:hover {
        text-decoration: underline;
    }
    #popup_addEditModel_body {
        display: flex;
        align-items: flex-start;
        overflow: auto;
        overflow-x: hidden;
        width: 100%;
        padding-right: 10px;
        flex-grow: 1;
    }
    #popup_addEditModel_body_imgAdd {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
    }
    #popup_addEditModel_body_imgAdd_btn1 {
        width: 100px;
        height: 100px;
        border-radius: 6px;
        border: 1px dashed rgba(205, 205, 205, 0.5);
        cursor: pointer;
        background-image: url(/images/add-new-model.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 34px 34px;
    }
    #popup_addEditModel_body_imgAdd_btn1:hover {
        border-color: rgba(205, 205, 205, 1);
        background-color: #f6f6f6;
    }
    #popup_addEditModel_body_imgAdd_title {
        font-size: 14px;
        margin-bottom: 10px;
        font-size: 12px;
        white-space: nowrap;
    }
    .popup_addEditModel_body_label {
        font-size: 12px;
        margin-bottom: 8px;
    }
    #popup_addEditModel_body_right {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .popup_addEditModel_body_input {
        width: 100%;
        padding: 2px 5px 5px 5px;
        border-bottom: 1px solid #CCC;
        margin-bottom: 10px;
    }
    .popup_addEditModel_body_input_error {
        border-bottom-color: red;
    }
    .popup_addEditModel_body_input > textarea {
        width: 100%;
        border: none;
        word-wrap: break-word;
        word-break: break-all;
    }
    #popup_addEditModel_body_rightTabs {
        width: 100%;
    }
    #popup_addEditModel_body_rightTabsHeader {
        display: flex;
        align-items: flex-start;
    }
    #popup_addEditModel_body_rightTabsHeader > span {
        cursor: pointer;
        padding: 10px;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #454A4D;
        padding-bottom: 15px;
        white-space: nowrap;
    }
    .popup_addEditModel_body_rightTabsHeader_active {
        border-bottom: 1px solid #2094FF !important;
        cursor: default !important;
    }
    #popup_addEditModel_body_rightTabsBody {
        margin-top: 8px;
    }
    .popup_addEditModel_body_rightTabsBody_label {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .popup_addEditModel_body_rightTabsBody_select {
        border: 1px solid #CCC;
        padding: 5px;
        width: 100%;
    }
    .popup_addEditModel_body_rightTabsBody_text {
        width: 100%;
        border: 1px solid #CCC;
        padding: 5px;
    }
    .popup_addEditModel_body_rightTabsBody_text_error {
        border-color: red;
    }
    #popup_addEditModel_body_rightTabsBody_footer {
        margin: 12px 0 20px 0;
    }
    #popup_addEditModel_body_rightTabsBody_footerCh {
        display: flex;
        align-items: center;
    }
    #popup_addEditModel_body_rightTabsBody_footerCh > span {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
    }
    #popup_addEditModel_body_rightFormAddDocs {
        padding: 10px;
    }
    .popup_addEditModel_body_rightFormAddDocs_addBtn {
        font-size: 12px;
        margin-bottom: 20px;
    }
    #popup_addEditModel_body_imgAdd_uploaded {
        display: flex;
        flex-direction: column;
    }
    #popup_addEditModel_body_imgAdd_uploaded > img {
        max-width: 120px;
        max-height: 120px;
    }
    #popup_addEditModel_body_imgAdd_uploaded > label {
        display: inline-block;
        margin-top: 10px;
    }
    
    .btn {
        color: #333;
        padding: 6px 14px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+100;Grey+Flat */
        background: #eeeeee; /* Old browsers */
        background: -moz-linear-gradient(top,  #eeeeee 0%, #eeeeee 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #eeeeee 0%,#eeeeee 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #eeeeee 0%,#eeeeee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
        border: 1px solid #CCC;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
    }
    .btn:hover {
        color: #000;
    }
    .btn:active {
        background: #cccccc;
        background: -moz-linear-gradient(top,  #cccccc 0%, #eeeeee 100%);
        background: -webkit-linear-gradient(top,  #cccccc 0%,#eeeeee 100%);
        background: linear-gradient(to bottom,  #cccccc 0%,#eeeeee 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cccccc', endColorstr='#eeeeee',GradientType=0 );
    }
    .link {
        font-size: 12px;
        display: inline-block;
        cursor: pointer;
    }
    .link:hover {
        text-decoration: underline;
    }
    .popup_addEditModel_body_rightFormAddDocs_table, #popup_addEditModel_body_rightFormAddDocs_tableUrls {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #eee;
        margin-bottom: 20px;
    }
    .popup_addEditModel_body_rightFormAddDocs_table textarea {
        border: none;
        width: 100%;
        padding: 5px;
        min-width: 300px;
    }
    .popup_addEditModel_body_rightFormAddDocs_table td,
    #popup_addEditModel_body_rightFormAddDocs_tableUrls td {
        border: 1px solid #eee;
        font-size: 12px;
        padding: 4px 5px;
        vertical-align: middle
    }
    .popup_addEditModel_body_rightFormAddDocs_table span,
    #popup_addEditModel_body_rightFormAddDocs_tableUrls span {
        cursor: pointer;
        font-size: 12px;
    }
    .popup_addEditModel_body_rightFormAddDocs_table span:hover,
    #popup_addEditModel_body_rightFormAddDocs_tableUrls span:hover {
        text-decoration: underline;
    }
    #popup_addEditModel_body_rightFormAddDocs_tableUrls textarea {
        border: none;
        padding: 5px;
        width: 100%;
    }
    .popup_addEditModel_body_rightFormAddDocs_tableHeader td {
        white-space: nowrap;
        background-color: #fcfcfc;
        padding: 5px;
    }
</style>