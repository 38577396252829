export default class UploadOneFile {
    uploadIndex = 0;
    xhr = null;
    cancel = false;
        
    constructor(
              url
            , data
            , file
            , token
            , callbackStart
            , callbackProgress
            , callbackEnd
            , callbackOkUpload
            , callbackError
            , callbackErrorRequest
    ){
        this.url = url;
        this.data = data;
        this.file = file;
        this.token = token;
        
        this.callbackStart           = (callbackStart) ? callbackStart : null;
        this.callbackProgress        = (callbackProgress) ? callbackProgress : null;
        this.callbackEnd             = (callbackEnd) ? callbackEnd : null;
        this.callbackOkUpload        = (callbackOkUpload) ? callbackOkUpload : null;
        this.callbackError           = (callbackError) ? callbackError : null;
        this.callbackErrorRequest           = (callbackErrorRequest) ? callbackErrorRequest : null;
        
        if(this.callbackStart) {
            this.callbackStart();
        }
        //console.log(files);
        this.uploadNext();
    }  
    cancel() {
        if(this.xhr) {
            this.xhr.abort();
        }
        this.cancel = true;
    } 
    uploadNext() {
        if(this.cancel) {
            return;
        }

        var fd = new FormData();
        fd.append("file", this.file);
        for(let key in this.data) {
            fd.append(key, this.data[key]);
        }

        this.xhr = new XMLHttpRequest();
        this.xhr.open("POST", this.url, true);
        this.xhr.setRequestHeader("X-Token-Secure", this.token);
        this.xhr.upload.onprogress = (e) => {
            if(this.callbackProgress) {
                this.callbackProgress( Math.ceil((e.loaded / e.total) * 100), this.file );
            }
        };

        this.xhr.onload = () => {
            if(this.xhr.status == 200) {
                
                var jsonResponse = JSON.parse(this.xhr.responseText);
                if(jsonResponse.success) {
                    if(this.callbackOkUpload) {
                        this.callbackOkUpload(this.file, jsonResponse);
                    }

                    if(this.callbackEnd) {
                        this.callbackEnd();
                    }
                }
                else {
                    if(this.callbackError) {
                        this.callbackError(this.file, jsonResponse);
                    }
                }
                
            }
            else {
                if(this.callbackErrorRequest) {
                    this.callbackErrorRequest(this.xhr.status);
                }
            }
        }

        this.xhr.send(fd);
    }
}


