<template>
    <div id="setNewPassword" v-show="showContent">
        <div id="setNewPassword_form">
            <div id="setNewPassword_formTitle">Установить пароль</div>
            
            <div class="input_box"
                 :class="{'input_box_error': form.errorPassword }"
                 >
                <div class="input_box_title">Введите новый пароль</div>
                <div class="input_box_footer">
                    <input 
                       :type="form.showPassword ? 'text' : 'password'"
                       :placeholder="form.showPassword ? '' : '* * * * * * * * * *'"
                       v-model="form.password_1"
                       @input="checkPasswords"
                       maxlength="50" />
                    <div
                        class="input_box_iconShowHidePassword"
                        :class="{
                            'input_box_showPassword': ! form.showPassword,
                            'input_box_hidePassword': form.showPassword,
                        }"
                        @click.stop="form.showPassword = ! form.showPassword"
                        ></div>
                </div>
            </div>
            
            <div class="input_box"
                 :class="{'input_box_error': form.errorPassword }"
                 >
                <div class="input_box_title">Повторите новый пароль</div>
                <div class="input_box_footer">
                    <input 
                       :type="form.showPassword ? 'text' : 'password'"
                       :placeholder="form.showPassword ? '' : '* * * * * * * * * *'"
                       v-model="form.password_2"
                       @input="checkPasswords"
                       maxlength="50" />
                    <div
                        class="input_box_iconShowHidePassword"
                        :class="{
                            'input_box_showPassword': ! form.showPassword,
                            'input_box_hidePassword': form.showPassword,
                        }"
                        @click.stop="form.showPassword = ! form.showPassword"
                        ></div>
                </div>
            </div>
            
            <div id="setNewPassword_formFooter">
                <button class="btnDefault" @click.stop="firstSetNewPassword">Установить</button>
            </div>
            
        </div>
    </div>
</template>

<script>
    import router from '@/router'
    import { toast } from '@/toast.js'
    
    export default {
        name: 'SetNewPassword',
        title: 'Установить пароль',
        data() {
            return {
                showContent: false,
                form: {
                    showPassword: false,
                    password_1: '',
                    password_2: '',
                    errorPassword: false,
                }
            };
        },
        created() {
            console.log(this.$route.params.token);
            
            this.$bus.emit('showLoading', 'Проверка токена');

            this.$getRequest(this.$config.urlSetPasswordToken + '/' + this.$route.params.token)
            .then(() => {
                this.$bus.emit('hideLoading');
                this.showContent = true;
            })
            .catch(() => {
                this.$bus.emit('hideLoading');
                toast.toast( { type: 'text', value: "Ошибка токена" }, { settings: { duration: 5300 } });
                router.push('/');
            });
        },
        methods: {
            checkPasswords() {
                this.form.errorPassword = this.form.password_1.length < 6 || this.form.password_1 != this.form.password_2;
            },
            firstSetNewPassword() {
                if(this.form.errorPassword) {
                    return;
                }
                
                this.$bus.emit('showLoading');

                this.$postRequest(this.$config.urlFirstSetPassword, {
                    password: this.form.password_1,
                    token: this.$route.params.token
                })
                .then(() => {
                    this.$bus.emit('hideLoading');
                    router.push('/login');
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            }
        }
    }
</script>

<style scoped>
    #setNewPassword {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F8F9FD;
    }
    #setNewPassword_form {
        min-width: 200px;
        min-height: 200px;
        background: #FFFFFF;
        box-shadow: 0px 20px 30px rgba(1, 101, 165, 0.07);
        border-radius: 7px;
        padding: 30px;
    }
    #setNewPassword_formTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: #474B50;
        margin-bottom: 36px;
    }
    .input_box_iconShowHidePassword {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
    }
    .input_box_showPassword {
        background-image: url(/images/show-password.svg);
    }
    .input_box_hidePassword {
        background-image: url(/images/hide-password.svg);
    }
    .input_box {
        width: 270px;
        border-bottom: 1px solid #D9D9DA;
        margin-bottom: 25px;
    }
    .input_box_footer {
        padding: 10px;
        display: flex;
        align-items: center;
    }
    .input_box_footer > input {
        flex-grow: 1;
        border: none;
        font-size: 14px;
    }
    .input_box_footer > .input_box_iconShowHidePassword {
        display: inline-block;
        margin-left: 10px;
        opacity: 0.5;
    }
    .input_box_footer > .input_box_iconShowHidePassword:hover {
        opacity: 0.7;
    }
    .input_box_title {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: rgba(71, 75, 80, 0.5);
    }
    .btnDefault {
        border: none;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
        padding: 0 16px;
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        border-radius: 5px;
    }
    .btnDefault:hover {
        background: #0ea1ff;
    }
    #setNewPassword_formFooter {
        display: flex;
        justify-content: flex-end;
    }
    .input_box_error {
        border-bottom: 1px solid red;
    }
    .input_box_error input {
        color: red;
    }
</style>