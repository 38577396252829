<template>
    <div id="profile_org_info">
        <div id="profile_org_info_title">Данные организации</div>
        <div id="profile_org_info_body">
            <div>
                <div class="profile_org_info_body_label">Название организации</div>
                <input class="profile_org_info_bodyReadOnly_input"
                       v-model="orgForm.orgName"
                       readonly />

                <div class="profile_org_info_body_label">ИНН организации</div>
                <input class="profile_org_info_bodyReadOnly_input"
                       v-model="orgForm.orgInn"
                       readonly />
            </div>
            <div>
                <div class="profile_org_info_body_label">Email сервисной службы организации</div>
                <input class="profile_org_info_body_input"
                       @keyup="orgForm_runTimeSave('org_email')"
                       v-model="orgForm.runTimeSave.org_email.value"
                       :class="{'profile_org_info_body_input_error': orgForm.runTimeSave.org_email.error}"
                       maxlength="80"
                       :readonly="typeAccount != 2"
                       ref="profile_org_info_orgEmail" />

                <div class="profile_org_info_body_label">Телефон сервисной службы организации</div>
                <input class="profile_org_info_body_input"
                       ref="profile_org_info_orgPhone"
                       :readonly="typeAccount != 2"
                       placeholder="8 800 000 00 00"
                       @keyup="orgForm_runTimeSave('org_phone')"
                       v-model="orgForm.runTimeSave.org_phone.value"
                       :class="{'profile_org_info_body_input_error': orgForm.runTimeSave.org_phone.error}"
                       v-mask="'+7(###) ### ## ##'" />
            </div>
            <div>
                <div class="profile_org_info_body_label">Время работы организации</div>
                <div id="profile_org_info_bodyTimeWork">
                    <span>с</span>
                    <span class="profile_org_info_bodyTimeWork_cInput">
                        <input class="profile_org_info_bodyTimeWork_from"
                           ref="profile_org_info_orgTimeWork_from"
                           @keyup="orgForm_runTimeSave('org_time_work')"
                           v-model="orgForm.runTimeSave.org_time_work.from_value"
                           placeholder="00:00"
                           :readonly="typeAccount != 2"
                           :class="{'profile_org_info_body_input_error': orgForm.runTimeSave.org_time_work.error}"
                           v-mask="'##:##'"
                           />
                    </span>
                    <span>до</span>
                    <span class="profile_org_info_bodyTimeWork_cInput">
                        <input class="profile_org_info_bodyTimeWork_to"
                           ref="profile_org_info_orgTimeWork_to"
                           @keyup="orgForm_runTimeSave('org_time_work')"
                           v-model="orgForm.runTimeSave.org_time_work.to_value"
                           placeholder="00:00"
                           :readonly="typeAccount != 2"
                           :class="{'profile_org_info_body_input_error': orgForm.runTimeSave.org_time_work.error}"
                           v-mask="'##:##'"
                           />
                    </span>
                </div>

            </div>
        </div>

        <div>
                <div class="profile_org_info_body_label">Сфера деятельности</div>
                <div id="profile_org_choose_type_account">
                    <div class="profile_org_checkbox" 
                         :class="{'profile_org_checkbox_noactive': typeAccount != 2}"
                         >
                        <div class="profile_org_checkbox_icon"
                             :class="{
                                'profile_org_checkbox_icon_checked': orgForm.typeAccount == MANUFACTURER,
                                'profile_org_checkbox_icon_unchecked': orgForm.typeAccount != MANUFACTURER,
                             }"
                             ></div>
                        <div class="profile_org_checkbox_name">Производитель</div>
                    </div>
                    <div class="profile_org_checkbox"
                         :class="{'profile_org_checkbox_noactive': typeAccount != 2}"
                         >
                        <div class="profile_org_checkbox_icon"
                             :class="{
                                'profile_org_checkbox_icon_checked': orgForm.typeAccount == DISTRIBUTOR,
                                'profile_org_checkbox_icon_unchecked': orgForm.typeAccount != DISTRIBUTOR,
                             }"
                             ></div>
                        <div class="profile_org_checkbox_name">Дистрибьютор</div>
                    </div>
                </div>
                <div v-if="orgForm.typeAccount == DISTRIBUTOR" id="manufacturerList">
                    <div
                        v-for="(item, index) in manufacturerList"
                        :key="index"
                        class="manufacturerList_item"
                        >
                        <div class="manufacturerList_itemLabel">Производитель</div>

                        <div class="manufacturerList_itemInput">
                            <input v-model="manufacturerList[ index ].value"
                                   @keyup="orgForm_runTimeSaveManufacturers(index)"
                                   maxlength="255"
                                   :readonly="typeAccount != 2"
                                   :class="{'manufacturerList_itemInput_error': manufacturerList[ index ].error}"
                                   />
                            <div :class="{
                                    'invisible': index == 0 && ! manufacturerList[ index ].prosessSave,
                                    'manufacturerList_itemInput_deleteIcon': ! manufacturerList[ index ].prosessSave,
                                    'manufacturerList_itemInput_saveIcon': manufacturerList[ index ].prosessSave,
                                 }"
                                 class="manufacturerList_itemInput_icon"
                                 @click.stop="deleteMenufacture(index)">
                            </div>
                        </div>
                    </div>
                    <div id="manufacturerList_footer" v-if="typeAccount == 2">
                        <button id="manufacturerList_btn"
                                class="btn2"
                            @click.stop="addManufacturer">Добавить</button>
                    </div>
                </div>
        </div>

    </div>
</template>

<script>
    import * as global from '../global.js'
    import { toast } from '@/toast.js'
    
    export default {
        name: 'FormOrg',
        data() {
            return {
                typeAccount: null,
                orgForm: {
                    orgName: '',
                    orgInn: '',
                    typeAccount: null,
                    intervalSave: 1200,
                    
                    runTimeSave: {
                        org_email: {
                            prosessSave: false,
                            timeOutObj: null,
                            value: '',
                            error: false,
                        },
                        org_phone: {
                            prosessSave: false,
                            timeOutObj: null,
                            value: '',
                            error: false,
                        },
                        org_time_work: {
                            prosessSave: false,
                            timeOutObj: null,
                            from_value: '',
                            to_value: '',
                            value: '',
                            error: false,
                        },
                    }
                },
                manufacturerList: [{ 
                    value: '', 
                    id: 0,
                    prosessSave: false,
                    timeOutObj: null,
                    error: false
                }],
            };
        },
        created() {
            //------------------------------------------------------------------
            this.DISTRIBUTOR       = 1;
            this.MANUFACTURER      = 2;
            this.typeAccount       = parseInt(localStorage.typeAccount);
            
            console.log(this.typeAccount);
        },
        mounted() {
            this.timePicker(this.$refs.profile_org_info_orgTimeWork_from, 'from_value');
            this.timePicker(this.$refs.profile_org_info_orgTimeWork_to, 'to_value');
        },
        methods: {
            /*setTypeAccount(v) {
                this.orgForm.typeAccount = v;
                this.$postRequest(this.$config.urlSaveFieldOrgForm, {
                    key: 'type_account',
                    value: v
                }, localStorage.mToken);
            },*/
            update(data) {
                this.orgForm.typeAccount  = data.data.type_account;
                this.orgForm.runTimeSave.org_email.value  = data.data.org_email ?? '';
                this.orgForm.runTimeSave.org_phone.value  = data.data.org_phone ?? '';
                
                if(data.data.org_time_work != null) {
                    let s = data.data.org_time_work.split('-');
                    this.orgForm.runTimeSave.org_time_work.from_value = s[0];
                    this.orgForm.runTimeSave.org_time_work.to_value = s[1];
                }
                
                this.orgForm.orgName = data.data.org_name;
                this.orgForm.orgInn = data.data.inn;
                
                if(data.data.org_manufacturers && data.data.org_manufacturers.length > 0) {
                    
                    let new_mass = [];
                    
                    for(let row of data.data.org_manufacturers) {
                        new_mass.push({ 
                            value: row.name, 
                            id: row.id,
                            prosessSave: false,
                            timeOutObj: null,
                            error: false
                        });
                    }
                    
                    this.manufacturerList = new_mass;
                }
            },
            orgFormSave(key) {
                let item = this.orgForm.runTimeSave[key]
                //check data
                if(item) {
                    if(key === 'org_email') {
                        if( ! global.validateEmail(item.value)) {
                            item.error = true;
                            return;
                        }
                    }
                    else if(key === 'org_phone') {
                        if( ! global.validatePhone(item.value)) {
                            item.error = true;
                            return;
                        }
                    }
                    else if(key === 'org_time_work') {
                        if( 
                                 ! /\d{2}:\d{2}/.test(item.from_value)
                              || ! /\d{2}:\d{2}/.test(item.to_value)
                        ) {
                            item.error = true;
                            return;
                        }
                        
                        item.value = item.from_value + '-' + item.to_value;
                    }
                    
                    item.error = false;
                    item.prosessSave = true;
                    this.$postRequest(this.$config.urlSaveFieldOrgForm, {
                        key: key,
                        value: item.value
                    }, localStorage.mToken)
                    .then(() => {
                        item.prosessSave = false;
                        
                    })
                    .catch(() => {
                        item.prosessSave = false;
                    });
                }
            },
            orgForm_runTimeSave(key, timeout) {
                if(this.orgForm.runTimeSave[key]) {
                    if(this.orgForm.runTimeSave[key].timeOutObj != null) {
                        clearTimeout(this.orgForm.runTimeSave[key].timeOutObj);
                        this.orgForm.runTimeSave[key].timeOutObj = null;
                    }

                    this.orgForm.runTimeSave[key].timeOutObj = setTimeout(
                                  () => { this.orgFormSave(key) }
                                , timeout ? timeout : this.orgForm.intervalSave
                            );
                }
            },
            addManufacturer() {
                this.manufacturerList.push({ 
                    value: '',
                    id: 0,
                    prosessSave: false,
                    timeOutObj: null,
                    error: false
                });
            },
            saveManufacturers(index) {
                let item = this.manufacturerList[index];
                
                if( item.value.trim().length == 0 ) {
                    item.error = true;
                }
                else {
                    for(let _index in this.manufacturerList) {
                        if(_index != index) {
                            if(item.value.trim() === this.manufacturerList[_index].value.trim()) {
                                item.error = true;
                                return;
                            }
                        }
                    }
                    
                    item.error = false;
                    item.prosessSave = true;
                    this.$postRequest(this.$config.urlSaveOneOrgManufacturers, {
                        id: item.id,
                        value: item.value
                    }, localStorage.mToken)
                    .then((data) => {
                        item.prosessSave = false;
                        if(data.id) {
                            item.id = data.id;
                        }
                    })
                    .catch(() => {
                        item.prosessSave = false
                    });
                }
            },
            orgForm_runTimeSaveManufacturers(index) {
                console.log(index);
                if(this.manufacturerList[index]) {
                    if(this.manufacturerList[index].timeOutObj != null) {
                        clearTimeout(this.manufacturerList[index].timeOutObj);
                        this.manufacturerList[index].timeOutObj = null;
                    }

                    this.manufacturerList[index].timeOutObj = setTimeout(() => { this.saveManufacturers(index) }, this.orgForm.intervalSave);
                }
            },
            deleteMenufacture(index) {
                let item = this.manufacturerList[index];
                
                if(item && item.timeOutObj != null) {
                    return;
                }
                
                if(item.id != 0) {
                    if(! confirm("Удалить производителя?")) {
                        return;
                    }
                    
                    item.prosessSave = true;
                    this.$bus.emit('showLoading');

                    this.$deleteRequest(this.$config.urlDeleteOrgManufacturers + item.id, localStorage.mToken)
                    .then(() => {
                        this.$bus.emit('hideLoading');
                        item.prosessSave = false;
                        this.manufacturerList.splice(index, 1);
                    })
                    .catch((data) => {
                        this.$bus.emit('hideLoading');
                        item.prosessSave = false;
                        
                        if(data.type_error === 'api') {
                            if(data.data.key === 'this-manufacturer-user') {
                                toast.toast( { type: 'text', value: 'Данный производитель, уже используется в моделях. Его нельзя удалить.' }, { settings: { duration: 4300 } });
                            }
                        }
                    });
                }
                else {
                    this.manufacturerList.splice(index, 1);
                }
            },
            timePicker(input, key) {
                var timePicker = document.createElement('div');
                timePicker.classList.add('time-picker');
                //input.value = '08:30';

                //open timepicker
                input.onclick= () => {
                    timePicker.classList.toggle('open');
                    
                    let current_h = '00';
                    let current_m = '00';
                    
                    if(input.value.length > 0) {
                        let split = input.value.split(':');
                        if(split.length == 2) {
                            current_h = split[0];
                            current_m = split[1];
                        }
                    }
                    
                    let btnSetTimeId = 'submitTime_' + global.getRandId();
                    let hourId = 'hour_' + global.getRandId();
                    let minuteId = 'minute_' + global.getRandId();
                    let plusHId = 'plusH_' + global.getRandId();
                    let minusHId = 'minusH_' + global.getRandId();
                    let plusMId = 'plusM_' + global.getRandId();
                    let minusMId = 'minusM_' + global.getRandId();

                    input.setAttribute('disabled','disabled');
                    timePicker.innerHTML +=`
                    <div class="set-time">
                       <div class="label">
                          <a id="${plusHId}" class="noselect">+</a>
                          <input class="set" type="text" id="${hourId}" value="${current_h}" maxLength="2">
                          <a id="${minusHId}" class="noselect">-</a>
                       </div>
                       <div class="label">
                          <a id="${plusMId}" class="noselect">+</a>
                          <input class="set" type="text" id="${minuteId}" value="${current_m}"  maxLength="2">
                          <a id="${minusMId}" class="noselect">-</a>
                       </div>
                    </div>
                    <div class="submitTime" id="${btnSetTimeId}">Установить</div>`;

                    input.after(timePicker);

                    let plusH = document.getElementById(plusHId);
                    let minusH = document.getElementById(minusHId);
                    let plusM = document.getElementById(plusMId);
                    let minusM = document.getElementById(minusMId);
                    let h = parseInt(document.getElementById(hourId).value);
                    let m = parseInt(document.getElementById(minuteId).value);
                   //increment hour
                    plusH.onclick = () => {
                       h = isNaN(h) ? 0 : parseInt(h);
                       
                       h++; 
                       
                       if(h === 24){
                          h = 0;
                       }
                       
                       document.getElementById(hourId).value = (h < 10 ? '0' : 0)+h;
                    }
                    //decrement hour
                    minusH.onclick = () => {
                        h = isNaN(h) ? 0 : parseInt(h);
                        h--;
                       
                       if(h < 0){
                          h = 23;
                       }
                       
                       document.getElementById(hourId).value = (h < 10 ? '0': 0)+h;
                    }
                    //increment hour
                    plusM.onclick = () => {
                        m = isNaN(m) ? 0 : parseInt(m);
                       
                        m++;
                        
                        if(m > 59) {
                            m = 0;
                        }
                        
                        document.getElementById(minuteId).value = (m < 10 ? '0': 0)+m;
                    }
                    //decrement hour
                    minusM.onclick = () => {
                        m = isNaN(m) ? 0 : parseInt(m);

                        m--;
                        
                        if(m < 0) {
                            m = 59;
                        }
                        
                        document.getElementById(minuteId).value = (m < 10? '0' : 0) + m;
                    }

                    //submit timepicker
                    var submit = document.getElementById(btnSetTimeId);
                    submit.onclick = () => {
                        let save_h = parseInt(document.getElementById(hourId).value);
                        let save_m = parseInt(document.getElementById(minuteId).value);
                        
                        if(save_h < 0 || save_h > 23) {
                            return;
                        }
                        
                        if(save_m < 0 || save_m > 59) {
                            return;
                        }
                        
                        save_h = (save_h < 10 ? '0' + save_h: save_h);
                        save_m = (save_m < 10 ? '0' + save_m: save_m);
                        
                        //input.value = save_h + ':' + save_m;
                        input.removeAttribute('disabled');
                        timePicker.classList.toggle('open');
                        timePicker.innerHTML = '';
                        
                        this.orgForm.runTimeSave.org_time_work[key] = save_h + ':' + save_m;
                        
                        this.orgForm_runTimeSave('org_time_work', 100);
                    }
                }
             }

        }
    }
</script>

<style>
    .time-picker {
        display: none;
        justify-content: center;
        flex-direction: column;
        transition: all 0.4s ease;
        height: 0;
        overflow: hidden;
    }
    .time-picker .set-time {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    .time-picker .label {
        width: 40px;
        margin: 0 5px;
        text-align: center;
        line-height: 16px;
        display: inline-style;
    }
    .time-picker .label a {
        display: block;
        border: 1px solid #DDDDDD;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        border-radius: 3px;
        height: 20px;
    }
    .time-picker .label a:hover, .time-picker .label a:active {
        background-color: red;
        color: #FFFFFF;
    }
    .time-picker .label .set {
        text-align: center;
        box-sizing: border-box;
        width: 100%;
        line-height: 30px;
        font-size: 16px;
        font-weight: bold;
        border: transparent;
    }
    .time-picker .submitTime {
        text-align: center;
        line-height: 30px;
        border: 1px solid #DDDDDD;
        width: 94px;
        margin: auto;
        border-radius: 3px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px;
        line-height: 23px;
    }
    .time-picker.open {
        display: flex;
        border: 1px solid #DDDDDD;
        padding: 6px 3px;
        transition: all 0.5s ease;
        height: auto;
        background-color: #FCFCFC;
        position: absolute;
    }
</style>
<style scoped>
    #profile_org_info {
        margin: 20px;
        padding: 36px;
        background: #FFF;
    }
    #profile_org_info_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        color: #454A4D;
        margin-bottom: 20px;
    }
    #profile_org_info_body {
        display: flex;
        align-items: flex-start;
    }
    #profile_org_info_body > div {
        margin-right: 20px;
    }
    .profile_org_info_body_label {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: rgba(69, 74, 77, 0.6);
        margin-top: 6px;
    }
    .profile_org_info_bodyReadOnly_input {
        border: none;
        width: 100%;
        margin: 10px 0;
        padding: 5px;
        font-size: 15px;
    }
    .profile_org_info_body_input {
        width: 100%;
        margin: 10px 0;
        padding: 5px;
        border: 1px solid #CCC;
        font-size: 15px;
    }
    .profile_org_info_body_input[readonly] {
        border-color: transparent;
    }
    #profile_org_info_bodyTimeWork input[readonly] {
        border-color: transparent;
    }
    .manufacturerList_itemInput input[readonly] {
        border-color: transparent;
    }
    .profile_org_info_body_input_error {
        border-color: red !important;
    }
    #profile_org_info_bodyTimeWork {
        margin: 10px 0;
        display: flex;
        align-items: center;
    }
    #profile_org_info_bodyTimeWork > span {
        display: inline-block;
        font-size: 13px;
        margin-left: 10px;
    }
    #profile_org_info_bodyTimeWork > span:first-child {
        margin-left: 0;
    }
    #profile_org_info_bodyTimeWork input {
        width: 80px;
        padding: 5px;
        border: 1px solid #CCC;
        display: inline-block;
        text-align: center;
        font-size: 15px;
        margin-bottom: 6px;
    }
    .profile_org_info_bodyTimeWork_cInput {
        margin-left: 10px;
        display: inline-block;
    }
    
    .profile_org_checkbox {
        display: inline-flex;
        align-items: center;
        cursor: default;
    }
    .profile_org_checkbox_noactive {
        cursor: default;
    }
    .profile_org_checkbox_icon {
        display: inline-block;
        margin-right: 10px;
        width: 17px;
        height: 17px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .profile_org_checkbox_icon_unchecked {
        background-image: url(/images/checkbox-unchecked.svg);
    }
    .profile_org_checkbox_icon_checked {
        background-image: url(/images/checkbox-checked.svg);
    }
    .profile_org_checkbox_name {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #474B50;
    }
    #profile_org_choose_type_account {
        display: flex;
        align-items: center;
        margin-top: 16px;
    }
    #profile_org_choose_type_account > .profile_org_checkbox:first-child {
        margin-right: 12px;
    }
    #manufacturerList {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        margin-top: 25px;
        width: 550px;
    }
    .manufacturerList_item {
        margin-bottom: 10px;
    }
    .manufacturerList_itemInput > input {
        width: 100%;
        padding: 5px;
        font-size: 14px;
        border: 1px solid #eee;
    }
    .manufacturerList_itemInput_icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
        opacity: 0.7;
        margin-left: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px 18px;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
    }
    .manufacturerList_itemInput_icon:hover {
        opacity: 1;
    }
    .manufacturerList_itemInput_deleteIcon {
        background-image: url(/images/delete.svg);
    }
    .manufacturerList_itemInput_saveIcon {
        background-image: url(/images/save.svg);
    }
    .manufacturerList_itemLabel {
        margin-bottom: 5px;
        font-size: 12px;
    }
    #manufacturerList_btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 13px;
    }
    #manufacturerList_footer {
        display: flex;
        justify-content: flex-end;
        margin-right: 28px;
    }
    
    .manufacturerList_itemInput {
        display: flex;
        align-items: center;
    }
    .invisible {
        visibility: hidden;
    }
    .manufacturerList_itemInput_error {
        border-color: red !important;
    }
    
    .btn2 {
        cursor: pointer;
        border: none;
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #2094FF;
        height: 40px;
        padding: 0 25px;
    }
    .btn2:hover {
        background: rgba(22, 100, 172, 0.1);
    }
    .profile_org_info_bodyTimeWork_cInput {
        position: relative;
    }
</style>