<template>
    <div id="clm_header">
        <span></span>
        <span>Наименование товара</span>
        <span>Описание</span>
        <span>Производитель</span>
        <span></span>
    </div>
    <div 
        id="clm_add_new" 
        v-if="typeAccount == 2 || typeAccount == 4"
        @click.stop="addModel">
        <img src="/images/add-new-model.svg"/>
        <span>Добавить модель</span>
    </div>
    <div id="clm_list">
        <div
            v-for="(item, index) in list"
            :key="index"
            class="clm_list_item"
            >
            <img class="clm_list_item_img" :src="$config.urlMain + 'images/models/' + account_id + '/' + item.main_img" />
            <span class="clm_list_item_name"
                  @click.stop="openModel(item)"
                  >{{item.name}}</span>
            <span class="clm_list_item_descr">{{item.description}}</span>
            <span class="clm_list_item_descr">{{item.name_manufacture}}</span>
            <div>
                <span 
                    v-if="typeAccount == 2 || typeAccount == 4"
                    class="clm_btn"
                    @click.stop="editModel(item)">
                   <img src="/images/edit-model.svg" />
                   <span>Редактировать</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CpanelListModels',
        emits: ["clickAddModel", "editModel", "openModel"],
        props: {
            currentGroup_id: { type: Number, default: 0 },
        },
        data() {
            return {
                list: [],
                typeAccount: null,
                account_id: null,
            }
        },
        created() {
            this.typeAccount = localStorage.typeAccount;
            this.loadList();
        },
        methods: {
            addModel() {
                this.$emit("clickAddModel");
            },
            loadList() {
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadListModels + this.currentGroup_id, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.list = data.data;
                    this.account_id = data.account_id;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            editModel(row) {
                this.$emit("editModel", row.id);
            },
            openModel(row) {
                this.$emit("openModel", row.id, row.name);
            },
        }
    }
</script>

<style scoped>
    #clm_header {
        display: grid;
        width: 100%;
        grid-template-columns: 130px 1fr 2fr 1fr 1fr;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #454A4D;
        margin-bottom: 14px;
        padding: 0 20px;
    }
    #clm_add_new {
        height: 51px;
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px dashed rgba(205, 205, 205, 0.5);
        border-radius: 3px;
        padding: 0 21px;
        cursor: pointer;
        margin-bottom: 10px;
    }
    #clm_add_new:hover {
        border-color: rgba(205, 205, 205, 1);
        background: #FFF;
    }
    #clm_add_new > img {
        display: inline-block;
        margin-right: 18px;
    }
    #clm_add_new > span {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #A9A9A9;
    }
    #clm_list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .clm_list_item {
        display: grid;
        height: 90px;
        width: 100%;
        padding: 0 20px;
        grid-template-columns: 130px 1fr 2fr 1fr 1fr;
        align-items: center;
        background: #FFFFFF;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 10px;
        justify-content: space-between;
    }
    .clm_list_item_img {
        max-width: 93px;
        max-height: 75px;
    }
    .clm_list_item_name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #454A4D;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 20px;        /* fallback */
        max-height: 40px;       /* fallback */
    }
    .clm_list_item_name:hover {
        text-decoration: underline;
    }
    .clm_list_item_descr {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: rgba(69, 74, 77, 0.4);
        
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 18px;        /* fallback */
        max-height: 40px;       /* fallback */
    }
    .clm_btn {
        width: 178px;
        height: 45px;
        padding: 0 25px;
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .clm_btn:hover {
        background: rgba(32, 148, 255, 0.2);
    }
    .clm_btn > img {
        width: 13px;
        height: 13px;
        display: inline-block;
        margin-right: 15px;
        
    }
    .clm_btn > span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #2094FF;
    }
    .clm_list_item > span:last-child {
        justify-self: end;
    }
    
</style>