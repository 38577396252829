<template>
    <div id="profile_org_users">
        <div id="profile_org_users_header">
            <div id="profile_org_users_title">Пользователи организации</div>
            <div 
                id="profile_org_usersAddNewUser"
                v-if="typeAccount == 2 || typeAccount == 4"
                @click.stop="openFormAddEditOrgUser">Добавить пользователя</div>
        </div>

        <div id="profile_org_usersList">
            <div v-if="list.length > 0" class="profile_org_usersTableRow profile_org_usersTableHeader">
                <span>Имя</span>
                <span>Фамилиия</span>
                <span>Права</span>
                <span>Должность</span>
                <span>Email</span>
                <span>Телефон</span>
                <span></span>
            </div>
            <div
                v-for="(item, index) in list"
                :key="index"
                class="profile_org_usersTableRow profile_org_usersTableUserRow"
                >
                <span>{{ item.first_name }}</span>
                <span>{{ item.last_name }}</span>
                <span>{{ item.type_account == 1 ? 'Пользователь' : 'Администратор' }}</span>
                <span>{{ item.position }}</span>
                <span>{{ item.email }}</span>
                <span>{{ item.phone }}</span>
                <span>
                    <button 
                        class="btn" 
                        v-if="typeAccount == 2 || typeAccount == 4"
                        @click.stop="editUser(item.id)">
                        <img src="/images/edit-model.svg" />
                        <span>Редактировать</span>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <PopupAddEditOrgUser ref="formAddEditOrgUser" @saveForm="loadList" />
</template>

<script>
    import PopupAddEditOrgUser from './PopupAddEditOrgUser.vue'
    //import * as global from '../global.js'
    //import { toast } from '@/toast.js'
    
    export default {
        name: 'FormUsers',
        emits: ["addNewUser", "editUser"],
        components: {
             PopupAddEditOrgUser
        },
        data() {
            return {
                list: [],
                typeAccount: null
            };
        },
        created() {
            this.typeAccount = localStorage.typeAccount;
            this.loadList();
        },
        methods: {
            openFormAddEditOrgUser() {
                this.$refs.formAddEditOrgUser.show();
            },
            loadList() {
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadListOrgUser, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.list = data.data;
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            },
            editUser(id) {
                console.log(id);
                this.$bus.emit('showLoading', 'Получение данных...');

                this.$getRequest(this.$config.urlLoadDataOrgUser + id, localStorage.mToken)
                .then((data) => {
                    this.$bus.emit('hideLoading');
                    this.$refs.formAddEditOrgUser.show(true, data.data);
                })
                .catch(() => {
                    this.$bus.emit('hideLoading');
                });
            }
        }
    }
</script>

<style scoped>
    #profile_org_users {
        margin: 20px;
        padding: 36px;
        background: #FFF;
    }
    #profile_org_users_header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    #profile_org_users_title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        color: #454A4D;
        
        flex-grow: 1;
    }
    #profile_org_usersAddNewUser {
        background: #2094FF;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        border-radius: 5px;
        width: 216px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
    }
    #profile_org_usersAddNewUser:hover {
        background: #167bd8;
    }
    
    .profile_org_usersTableRow {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 150px;
        align-items: center;
        padding: 0 20px;
    }
    .profile_org_usersTableUserRow {
        background: #FAFAFA;
        border-radius: 5px; 
        height: 45px;
        margin-bottom: 5px;
    }
    .profile_org_usersTableRow > span {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #454A4D;
    }
    .profile_org_usersTableUserRow > span:last-child {
        justify-self: end;
    }
    .profile_org_usersTableHeader {
        height: 40px;
    }
    
    .btn {
        width: 160px;
        height: 32px;
        padding: 0 25px;
        border: none;
        background: rgba(32, 148, 255, 0.1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn:hover {
        background: rgba(32, 148, 255, 0.2);
    }
    .btn > img {
        width: 13px;
        height: 13px;
        display: inline-block;
        margin-right: 5px;
        
    }
    .btn > span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #2094FF;
    }
</style>