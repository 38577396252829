<template>
    <div class="popup_change_password" v-show="showContent && showForm == 1">
        <div class="popup_change_password_form">
            <div class="popup_change_password_formCloseBtn" @click.stop="closeForm()"></div>
            <div id="popup_change_password_form_title">Изменение пароля</div>
            <div id="popup_change_password_formTwoColumns">
                <div>
                    <div class="input_box">
                       <div class="input_box_title">Введите старый пароль</div>
                       <div class="input_box_footer">
                           <input 
                              :type="form.showPassword ? 'text' : 'password'"
                              :placeholder="form.showPassword ? '' : '* * * * * * * * * *'"
                              v-model="form.old_password"
                              @input="checkPasswords"
                              maxlength="50" />
                           <div
                               class="input_box_iconShowHidePassword"
                               :class="{
                                   'input_box_showPassword': ! form.showPassword,
                                   'input_box_hidePassword': form.showPassword,
                               }"
                               @click.stop="form.showPassword = ! form.showPassword"
                               ></div>
                       </div>
                   </div>
                </div>
                
                <div>
                    
                    <div class="input_box"
                            :class="{'input_box_error': form.errorPassword }"
                            >
                           <div class="input_box_title">Введите новый пароль</div>
                           <div class="input_box_footer">
                               <input 
                                  :type="form.showPassword ? 'text' : 'password'"
                                  :placeholder="form.showPassword ? '' : '* * * * * * * * * *'"
                                  v-model="form.new_password_1"
                                  @input="checkPasswords"
                                  maxlength="50" />
                               <div
                                   class="input_box_iconShowHidePassword"
                                   :class="{
                                       'input_box_showPassword': ! form.showPassword,
                                       'input_box_hidePassword': form.showPassword,
                                   }"
                                   @click.stop="form.showPassword = ! form.showPassword"
                                   ></div>
                           </div>
                    </div>
                    
                    <div class="input_box"
                            :class="{'input_box_error': form.errorPassword }"
                            >
                           <div class="input_box_title">Повторите новый пароль</div>
                           <div class="input_box_footer">
                               <input 
                                  :type="form.showPassword ? 'text' : 'password'"
                                  :placeholder="form.showPassword ? '' : '* * * * * * * * * *'"
                                  v-model="form.new_password_2"
                                  @input="checkPasswords"
                                  maxlength="50" />
                               <div
                                   class="input_box_iconShowHidePassword"
                                   :class="{
                                       'input_box_showPassword': ! form.showPassword,
                                       'input_box_hidePassword': form.showPassword,
                                   }"
                                   @click.stop="form.showPassword = ! form.showPassword"
                                   ></div>
                           </div>
                    </div>
                    
                </div>
            </div>
            <div id="popup_change_password_formError">{{ form.error }}</div>
            <div id="popup_change_password_formFooter">
                <div class="btnMain"
                     :class="{'btnMain_active': form.statusOK}"
                     @click.stop="execChangePassword()"
                     >
                    Изменить пароль
                </div>
            </div>
        </div>
    </div>
    
    <div class="popup_change_password" v-show="showContent && showForm == 2">
        <div class="popup_change_password_form popup_change_password_form_center">
            <div class="popup_change_password_formCloseBtn" @click.stop="closeForm()"></div>
            <img src="/images/icon-ok.svg" />
            <div id="popup_change_password_text_ok">Ваш пароль успешно<br />изменен</div>
        </div>
    </div>
    
</template>

<script>
    import { toast } from '@/toast.js'
    
    export default {
        name: 'PopupChangePassword',
        emits: ["closeForm"],
        data() {
            return {
                showContent: false,
                showForm: 1,
                form: {
                    errorPassword: false,
                    old_password: '',
                    new_password_1: '',
                    new_password_2: '',
                    statusOK: false,
                    error: '',
                }
            };
        },
        methods: {
            show() {
                this.showContent = true;
            },
            hide() {
                this.showContent = false;
            },
            checkPasswords() {
                
                this.form.statusOK = false;
                this.form.error = '';
                if(this.form.old_password.length < 6) {
                    this.form.error = 'Минимальная дланна пароля - 6 символов';
                    return;
                }
                
                this.form.errorPassword = this.form.new_password_1.length < 6 || this.form.new_password_1 != this.form.new_password_2;
                
                if(this.form.old_password.length < 6) {
                    this.form.error = 'Минимальная дланна пароля - 6 символов';
                    return;
                }
                
                if(this.form.old_password == this.form.new_password_1) {
                    this.form.error = 'Старый и новый пароли, совпадают';
                    return;
                }
                
                this.form.statusOK = ! this.form.errorPassword;
            },
            closeForm() {
                this.$bus.emit("closeFormPopupChangePassword");
                this.hide();
            },
            execChangePassword() {
                if(! this.form.statusOK) {
                    return;
                }
                
                this.$bus.emit('showLoading');

                this.$postRequest(this.$config.urlProfileSetNewPassword, {
                    old_password: this.form.old_password,
                    new_password: this.form.new_password_1
                }, localStorage.mToken)
                .then(() => {
                    this.$bus.emit('hideLoading');
                    this.showForm = 2;
                })
                .catch((data) => {
                    this.$bus.emit('hideLoading');
                    if(data.type_error === 'api') {
                        if(data.data.key === 'old password is not right') {
                            toast.toast( { type: 'text', value: 'Старый пароль указан неверно' }, { settings: { duration: 4300 } });
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .popup_change_password {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(150, 150, 150, 0.1);
    }
    .popup_change_password_form {
        position: absolute;
        display: flex;
        flex-direction: column;
        min-width: 704px;
        min-height: 394px;
        top: 48%;
        left: 50%;
        border: 1px solid #eee;
        transform: translate(-50%, -50%);
        background: #fefefe;
        padding: 51px 69px 51px 50px;
    }
    .popup_change_password_form_center {
        align-items: center;
        justify-content: center;
    }
    .popup_change_password_formCloseBtn {
        position: absolute;
        display: inline-block;
        top: 22px;
        right: 29px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(/images/close-popup.svg);
        background-size: 20px 20px;
        opacity: 0.7;
        cursor: pointer;
    }
    .popup_change_password_formCloseBtn:hover {
        opacity: 1;
    }
    #popup_change_password_form_title {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 20px;
        color: #454A4D;
        margin-bottom: 28px;
    }
    #popup_change_password_formTwoColumns {
        display: flex;
        align-items: flex-start;
    }
    #popup_change_password_formTwoColumns > div:first-child {
        margin-right: 45px;
    }
    #popup_change_password_formError {
        margin-top: 10px;
        color: red;
        font-size: 12px;
        min-height: 20px;
    }
    #popup_change_password_formFooter {
        margin-top: 20px;
    }
    #popup_change_password_text_ok {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 35px;
        color: #454A4D;
        text-align: center;
        margin-top: 35px;
    }
    
    
    .btnMain {
        min-width: 270px;
        min-height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: rgba(71, 75, 80, 0.4);
        cursor: default;
        background: rgba(223, 223, 223, 0.4);
        border-radius: 5px;
    }
    .btnMain_active {
        cursor: pointer;
        background: #008FEA;
        box-shadow: 0px 4px 15px rgba(22, 103, 224, 0.2);
        color: #FFF;
    }
    .btnMain_active:hover {
        background: #0c86d4;
    }
    .input_box_iconShowHidePassword {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
    }
    .input_box_showPassword {
        background-image: url(/images/show-password.svg);
    }
    .input_box_hidePassword {
        background-image: url(/images/hide-password.svg);
    }
    .input_box {
        width: 270px;
        border-bottom: 1px solid #D9D9DA;
        margin-bottom: 25px;
    }
    .input_box_footer {
        padding: 10px;
        display: flex;
        align-items: center;
    }
    .input_box_footer > input {
        flex-grow: 1;
        border: none;
        font-size: 14px;
    }
    .input_box_footer > .input_box_iconShowHidePassword {
        display: inline-block;
        margin-left: 10px;
        opacity: 0.5;
    }
    .input_box_footer > .input_box_iconShowHidePassword:hover {
        opacity: 0.7;
    }
    .input_box_title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: rgba(71, 75, 80, 0.5);
    }
    .input_box_error {
        border-bottom: 1px solid red;
    }
    .input_box_error input {
        color: red;
    }
</style>