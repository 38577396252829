import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/components/Login.vue'
import Cpanel from '@/components/Cpanel.vue'
import CpanelSerialNumbers from '@/components/CpanelSerialNumbers.vue'
import Profile from '@/components/Profile.vue'
import Search from '@/components/Search.vue'
import Registration from '@/components/Registration.vue'
import SetNewPassword from '@/components/SetNewPassword.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'
import NotFound from '@/components/NotFound.vue'

const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'Cpanel',
      component: Cpanel
    },
    {
      path: '/open-model/:id([0-9]+)',
      name: 'CpanelOpenModel',
      component: Cpanel
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },
    {
      path: '/search',
      name: 'Search',
      component: Search
    },
    {
      path: '/serial-numbers',
      name: 'CpanelSerialNumbers',
      component: CpanelSerialNumbers
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/registration',
      name: 'Registration',
      component: Registration
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/set-password-new-account/:token(.+)',
      name: 'SetNewPassword',
      component: SetNewPassword
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
  ]
});

export default router;